import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { Divider, Input, Popconfirm, Select, Space, Table } from "antd";
import React, { useState } from "react";
import ShowDescriptionInListing from "../Common/showDescriptionInListing";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import CommonFilterForm from "../Common/commonFilterForm";
import TableLayout from "../Layout/tableLayout";
import { useSelector } from "react-redux";
import AuditModalContainer from "../../Containers/Products/AuditModalContainer";
import { AuditEntityType } from "../../Constant/Common";
import { getSpaceValidationRule } from "../../Util/commonUtility";

const ListTargetProductComponent = (props) => {
  const [isAuditModal, setIsAuditModal] = useState({
    open: false,
    data: null,
  });
  const {
    productTargets,
    paging,
    handlePageChange,
    editProductTarget,
    deleteProductTarget,
    listFilterSubmit,
    resetFilter,
    listFilterData,
    handleTableChange,
    sortField,
    sortOrder,
  } = props;

  const Environments = useSelector(
    (state) => state.product.selectedProduct.environments
  );
  const hasFullAccessInAnyEnv = (environments) => {
    return environments.some((env) => env.isFullAccess);
  };
  const hasFullAccessToProduction = (environments) => {
    return environments.some((env) => env.isProduction && env.isFullAccess);
  };

  const filterFields = [
    {
      name: "name",
      component: (
        <Input className="list-filter-common-style" placeholder="Name" />
      ),
      rules: [getSpaceValidationRule()],
    },
    {
      name: "description",
      component: (
        <Input className="list-filter-common-style" placeholder="Description" />
      ),
      rules: [getSpaceValidationRule()],
    },
    {
      name: "isEnabled",
      component: (
        <Select
          className="list-filter-common-style select-target-min-width"
          placeholder="Is Enabled?"
        >
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <ShowDescriptionInListing title={text} text={text} />,
    },
    // {
    //   title: "Environment Id",
    //   dataIndex: "environmentId",
    //   key: "environmentId",
    //   // render: (version, row) => {
    //   //   return <Tag className="common-tag-color">{version}</Tag>;
    //   // },
    // },
    // {
    //   title: "Product Id",
    //   dataIndex: "productId",
    //   key: "productId",
    // },
    {
      title: "Used in Production",
      dataIndex: "isProductionUse",
      render: (isProductionUse, row) => (isProductionUse ? "Yes" : "No"),
    },
    {
      title: "Enabled",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (isEnabled, row) => (isEnabled ? "Yes" : "No"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        const isFullAccess = hasFullAccessInAnyEnv(Environments);
        if (!isFullAccess) return null;
        const isFullAccessWithProductionAccess =
          hasFullAccessToProduction(Environments);
        const isUpdateAccess =
          !record?.isProductionUse ||
          (record?.isProductionUse && isFullAccessWithProductionAccess);

        return isUpdateAccess ? (
          <>
            <Space>
              <HistoryOutlined
                id={`auditTarget-${record.id}`}
                title="Audit Logs"
                onClick={() =>
                  setIsAuditModal({
                    open: true,
                    data: {
                      entityType: AuditEntityType.Target,
                      entityName: record.name,
                      entityId: record.id,
                    },
                  })
                }
              />
              <Divider type="vertical"></Divider>
              <EditOutlined
                title="Edit"
                onClick={() => {
                  editProductTarget(record);
                }}
              />
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure to delete this target?"
                onConfirm={() => {
                  deleteProductTarget(record);
                }}
              >
                <DeleteOutlined title="Delete" />
              </Popconfirm>
            </Space>
          </>
        ) : null;
      },
    },
  ];

  columns.forEach((column) => {
    if (column.key !== "action") {
      column.sortOrder = sortField === column.dataIndex && sortOrder;
    }
  });
  return (
    <>
      {isAuditModal.open &&
        isAuditModal.data?.entityType &&
        isAuditModal.data?.entityId && (
          <AuditModalContainer
            entityType={isAuditModal.data.entityType}
            entityName={isAuditModal.data.entityName}
            entityId={isAuditModal.data.entityId}
            onClose={() =>
              setIsAuditModal({
                open: false,
                data: null,
              })
            }
          />
        )}
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={listFilterSubmit}
        onReset={resetFilter}
        initialValues={listFilterData}
      />
      <TableLayout>
        <Table
          className="wid-fix"
          dataSource={productTargets?.result}
          columns={columns}
          size="small"
          rowKey={"id"}
          onChange={handleTableChange}
          pagination={{
            showTotal: (total, range) => (
              <TotalReocordsInListingComponent
                totalRecords={paging.totalRecords}
              />
            ),
            pageSize: paging.pageSize,
            total: paging.totalPages * paging.pageSize,
            current: paging.pageIndex,
            totalRecords: paging.totalRecords,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
          }}
        />
      </TableLayout>
    </>
  );
};

export default ListTargetProductComponent;

