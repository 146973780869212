import React, { useState } from "react";
import JsonForm from "../Common/JsonForm";
import JsonEditor from "../Common/jsonEditor";
import { Button, Modal, Popover } from "antd";
import NotificationService from "../../Services/notification";
import { isNullOrEmpty } from "../../Util/commonUtility";
import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";

const RolloutEditor = (props) => {
  const {
    editorSchema,
    formSchema,
    mode = "view",
    formValues,
    setFormValues,
    setCanSubmit,
    formContainerRef,
    formEditorRef,
    validationErrors,
    setValidationErrors,
    showDuplicationPopUp,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const HelpContent = () => (
    <div className="p-2" style={{ maxWidth: "300px" }}>
      <h5 className="fw-bold mb-2">How to Use the JSON Editor?</h5>
      <ul className="list-group">
        <li className="list-group-item !py-1 border-0 ps-0">
          ➤ Initially, the editor will not be visible.
        </li>
        <li className="list-group-item !py-1 border-0 ps-0">
          ➤ When you select any feature, the editor and the generated form for
          that feature will become visible.
        </li>
        <li className="list-group-item !py-1 border-0 ps-0">
          ➤ The editor is only for previewing the JSON values.
        </li>
        <li className="list-group-item !py-1 border-0 ps-0">
          ➤ You can change the form values, and the changed form values will be
          previewed in the editor.
        </li>
      </ul>
    </div>
  );

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(formValues, null, 2)).then(
      () => NotificationService.success("JSON copied to clipboard."),
      (error) => console.error("Error copying text:", error)
    );
  };

  const showJsonModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="feature-flag-content flex !flex-col flex-grow  min-h-0">
        <div className="feature-rollout-editor-container flex !flex-col flex-grow min-h-0">
          {!isNullOrEmpty(formSchema) && (
            <>
              <div className="flex items-center justify-between gap-2">
                <span className="text-[14px] font-semibold">Feature Data</span>
                <div className="flex gap-2">
                  <Button
                    type="primary"
                    icon={<CopyOutlined />}
                    onClick={handleCopyToClipboard}
                  >
                    Copy JSON
                  </Button>
                  <Button type="default" onClick={showJsonModal}>
                    Show JSON
                  </Button>
                  <Popover
                    content={
                      <div>
                        <HelpContent />
                      </div>
                    }
                    trigger="click"
                  >
                    <Button type="default">
                      <InfoCircleOutlined
                        title={"Click for JSON Editor Instructions"}
                        className={`text-[18px] cursor-pointer `}
                      />
                    </Button>
                  </Popover>
                </div>
              </div>
              <div className="border p-3 rounded-lg !border-[#eeeff2] flex-grow overflow-y-auto min-h-0">
                <JsonForm
                  jsonSchema={formSchema}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  setCanSubmit={setCanSubmit}
                  formContainerRef={formContainerRef}
                  formEditorRef={formEditorRef}
                  validationErrors={validationErrors}
                  setValidationErrors={setValidationErrors}
                  showDuplicationPopUp={showDuplicationPopUp}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {/* Modal for displaying JSON */}
      <Modal
        title="Json Data"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width="50vw"
        className=""
        height="50vh"
      >
        <div className="min-h-[50vh] max-h-[70vh] overflow-scroll">
          <JsonEditor jsonSchema={editorSchema} mode={mode} />
        </div>
      </Modal>
    </>
  );
};

export default RolloutEditor;

