import React, { useEffect, useRef } from "react";
import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import { isNullOrEmpty } from "../../Util/commonUtility";
import { emptyJson, inValidJson } from "../../Util/config";
import InfoPopover from "./infoPopover";

const JsonEditor = ({
  jsonSchema,
  mode = "code",
  onChangeJSON = () => {},
  setCanSubmit,
  contentData,
  copyJson,
  rolloutContentData,
}) => {
  const containerRef = useRef(null);
  const jsonEditorRef = useRef(null);

  useEffect(() => {
    const options = {
      onChange: () => {
        if (jsonEditorRef.current) {
          jsonEditorRef.current.validate().then((errors) => {
            if (errors && errors.length > 0) {
              setCanSubmit && setCanSubmit(inValidJson);
            } else {
              if (mode === "view") {
                jsonEditorRef.current.expandAll();
              }
              setCanSubmit && setCanSubmit(false);
            }
          });
          try {
            const updatedJSON = jsonEditorRef.current?.get();
            if (JSON.stringify(updatedJSON) === JSON.stringify({})) {
              setCanSubmit(emptyJson);
            }
            onChangeJSON(updatedJSON);
          } catch (error) {
            onChangeJSON(null);
            setCanSubmit(emptyJson);
          }
        }
      },
      mode: mode,
      navigationBar: false,
    };
    if (!isNullOrEmpty(jsonSchema)) {
      jsonEditorRef.current = new JSONEditor(containerRef.current, options);
      jsonEditorRef.current.set(jsonSchema);
      mode === "view" && jsonEditorRef.current.expandAll();
    }
    return () => {
      if (jsonEditorRef.current) {
        jsonEditorRef.current.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonSchema, mode]);

  return (
    <div
      className="jsoneditor-react-container position-relative"
      ref={containerRef}
    >
      {contentData && (
        <InfoPopover
          iconColor={"white"}
          titleText={"Click for JSON Editor Instructions"}
          className={"position-absolute top-2 right-3 z-10"}
          helpItems={contentData}
        />
      )}
      <div className="position-absolute flex gap-2 w-20 top-2 left-[3.5rem] z-10">
        {copyJson}
        {rolloutContentData && (
          <InfoPopover
            iconColor={"white"}
            titleText={"Click for JSON Editor Instructions"}
            className={""}
            helpItems={rolloutContentData}
          />
        )}
      </div>
    </div>
  );
};

export default JsonEditor;

