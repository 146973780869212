import * as diff from "diff";

const jsDiff = diff;

export const LineNumberPrefix = {
  LEFT: "L",
  RIGHT: "R",
};
const DiffType = {
  DEFAULT: 0,
  ADDED: 1,
  REMOVED: 2,
};

// See https://github.com/kpdecker/jsdiff/tree/v4.0.1#api for more info on the below JsDiff methods
const DiffMethod = {
  CHARS: "diffChars",
  WORDS: "diffWords",
  WORDS_WITH_SPACE: "diffWordsWithSpace",
  LINES: "diffLines",
  TRIMMED_LINES: "diffTrimmedLines",
  SENTENCES: "diffSentences",
  CSS: "diffCss",
};

/**
 * Splits diff text by new line and computes final list of diff lines based on
 * conditions.
 *
 * @param {string} value Diff text from the js diff module.
 * @returns {string[]} Processed lines
 */
const constructLines = (value) => {
  const lines = value.split("\n");
  const isAllEmpty = lines.every((val) => !val);
  if (isAllEmpty) {
    // This is to avoid added an extra new line in the UI.
    if (lines.length === 2) {
      return [];
    }
    lines.pop();
    return lines;
  }

  const lastLine = lines[lines.length - 1];
  const firstLine = lines[0];
  // Remove the first and last element if they are new line character. This is
  // to avoid addition of extra new line in the UI.
  if (!lastLine) {
    lines.pop();
  }
  if (!firstLine) {
    lines.shift();
  }
  return lines;
};

/**
 * Computes word diff information in the line.
 *
 * @param {string} oldValue Old word in the line.
 * @param {string} newValue New word in the line.
 * @param {string} [compareMethod='diffChars'] JsDiff text diff method
 * @returns {Object} Computed diff information
 */
const computeDiff = (oldValue, newValue, compareMethod = DiffMethod.CHARS) => {
  const diffArray = jsDiff[compareMethod](oldValue, newValue);
  const computedDiff = {
    left: [],
    right: [],
  };
  diffArray.forEach(({ added, removed, value }) => {
    const diffInformation = {};
    if (added) {
      diffInformation.type = DiffType.ADDED;
      diffInformation.value = value;
      computedDiff.right.push(diffInformation);
    }
    if (removed) {
      diffInformation.type = DiffType.REMOVED;
      diffInformation.value = value;
      computedDiff.left.push(diffInformation);
    }
    if (!removed && !added) {
      diffInformation.type = DiffType.DEFAULT;
      diffInformation.value = value;
      computedDiff.right.push(diffInformation);
      computedDiff.left.push(diffInformation);
    }
    return diffInformation;
  });
  return computedDiff;
};

/**
 * Computes line wise information based in the js diff information passed.
 *
 * @param {string} oldString Old string to compare.
 * @param {string} newString New string to compare with old string.
 * @param {boolean} [disableWordDiff=false] Flag to enable/disable word diff.
 * @param {string} [compareMethod='diffChars'] JsDiff text diff method
 * @param {number} [linesOffset=0] Line number to start counting from
 * @returns {Object} Computed line information
 */
const computeLineInformation = (
  oldString,
  newString,
  disableWordDiff = false,
  compareMethod = DiffMethod.CHARS,
  linesOffset = 0
) => {
  const diffArray = diff.diffLines(
    oldString.trimRight(),
    newString.trimRight(),
    {
      newlineIsToken: true,
      ignoreWhitespace: false,
      ignoreCase: false,
    }
  );
  let rightLineNumber = linesOffset;
  let leftLineNumber = linesOffset;
  let lineInformation = [];
  let counter = 0;
  const diffLines = [];
  const ignoreDiffIndexes = [];

  const getLineInformation = (
    value,
    diffIndex,
    added,
    removed,
    evaluateOnlyFirstLine
  ) => {
    const lines = constructLines(value);

    return lines
      .map((line, lineIndex) => {
        const left = {};
        const right = {};
        if (
          ignoreDiffIndexes.includes(`${diffIndex}-${lineIndex}`) ||
          (evaluateOnlyFirstLine && lineIndex !== 0)
        ) {
          return undefined;
        }
        if (added || removed) {
          if (!diffLines.includes(counter)) {
            diffLines.push(counter);
          }
          if (removed) {
            leftLineNumber += 1;
            left.lineNumber = leftLineNumber;
            left.type = DiffType.REMOVED;
            left.value = line || " ";
            // When the current line is of type REMOVED, check the next item in
            // the diff array whether it is of type ADDED. If true, the current
            // diff will be marked as both REMOVED and ADDED. Meaning, the
            // current line is a modification.
            const nextDiff = diffArray[diffIndex + 1];
            if (nextDiff && nextDiff.added) {
              const nextDiffLines = constructLines(nextDiff.value)[lineIndex];
              if (nextDiffLines) {
                const {
                  value: rightValue,
                  lineNumber,
                  type,
                } = getLineInformation(
                  nextDiff.value,
                  diffIndex,
                  true,
                  false,
                  true
                )[0].right;
                // When identified as modification, push the next diff to ignore
                // list as the next value will be added in this line computation as
                // right and left values.
                ignoreDiffIndexes.push(`${diffIndex + 1}-${lineIndex}`);
                right.lineNumber = lineNumber;
                right.type = type;
                // Do word level diff and assign the corresponding values to the
                // left and right diff information object.
                if (disableWordDiff) {
                  right.value = rightValue;
                } else {
                  const computedDiff = computeDiff(
                    line,
                    rightValue,
                    compareMethod
                  );
                  right.value = computedDiff.right;
                  left.value = computedDiff.left;
                }
              }
            }
          } else {
            rightLineNumber += 1;
            right.lineNumber = rightLineNumber;
            right.type = DiffType.ADDED;
            right.value = line;
          }
        } else {
          leftLineNumber += 1;
          rightLineNumber += 1;

          left.lineNumber = leftLineNumber;
          left.type = DiffType.DEFAULT;
          left.value = line;
          right.lineNumber = rightLineNumber;
          right.type = DiffType.DEFAULT;
          right.value = line;
        }

        counter += 1;
        return { right, left };
      })
      .filter(Boolean);
  };

  diffArray.forEach(({ added, removed, value }, index) => {
    lineInformation = [
      ...lineInformation,
      ...getLineInformation(value, index, added, removed),
    ];
  });

  return {
    lineInformation,
    diffLines,
  };
};

export { computeLineInformation, DiffType, DiffMethod };

