import React, { useEffect, useState } from "react";
import logo from "../../assets/rs_icon.ico";
import "./mainLayout.scss";
import { Button, Dropdown, Layout, Space } from "antd";
import {
  LogoutOutlined,
  PlusOutlined,
  UnlockOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import {
  THEME_KEY,
  breadCrumbsPathForPages,
  getLocalStorage,
  isJson,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import { Role, showProductItemInNav } from "../../Constant/Common";
import sun from "./../../assets/sunny .png";
import moon from "./../../assets/half-moon.png";
import BreadCrumb from "../Common/breadCrumb";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";
import ChangePasswordModal from "../Settings/changePasswordModal";
import ProfileModal from "../Settings/profileModal";
const { Header, Content } = Layout;

const MainLayout = (props) => {
  const match = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const productState = useSelector((state) => state.product);
  const [selectedTab, setSelectedTab] = useState({ id: "", action: "list" });
  const authState = useSelector((state) => state.auth);
  const localAccount = localStorage.getItem("account");
  const [breadcrumbProps, setBreadcrumbProps] = useState({});
  const [extraButton, setExtraButton] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const prepareExtraButton = (title, actionCallback) => {
    return (
      <ShowForRightAccessComponent>
        <Button
          type="primary"
          className="common-button"
          onClick={actionCallback}
          title={title}
          icon={<PlusOutlined />}
        >
          {title}
        </Button>
      </ShowForRightAccessComponent>
    );
  };
  const onMenuItemClick = (e) => {
    switch (e) {
      case "home":
        navigate("/");
        break;
      case "dashboard":
        navigate("/dashboard");
        break;
      case "details":
        navigate("/product/details");
        break;
      case "logout":
        navigate("/logout");
        break;
      case "tenant-users":
        navigate("/tenant-users");
        break;
      case "changePassword":
        setIsChangePasswordModalOpen(true);
        break;
      case "settings":
        setIsProfileModalOpen(true);
        break;
      default:
        break;
    }
  };
  let newBreadcrumbs = {};
  const updateBreadcrumb = () => {
    if (location.pathname.includes("/product")) {
      newBreadcrumbs = {
        defaultValue: productState?.selectedProduct?.name,
        selection: productState?.allProducts?.result,
        breadCrumbItems: setBreadCrumItem(selectedTab),
      };
    } else if (location.pathname.includes("/tenant")) {
      newBreadcrumbs = {
        breadCrumbItems: setTenantUsersBreadCrumbItem(selectedTab),
      };
    } else if (location.pathname.includes("/settings")) {
      newBreadcrumbs = {
        breadCrumbItems: setSettingsBreadCrumbItem(selectedTab),
      };
    }
    if (JSON.stringify(breadcrumbProps) !== JSON.stringify(newBreadcrumbs)) {
      setBreadcrumbProps(newBreadcrumbs);
    }
  };

  useEffect(() => {
    updateBreadcrumb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, selectedTab]);

  const getItem = (label, key, icon, children, type, checkForRole = false) => {
    if (checkForRole) {
      if (
        ["tenant-users"].includes(key) &&
        authState.accountData?.roles?.includes(Role.ADMIN)
      ) {
        return {
          key,
          icon,
          children,
          label,
          type,
        };
      }
    } else {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    }
  };
  const settingMenu = [
    getItem("My Account", "settings", <UserOutlined />),
    getItem("Change password", "changePassword", <UnlockOutlined />),
    getItem(
      "Tenant Users",
      "tenant-users",
      <UsergroupAddOutlined />,
      null,
      null,
      true
    ),
    {
      type: "divider",
    },
    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];
  const getUserLabel = () => {
    if (authState && authState.accountData && authState.accountData.email) {
      return authState.accountData.email;
    } else {
      return isJson(localAccount) ? JSON.parse(localAccount)?.email : "";
    }
  };

  const setSettingsBreadCrumbItem = (selectedTab) => {
    switch (selectedTab.id) {
      case "settings-1":
        let breadcrumbBuildPath = breadCrumbsPathForPages.profile;
        if (selectedTab && selectedTab.action === "edit") {
          breadcrumbBuildPath = breadCrumbsPathForPages.editprofile;
        }
        return breadcrumbBuildPath;
      case "settings-2":
        return breadCrumbsPathForPages.changepassword;
      default:
        break;
    }
  };

  const setTenantUsersBreadCrumbItem = (selectedTab) => {
    switch (selectedTab.id) {
      case "tenant-1":
        let breadcrumbBuildPath = breadCrumbsPathForPages.tenantUser;
        if (selectedTab && selectedTab.action === "add") {
          breadcrumbBuildPath = breadCrumbsPathForPages.addTenantUser;
        }
        if (selectedTab && selectedTab.action === "edit") {
          breadcrumbBuildPath = breadCrumbsPathForPages.editTenantUser;
        }
        return breadcrumbBuildPath;
      default:
        break;
    }
  };
  const setBreadCrumItem = (selectedTab) => {
    switch (selectedTab.id) {
      case "1":
        let breadcrumbBuildPath = breadCrumbsPathForPages.builds;
        if (selectedTab && selectedTab.action === "add") {
          breadcrumbBuildPath = breadCrumbsPathForPages.addBuilds;
        } else if (selectedTab && selectedTab.action === "edit") {
          breadcrumbBuildPath = breadCrumbsPathForPages.editBuilds;
        }
        return breadcrumbBuildPath;
      case "2":
        let breadcrumbPath = breadCrumbsPathForPages.releases;
        if (selectedTab && selectedTab.action === "add") {
          breadcrumbPath = breadCrumbsPathForPages.addReleases;
        } else if (selectedTab && selectedTab.action === "edit") {
          breadcrumbPath = breadCrumbsPathForPages.editReleases;
        }
        return breadcrumbPath;
      case "3":
        let breadcrumbUserPath = breadCrumbsPathForPages.targets;
        if (selectedTab && selectedTab.action === "add") {
          breadcrumbUserPath = breadCrumbsPathForPages.addTargets;
        } else if (selectedTab && selectedTab.action === "edit") {
          breadcrumbUserPath = breadCrumbsPathForPages.editTargets;
        }
        return breadcrumbUserPath;
      case "4":
        return breadCrumbsPathForPages.tests;
      case "5":
        return breadCrumbsPathForPages.productDetails;
      case "6":
        let breadcrumbTargetAttributePath =
          breadCrumbsPathForPages.productTargetAttributes;
        if (selectedTab && selectedTab.action === "add") {
          breadcrumbTargetAttributePath =
            breadCrumbsPathForPages.addProductTargetAttributes;
        } else if (selectedTab && selectedTab.action === "edit") {
          breadcrumbTargetAttributePath =
            breadCrumbsPathForPages.editProductTargetAttributes;
        }
        return breadcrumbTargetAttributePath;
      case "7":
        let breadcrumbProductUserPath = breadCrumbsPathForPages.productUsers;
        if (selectedTab && selectedTab.action === "add") {
          breadcrumbProductUserPath = breadCrumbsPathForPages.addProductUsers;
        } else if (selectedTab && selectedTab.action === "edit") {
          breadcrumbProductUserPath = breadCrumbsPathForPages.editProductUsers;
        }
        return breadcrumbProductUserPath;
      case "8":
        let breadcrumbProductFeatureFlagPath =
          breadCrumbsPathForPages.featureFlags;
        if (selectedTab && selectedTab.action === "add") {
          breadcrumbProductFeatureFlagPath =
            breadCrumbsPathForPages.addFeatureFlags;
        } else if (selectedTab && selectedTab.action === "edit") {
          breadcrumbProductFeatureFlagPath =
            breadCrumbsPathForPages.editFeatureFlags;
        }
        return breadcrumbProductFeatureFlagPath;
      case "9":
        let breadcrumbProductFeatureRolloutPath =
          breadCrumbsPathForPages.featureRollouts;
        if (selectedTab && selectedTab.action === "add") {
          breadcrumbProductFeatureRolloutPath =
            breadCrumbsPathForPages.addFeatureRollouts;
        } else if (selectedTab && selectedTab.action === "edit") {
          breadcrumbProductFeatureRolloutPath =
            breadCrumbsPathForPages.editFeatureRollouts;
        }
        return breadcrumbProductFeatureRolloutPath;

      case "product-action":
        let breadcrumbProductPath = breadCrumbsPathForPages.product;
        if (selectedTab && selectedTab.action === "add") {
          breadcrumbProductPath = breadCrumbsPathForPages.addproduct;
        }
        if (selectedTab && selectedTab.action === "edit") {
          breadcrumbProductPath = breadCrumbsPathForPages.editproduct;
        }
        return breadcrumbProductPath;

      default:
        break;
    }
  };

  const handleBuildRolloutEvent = () => {
    navigate("/product/release/add");
  };
  const handleBuildAddEvent = () => {
    navigate("/product/build/add");
  };

  const handleProductTargetEvent = () => {
    navigate("/product/target/add");
  };
  const handleTargetAttributeEvent = () => {
    navigate("/product/targetattributes/add");
  };
  const handleUserEvent = () => {
    navigate(`/product/users/add`);
  };
  const handleFeatureFlagEvent = () => {
    navigate(`/product/featureFlag/add`);
  };
  const handleFeatureRolloutEvent = () => {
    navigate(`/product/featureRollout/add`);
  };

  const onTabChange = (tabId) => {
    switch (tabId) {
      case "1":
        navigate("/product/build");
        setExtraButton(prepareExtraButton("Add Build", handleBuildAddEvent));
        break;
      case "2":
        navigate("/product/release");
        setExtraButton(
          prepareExtraButton("Add Release", handleBuildRolloutEvent)
        );
        break;
      case "3":
        navigate("/product/target");
        setExtraButton(
          prepareExtraButton("Add Target", handleProductTargetEvent)
        );
        break;
      case "4":
        navigate("/product/test");
        setExtraButton();
        break;
      case "5":
        navigate("/product/details");
        setExtraButton();
        break;
      case "6":
        navigate("/product/targetattributes");
        setExtraButton(
          prepareExtraButton("Add Target Attribute", handleTargetAttributeEvent)
        );
        break;
      case "7":
        navigate("/product/users");
        setExtraButton(prepareExtraButton("Add Product User", handleUserEvent));
        break;
      case "8":
        navigate("/product/featureFlag");
        setExtraButton(
          prepareExtraButton("Add Feature Flag", handleFeatureFlagEvent)
        );
        break;
      case "9":
        navigate("/product/featureRollout");
        setExtraButton(
          prepareExtraButton("Add Feature Rollout", handleFeatureRolloutEvent)
        );
        break;

      case "settings-1":
        navigate("/settings/profile");
        break;
      case "settings-2":
        navigate("/settings/changepassword");
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    const path = location.pathname.split("/");
    switch (path[1]) {
      case "settings":
        switch (path[2]) {
          case "profile":
            switch (path[3]) {
              case "edit":
                setSelectedTab({ id: "settings-1", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "settings-1", action: "list" });
                onTabChange("settings-1");
                break;
            }
            break;
          case "changepassword":
            setSelectedTab({ id: "settings-2", action: "list" });
            onTabChange("settings-2");
            break;
          default:
            setSelectedTab({ id: "settings-1", action: "list" });
            onTabChange("settings-1");
            break;
        }
        break;
      case "tenant-users":
        switch (path[2]) {
          case "add":
            setSelectedTab({ id: "tenant-1", action: "add" });
            break;
          case "edit":
            setSelectedTab({ id: "tenant-1", action: "edit" });
            break;
          default:
            setSelectedTab({ id: "tenant-1", action: "list" });
            break;
        }
        break;

      case "products":
        switch (path[2]) {
          case "add":
            setSelectedTab({ id: "product-action", action: "add" });
            break;
          case "edit":
            setSelectedTab({ id: "product-action", action: "edit" });
            break;
          default:
            setSelectedTab({ id: "product-action", action: "list" });
            break;
        }
        break;

      case "product":
        switch (path[2]) {
          case "build":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "1", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "1", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "1", action: "list" });
                break;
            }
            break;
          case "release":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "2", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "2", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "2", action: "list" });
                break;
            }
            break;
          case "target":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "3", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "3", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "3", action: "list" });
                break;
            }
            break;
          case "test":
            setSelectedTab({ id: "4", action: "list" });
            break;
          case "details":
            setSelectedTab({ id: "5", action: "list" });
            break;
          case "targetattributes":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "6", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "6", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "6", action: "list" });
                break;
            }
            break;
          case "users":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "7", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "7", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "7", action: "list" });
                break;
            }
            break;
          case "featureFlag":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "8", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "8", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "8", action: "list" });
                break;
            }
            break;
          case "featureRollout":
            switch (path[3]) {
              case "add":
                setSelectedTab({ id: "9", action: "add" });
                break;
              case "edit":
                setSelectedTab({ id: "9", action: "edit" });
                break;
              default:
                setSelectedTab({ id: "9", action: "list" });
                break;
            }
            break;

          default:
            setSelectedTab({ id: "1", action: "list" });
            break;
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const isDarkMode = getLocalStorage(THEME_KEY) || false;

  useEffect(() => {
    if (
      showProductItemInNav.findIndex((a) => a === match.pathname) < -1 &&
      isNullOrEmpty(productState?.selectedProduct)
    ) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout className="layout">
      <Header className="layout-header border-bottom">
        <div className="layout-header-container">
          <div className="layout-header-left-container">
            <img
              onClick={() => {
                navigate("/");
              }}
              src={logo}
              alt="Company Logo"
              class="logo h-auto d-block"
            />

            {Object.keys(breadcrumbProps)?.length > 0 ? (
              <BreadCrumb {...breadcrumbProps} />
            ) : null}
          </div>
          <div className="layout-header-right-container">
            <Space>
              <Dropdown.Button
                menu={{
                  items: settingMenu,
                  onClick: (e) => onMenuItemClick(e.key),
                }}
                placement="bottom"
                icon={<UserOutlined />}
              >
                {getUserLabel()}
              </Dropdown.Button>
            </Space>

            <button
              title={`Switch between dark and light mode (currently ${
                isDarkMode ? "dark mode" : "light mode"
              })`}
              id="theme-toggle-button"
              className={`theme-toggle-button ${
                isDarkMode ? "dark-mode" : "light-mode"
              }`}
              onClick={props.onToggle}
            >
              <img
                src={isDarkMode ? moon : sun}
                alt="Theme Icon"
                style={{ width: "20px", height: "20px" }}
              />
            </button>
          </div>
        </div>
      </Header>

      <Content className="site-layout-content">
        <div className="site-layout-content-box">
          <Outlet />
        </div>
      </Content>
      {/* {
        location.pathname === '/' && <Footer className="footer">
        {footerText} {new Date().getFullYear()}
      </Footer>
      } */}

      <ProfileModal
        open={isProfileModalOpen}
        onClose={() => setIsProfileModalOpen(false)}
      />
      <ChangePasswordModal
        open={isChangePasswordModalOpen}
        onClose={() => setIsChangePasswordModalOpen(false)}
      />
    </Layout>
  );
};

export default MainLayout;

