import React from "react";
import ProductUsersContainer from "../../Containers/ProductDetails/ProductUsersContainer";

const UsersListScreen = () => {
  return (
    <>
      <React.Fragment>
        <ProductUsersContainer />
      </React.Fragment>
    </>
  );
};

export default UsersListScreen;
