import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DefaultPagingValue, DefaultSortingValue } from "../../Constant/Common";
import { useNavigate } from "react-router-dom";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import {
  convertDataToQueryParam,
  handleLinkClick,
} from "../../Util/commonUtility";
import Loader from "../../Components/Common/Loader";
import {
  setProductDetails,
  setSelectedEnvironmentDetails,
} from "../../Store/Reducers/Slices/Product/productSlice";
import ListProductsComponent from "../../Components/Products/ListProductsComponent";

import NotificationService from "../../Services/notification";
import useFetchData from "../../Hooks/useFetchData";

const ListProductsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const isPageSizeChangeRef = useRef(false);
  const [productFilterData, setProductFilterData] = useState({});
  const [paging, SetPaging] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE,
    pageIndex: DefaultPagingValue.PAGE_INDEX,
    totalPages: DefaultPagingValue.TOTAL_PAGES,
    totalRecords: DefaultPagingValue.TOTAL_RECORDS,
  });

  const {
    data: tenantProducts,
    loading,
    pagination: fetchedPagination,
  } = useFetchData(
    `${Urls.TENANT_GET_ALL_PRODUCTS.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    )}?${convertDataToQueryParam(productFilterData)}&PageNumber=${
      paging.pageIndex
    }&PageSize=${paging.pageSize}`,
    [paging.pageIndex, paging.pageSize, productFilterData]
  );

  useEffect(() => {
    if (fetchedPagination) {
      SetPaging(fetchedPagination);
    }
  }, [fetchedPagination]);

  const handlePageChange = (page, pageSize) => {
    if (isPageSizeChangeRef.current) {
      isPageSizeChangeRef.current = false;
      return;
    }
    SetPaging((prevPaging) => ({
      ...prevPaging,
      pageIndex: page,
      pageSize: pageSize,
    }));
  };

  const handlePageSizeChange = (current, pageSize) => {
    isPageSizeChangeRef.current = true;

    SetPaging((prevPaging) => ({
      ...prevPaging,
      pageIndex: DefaultPagingValue.PAGE_INDEX,
      pageSize: pageSize,
    }));
  };

  const addEditProduct = (id = null) => {
    if (id) {
      navigate(`/products/edit/${id}`);
    } else {
      navigate("/products/add");
    }
  };
  const viewProductDetails = (data, target = "product") => {
    dispatch(
      setProductDetails({
        selectedProduct: data,
        allProducts: tenantProducts,
      })
    );
    if (target === "details") {
      navigate("/product/details");
    } else {
      navigate("/product/build");
    }
  };

  const deleteProduct = async (data) => {
    const response = await httpClient.delete(
      `${Urls.TENANT_DELETE_PRODUCT_BY_ID.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      ).replace("#{productId}", data.id)}`
    );
    if (isSuccessStatus(response?.status)) {
      NotificationService.success("Success", "Product deleted successfully");
      setProductFilterData({});
    }
  };

  const onProductEnvironmentClick = (event, envId, productData) => {
    dispatch(
      setProductDetails({
        selectedProduct: productData,
        allProducts: tenantProducts,
      })
    );
    dispatch(
      setSelectedEnvironmentDetails({
        selectedEnvironmentId: envId,
        selectedProductId: productData.id,
      })
    );
    handleLinkClick(event, "/product/build", navigate);
  };

  const productFilterSubmit = (data) => {
    setProductFilterData(data);
    SetPaging((prev) => ({ ...prev, pageIndex: 1 }));
  };

  const resetFilter = () => {
    setProductFilterData({});
    SetPaging((prev) => ({ ...prev, pageIndex: 1 }));
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ListProductsComponent
          dataSource={tenantProducts}
          viewProductDetails={(data) => viewProductDetails(data, "details")}
          productsFilterData={productFilterData}
          addProduct={() => addEditProduct()}
          viewProduct={(data) => viewProductDetails(data)}
          editProduct={(data) => addEditProduct(data.id)}
          deleteProduct={(data) => deleteProduct(data)}
          onProductEnvironmentClick={(e, envId, productData) =>
            onProductEnvironmentClick(e, envId, productData)
          }
          handleSearch={productFilterSubmit}
          handleReset={resetFilter}
          paging={paging}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
        />
      )}
    </>
  );
};

export default ListProductsContainer;
