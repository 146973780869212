import { useState, useEffect } from "react";
import { httpClient, isSuccessStatus } from "../Api/client";
import { isNullOrEmpty } from "../Util/commonUtility";
import { DefaultPagingValue } from "../Constant/Common";

const useFetchData = (url, dependencies = []) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE || 10,
    pageIndex: DefaultPagingValue.PAGE_INDEX || 1,
    totalPages: 0,
    totalRecords: 0,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await httpClient.get(url);
      if (isSuccessStatus(response.status) && !isNullOrEmpty(response.data)) {
        setData(response.data);
        const pagination = response.data.pagingStrategy;
        if (pagination) {
          const isNullData = isNullOrEmpty(response.data.result);
          const currentPage = pagination.currentPage;
          const defaultCurrentPage = DefaultPagingValue.PAGE_INDEX;
          const newPageIndex =
            isNullData && currentPage > defaultCurrentPage
              ? currentPage - defaultCurrentPage
              : currentPage;
          setPagination({
            pageIndex: newPageIndex,
            pageSize: pagination.pageSize,
            totalPages: pagination.totalPages,
            totalRecords: pagination.totalRecords,
          });
        }
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, dependencies);

  return { data, loading, pagination, refetch: fetchData };
};

export default useFetchData;
