import { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocalStorageAccountInfo } from "../Util/commonUtility";
import { Role } from "../Constant/Common";

export const useExtraButtons = (prepareExtraButton) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [extraButton, setExtraButton] = useState(null);
  const [loggedInUserDetails, setLoggedInUserDetails] = useState(null);

  useEffect(() => {
    const userData = getLocalStorageAccountInfo();
    setLoggedInUserDetails(userData);
  }, []);

  const updateExtraButtons = useCallback(
    (tabId) => {
      const buttonConfig = {
        // Configuration object for extra buttons. Keys represent tab IDs, and values contain button details.
        1: { extra: "Add Build" },
        2: { extra: "Add Release" },
        3: { extra: "Add Target" },
        6: { extra: "Add Target Attribute" },
        7: { extra: "Add Product User" },
        8: { extra: "Add Feature" },
        9: { extra: "Add Feature Rollout" },
        10: { extra: "Add Content Template" },
        11: { extra: "Add Content Rollout" },
        12: { extra: "Add File" },
        13: { extra: "Add Environment" },
      };

      const config = buttonConfig[tabId];
      if (config) {
        if (tabId === "6") {
          loggedInUserDetails?.roles[0] === Role.ADMIN
            ? setExtraButton(
                prepareExtraButton(config.extra, () =>
                  navigate(`${location.pathname}/add`)
                )
              )
            : setExtraButton(null);
        } else {
          setExtraButton(
            prepareExtraButton(config.extra, () =>
              navigate(`${location.pathname}/add`)
            )
          );
        }
      } else {
        setExtraButton(null);
      }
    },
    [
      loggedInUserDetails?.roles,
      prepareExtraButton,
      navigate,
      location.pathname,
    ]
  );

  return { extraButton, updateExtraButtons };
};

