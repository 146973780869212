import React from "react";
import { Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const InfoPopover = ({ helpItems , className , iconColor , titleText }) => {
    return (
        <Popover  className={className} content={<div>{helpItems}</div>} trigger="click">
        <InfoCircleOutlined title={titleText} className={`text-[18px] text-${iconColor} cursor-pointer `} />
      </Popover>
    )
}

export default InfoPopover;
