import React from "react";
import AddEnvironmentContainer from "../../Containers/ProductDetails/AddEnvironmentContainer";

const EnvironmentsAddScreen = () => {
  return (
    <>
      <React.Fragment>
        <AddEnvironmentContainer />
      </React.Fragment>
    </>
  );
};

export default EnvironmentsAddScreen;
