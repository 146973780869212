import React from "react";
import JsonForm from "../Common/JsonForm";
import JsonEditor from "../Common/jsonEditor";
import { Button, Drawer, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const FlagEditor = ({
  editorSchema,
  formSchema,
  onChangeJSON = () => {},
  mode = "code",
  formValues,
  setFormValues,
  setCanSubmit,
  formContainerRef,
  formEditorRef,
  validationErrors,
  setOpen,
  open,
  setValidationErrors,
  canSubmit,
}) => {
  const HelpContent = () => (
    <div className="p-2" style={{ maxWidth: "300px" }}>
      <h5 className="fw-bold mb-2">How to Use the JSON Editor?</h5>
      <ul className="list-group ">
        <li className="list-group-item !py-1 border-0 ps-0">
          ➤ Enter your JSON schema in the editor.
        </li>
        <li className="list-group-item !py-1 border-0 ps-0">
          ➤ Once you add a valid schema, a form will be generated on the right
          side.
        </li>
        <li className="list-group-item !py-1 border-0 ps-0">
          ➤ Modify the schema, and the form will automatically update based on
          the new schema.
        </li>
        <li className="list-group-item !py-1 border-0 ps-0">
          ➤ Fill out the form, and the values will be saved as per the schema.
        </li>
      </ul>
      <p className="text-muted small">
        Ensure your schema is correct by following proper JSON schema standards.
        The generated form will allow you to input values that conform to the
        provided schema.
      </p>
    </div>
  );

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Space direction="vertical" className="w-full">
      <Drawer
        width={650}
        prefixCls="json-editor-drawer"
        closable={false}
        title="Template"
        placement="right"
        open={open}
        onClose={onClose}
        extra={
          <Space>
            <Button className="view-button-cancel" onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <div className="m-3">
          <JsonEditor
            contentData={<HelpContent />}
            jsonSchema={editorSchema}
            onChangeJSON={onChangeJSON}
            setCanSubmit={setCanSubmit}
            mode={mode}
          />
        </div>
      </Drawer>

      <JsonForm
        jsonSchema={formSchema}
        formValues={formValues}
        setFormValues={setFormValues}
        setCanSubmit={setCanSubmit}
        canSubmit={canSubmit}
        formContainerRef={formContainerRef}
        formEditorRef={formEditorRef}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
      />
    </Space>
  );
};
export default FlagEditor;

