import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Space, Switch } from "antd";
import { useEffect, useRef, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { getSpaceValidationRule, isNullOrEmpty, scrollToErrorOnForm } from "../../Util/commonUtility";
import FlagEditor from "./FlagEditor";
import { defaultConfig } from "./defaultConfig";
import { FEATURE_CODE_REGEX, maxNameCharacterLength } from "../../Constant/Common";
import { useLocation } from "react-router-dom";
import FormLayout from "../Layout/formLayout";
import InfoDetails from "../Common/editHistoryInfo";

const AddProductFeatureFlagComponent = (props) => {
  const {
    id,
    onFinish,
    backToListPage,
    editProductFeatureFlagData,
    setCanSubmit,
    canSubmit,
  } = props;
  const location = useLocation();
  const [addProductFeatureFlagForm] = Form.useForm();
  const [editorSchema, setEditorSchema] = useState({});
  const [formSchema, setFormSchema] = useState({});
  const [formValues, setFormValues] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);
  const formContainerRef = useRef(null);
  const formEditorRef = useRef(null);
  const featureHeading = location.pathname.includes("add") ? "Add" : "Edit";
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (
      !editProductFeatureFlagData &&
      !isNullOrEmpty(defaultConfig) &&
      isNullOrEmpty(editorSchema)
    ) {
      setEditorSchema(defaultConfig);
      setFormSchema(defaultConfig);
      addProductFeatureFlagForm.setFieldValue("SchemaConfig", defaultConfig);
    }
  }, [
    addProductFeatureFlagForm,
    editProductFeatureFlagData,
    editorSchema,
    formSchema,
  ]);

  const handleFinish = (values) => {
    if (validationErrors.length > 0) {
      scrollToErrorOnForm(validationErrors.slice(-1)[0].path, formEditorRef);
    }
    onFinish(values);
  };

  const handleFinishFailed = () => {
    if (validationErrors.length > 0) {
      scrollToErrorOnForm(validationErrors.slice(-1)[0].path, formEditorRef);
    }
  };

  const onChangeJSON = (json) => {
    if (json) {
      setFormSchema(json);
      addProductFeatureFlagForm.setFieldValue("SchemaConfig", json);
    } else {
      setFormSchema(null);
      addProductFeatureFlagForm.setFieldValue("SchemaConfig", null);
    }
  };

  useEffect(() => {
    if (formValues && !isNullOrEmpty(formValues)) {
      addProductFeatureFlagForm.setFieldValue("defaultConfig", formValues);
    }
  }, [addProductFeatureFlagForm, formValues]);

  useEffect(() => {
    if (editProductFeatureFlagData) {
      if (editProductFeatureFlagData.defaultConfig) {
        if (typeof editProductFeatureFlagData.defaultConfig === "string") {
          const configValue = JSON.parse(
            editProductFeatureFlagData?.defaultConfig
          );
          setFormValues(configValue);
        } else {
          setFormValues(editProductFeatureFlagData?.defaultConfig);
        }
      }
      if (editProductFeatureFlagData.schemaConfig) {
        if (typeof editProductFeatureFlagData.schemaConfig === "string") {
          const schemaValue = JSON.parse(
            editProductFeatureFlagData?.schemaConfig
          );
          setEditorSchema(schemaValue);
          setFormSchema(schemaValue);
          addProductFeatureFlagForm.setFieldsValue({
            ...editProductFeatureFlagData,
            SchemaConfig: schemaValue,
          });
        } else {
          setEditorSchema(editProductFeatureFlagData?.schemaConfig);
          setFormSchema(editProductFeatureFlagData?.schemaConfig);
          addProductFeatureFlagForm.setFieldsValue({
            ...editProductFeatureFlagData,
            SchemaConfig: editProductFeatureFlagData?.schemaConfig,
          });
        }
      }
      if (editProductFeatureFlagData.SchemaConfig) {
        if (typeof editProductFeatureFlagData.SchemaConfig === "string") {
          const schemaValue = JSON.parse(
            editProductFeatureFlagData?.SchemaConfig
          );

          setEditorSchema(schemaValue);
          setFormSchema(schemaValue);
          addProductFeatureFlagForm.setFieldsValue({
            ...editProductFeatureFlagData,
            SchemaConfig: schemaValue,
          });
        } else {
          setEditorSchema(editProductFeatureFlagData?.SchemaConfig);
          setFormSchema(editProductFeatureFlagData?.SchemaConfig);
          addProductFeatureFlagForm.setFieldsValue({
            ...editProductFeatureFlagData,
            SchemaConfig: editProductFeatureFlagData?.SchemaConfig,
          });
        }
      }
    }
  }, [addProductFeatureFlagForm, editProductFeatureFlagData]);

  return (
    <FormLayout formTitle={`${featureHeading}  Feature`}>
      <>
        <Form
          initialValues={editProductFeatureFlagData}
          form={addProductFeatureFlagForm}
          flex
          flex-row
          justify-between
          w-full
          gap-4
          className="common-form flex flex-row items-start w-full gap-4 h-full"
          id="add-featureflag"
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Col className="w-1/3 border p-3 rounded-lg !border-[#eeeff2] min-h-1/3 flex-grow">
            <Form.Item hidden={true} name="id" id="id">
              <Input />
            </Form.Item>
            <div className="flex flex-row gap-2">
              <Form.Item
                label="Name"
                name="name"
                id="name"
                className="w-full !mb-2"
                rules={[
                  {
                    required: true,
                    message: "Please enter feature name!",
                  },
                  {
                    max: maxNameCharacterLength,
                    message: "Input cannot exceed 50 characters!",
                  },
                  getSpaceValidationRule()
                ]}
              >
                <Input id="releaseName" className="!border-[#eeeff2]" />
              </Form.Item>
              <Form.Item
                label="Code"
                name="code"
                id="code"
                className="w-full !mb-2"
                rules={[
                  {
                    required: true,
                    message: "Please enter feature code!",
                  },
                  {
                    pattern: FEATURE_CODE_REGEX,
                    message: "Code must consist of exactly 6 characters without spaces.",
                  }
                ]}
              >
                <Input disabled={id}
                id="code" className="!border-[#eeeff2]" />
              </Form.Item>
            </div>
            <Form.Item
              label="Description"
              name="description"
              id="description"
              className="w-full !mb-2 "
              rules={[
                {
                  required: true,
                  message: "Please enter description!",
                },
                {
                  max: 200,
                  message: "Description must be at least 200 characters",
                },
                getSpaceValidationRule()
              ]}
            >
              <TextArea
                id="releaseDescription"
                className="!border-[#eeeff2] max-h-[100px]"
              />
            </Form.Item>
            <div className="flex flex-row space-x-2">
              <Form.Item
                label="Enabled?"
                name={"isEnabled"}
                id="isEnabled"
                className="w-full !mb-2"
                valuePropName="checked"
                prefixCls="enabled-form"
              >
                <Switch id="releaseSwitch" />
              </Form.Item>
              <Form.Item className="!mb-0" id="buttons">
                <Button
                  id="backToListingPage"
                  type="primary"
                  onClick={showDrawer}
                >
                  Configure Template
                </Button>
              </Form.Item>
            </div>
          </Col>

          <Col className="w-2/3 border !border-[#eeeff2] p-2 rounded-lg h-full flex flex-col">
            <p className="font-semibold text-sm ml-2 mb-1">Template Data</p>
            <div className="p-2 min-h-0 flex-grow overflow-y-auto">
              <FlagEditor
                editorSchema={editorSchema}
                formSchema={formSchema}
                onChangeJSON={onChangeJSON}
                formValues={formValues}
                setFormValues={setFormValues}
                setCanSubmit={setCanSubmit}
                canSubmit={canSubmit}
                formContainerRef={formContainerRef}
                formEditorRef={formEditorRef}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                setOpen={setOpen}
                open={open}
                mode="code"
              />
              <Form.Item
                name={"defaultConfig"}
                hidden={true}
                id="defaultConfig"
              >
                <Input />
              </Form.Item>
              <Form.Item name={"SchemaConfig"} hidden={true} id="schemaConfig">
                <Input />
              </Form.Item>
            </div>
          </Col>

          <Form.Item
            className="!mb-0 mt-2 top-0 right-4 position-absolute"
            id="buttons"
          >
            <Space>
              <Button
                id="backToListingPage"
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  backToListPage();
                }}
              >
                Back
              </Button>
              <Button
                className="submit-btn"
                id="submitRelease"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
              {id && <InfoDetails infoData={editProductFeatureFlagData} />}
            </Space>
          </Form.Item>
        </Form>
      </>
    </FormLayout>
  );
};

export default AddProductFeatureFlagComponent;

