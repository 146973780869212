import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Select, Form, Input, Space } from "antd";
import { PHONE_NUMBER_REGEX, Role } from "../../Constant/Common";
import FormLayout from "../Layout/formLayout";
import { getSpaceValidationRule } from "../../Util/commonUtility";

const AddTenantUser = (props) => {
  const { onFinishFailed, onFinish, backToListPage } = props;
  return (
    <div className="p-[2rem] !py-3">
      <FormLayout className="w-full" formTitle={`Add Tenant User`}>
        <Form
          className="common-form lg:w-full xl:w-1/3"
          name="basic"
          initialValues={{
            remember: true,
          }}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="userName"
            className="!mb-2"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
               getSpaceValidationRule()
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            className="!mb-2"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            className="!mb-2"
            rules={[
              {
                required: false,
                message: "Please enter your Phone Number!",
              },
              {
                pattern: PHONE_NUMBER_REGEX,
                message: "Must be a valid phone number with 10 to 12 digits",
              },
            ]}
          >
            <Input
              prefixCls="mask-input"
              id="profilePhoneNumber"
              maxLength={12}
            />
          </Form.Item>
          <Form.Item
            name="role"
            label="Role"
            className="!mb-2"
            rules={[
              {
                required: true,
                message: "Please select user role!",
              },
            ]}
          >
            <Select
              placeholder="Please select role"
              className="select-min-width"
            >
              <Select.Option value={Role.ADMIN}>{Role.ADMIN}</Select.Option>
              <Select.Option value={Role.USER}>{Role.USER}</Select.Option>
              <Select.Option value={Role.CICDUser}>
                {Role.CICDUser}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                id="backToTenantUserList"
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  backToListPage();
                }}
              >
                Back
              </Button>
              <Button
                className="submit-btn"
                id="submitTenantUser"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </FormLayout>
    </div>
  );
};
export default AddTenantUser;

