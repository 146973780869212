import React, { useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import BuildRoute from "./buildRoute";
import SideBarComponent from "../../Components/Common/productInfoTabLayout";
import ReleaseRoute from "./releaseRoute";
import { Modal, Button } from "antd";
import {
  AppstoreOutlined,
  CheckCircleOutlined,
  SwapOutlined,
  AimOutlined,
  ApartmentOutlined,
  UsergroupAddOutlined,
  FlagOutlined,
  RiseOutlined,
  NotificationOutlined,
  FundViewOutlined,
  CopyOutlined,
  PlusOutlined,
  CloudServerOutlined,
} from "@ant-design/icons";
import TargetRoute from "./targetRoute";

import TestContainer from "../../Containers/Test/Test";
import DetailsContainer from "../../Containers/ProductDetails/DetailsContainer";
import TargetAttributesRoute from "../ProductDetails/TargetAttributesRoute";
import UsersRoute from "../ProductDetails/usersRoute";
import { Role } from "../../Constant/Common";
import FeatureFlagRoute from "./featureFlagRoute";
import FeatureRolloutRoute from "./featureRolloutRoute";
import ContentTemplatesRoute from "./contentTemplatesRoute";
import ContentRolloutRoutes from "./contentRolloutRoutes";
import { useExtraButtons } from "../../Hooks/useExtraButton";
import { useCopyModal } from "../../Hooks/useCopyButton";
import { useTabNavigation } from "../../Hooks/useTabNavigation";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";
import "./ProductInfoRoutes.css";
import Notfound from "../ErrorPages/notFound";
import EnvironmentsRoute from "./../ProductDetails/environmentsRoute";

const ProductInfoRoutes = () => {
  const prepareCopyExtraButton = useCallback((title, actionCallback) => {
    return (
      <ShowForRightAccessComponent>
        <Button
          type="default"
          className="common-button !mb-0"
          onClick={actionCallback}
          title={title}
          icon={<CopyOutlined />}
        >
          {title}
        </Button>
      </ShowForRightAccessComponent>
    );
  }, []);

  const prepareExtraButton = useCallback((title, actionCallback) => {
    return (
      <ShowForRightAccessComponent>
        <Button
          type="primary"
          className="common-button !mb-0"
          onClick={actionCallback}
          title={title}
          icon={<PlusOutlined />}
        >
          {title}
        </Button>
      </ShowForRightAccessComponent>
    );
  }, []);
  const { selectedTab, onTabChange } = useTabNavigation();
  const { extraButton, updateExtraButtons } =
    useExtraButtons(prepareExtraButton);
  const {
    showCopyPopUp,
    handleCancel,
    copyButton,
    getCopyTitle,
    getCopyContent,
    updateCopyButtons,
  } = useCopyModal(prepareCopyExtraButton);

  const sidebarItems = [
    {
      key: "3",
      icon: <SwapOutlined />,
      label: "Targets",
      showLabel: "Targets",
      children: [
        {
          key: "6",
          url: "/product/targetattributes",
          icon: <ApartmentOutlined />,
          label: "Target Attributes",
          access: [Role.ADMIN, Role.USER],
          showLabel: "Target Attributes",
        },
        {
          key: "3",
          url: "/product/target",
          icon: <SwapOutlined />,
          label: "Targets",
          showLabel: "Targets",
        },
      ],
    },
    {
      key: "2",
      icon: <CheckCircleOutlined />,
      label: "Releases",
      showLabel: "Releases",
      children: [
        {
          key: "1",
          url: "/product/build",
          icon: <AppstoreOutlined />,
          label: "Build",
          showLabel: "Build",
        },
        {
          key: "2",
          url: "/product/release",
          icon: <CheckCircleOutlined />,
          label: "Releases",
          showLabel: "Releases",
        },
      ],
    },
    {
      key: "8",
      icon: <FlagOutlined />,
      label: "Features",
      access: [Role.ADMIN],
      showLabel: "Features",
      children: [
        {
          key: "8",
          url: "/product/featureFlag",
          icon: <FlagOutlined />,
          label: "Features",
          showLabel: "Features",
        },
        {
          key: "9",
          url: "/product/featureRollout",
          icon: <RiseOutlined />,
          label: "Rollouts",
          showLabel: "Feature Rollouts",
        },
      ],
    },
    {
      key: "10",
      icon: <NotificationOutlined />,
      label: "Contents",
      access: [Role.ADMIN],
      showLabel: "Content Templates",
      children: [
        {
          key: "10",
          url: "/product/contentTemplates",
          icon: <NotificationOutlined />,
          label: "Templates",
          showLabel: "Content Templates",
        },
        {
          key: "11",
          url: "/product/contentRollouts",
          icon: <FundViewOutlined />,
          label: "Rollouts",
          showLabel: "Content Rollouts",
        },
      ],
    },
    {
      key: "4",
      url: "/product/test",
      icon: <AimOutlined />,
      label: "Test Rollouts",
      showLabel: "Test Rollouts",
    },
    {
      key: "7",
      url: "/product/users",
      icon: <UsergroupAddOutlined />,
      label: "Users",
      access: [Role.ADMIN],
      showLabel: "Users",
    },
    {
      key: "13",
      url: "/product/environments",
      icon: <CloudServerOutlined />,
      label: "Environments",
      access: [Role.ADMIN],
      showLabel: "Environments",
    },

    // {
    //     key: "12",
    //     url: "/product/files",
    //     icon: <FileSearchOutlined />,
    //     label: "Files",
    //     access: [Role.ADMIN],
    //     showLabel: "Files",
    // },
  ];

  React.useEffect(() => {
    if (selectedTab.action === "list") {
      updateCopyButtons(selectedTab.id);
      updateExtraButtons(selectedTab.id);
    }
  }, [selectedTab, updateCopyButtons, updateExtraButtons]);
  return (
    <>
      <div className="main-sidebar">
        <Routes>
          <Route
            element={
              <SideBarComponent
                selectedTabId={selectedTab.id}
                sidebarItem={sidebarItems}
                onTabChange={onTabChange}
                extraButton={
                  selectedTab?.action === "list" ? extraButton : null
                }
                copyButton={selectedTab?.action === "list" ? copyButton : null}
              />
            }
          >
            <Route path="/" element={<BuildRoute />} />
            <Route path="/build/*" element={<BuildRoute />} />
            <Route path="/release/*" element={<ReleaseRoute />} />
            <Route
              path="/contentTemplates/*"
              element={<ContentTemplatesRoute />}
            />
            <Route
              path="/contentRollouts/*"
              element={<ContentRolloutRoutes />}
            />
            <Route path="/target/*" element={<TargetRoute />} />
            <Route path="/test" element={<TestContainer />} />
            <Route path="/details" element={<DetailsContainer />} />
            <Route
              path="/targetattributes/*"
              element={<TargetAttributesRoute />}
            />
            <Route path="/users/*" element={<UsersRoute />} />
            <Route path="/featureFlag/*" element={<FeatureFlagRoute />} />
            <Route path="/featureRollout/*" element={<FeatureRolloutRoute />} />
            <Route
              index
              path="/environments/*"
              element={<EnvironmentsRoute />}
            />
            <Route path="*" element={<Notfound />} />
          </Route>
        </Routes>

        <Modal
          open={!!showCopyPopUp}
          title={getCopyTitle()}
          onCancel={handleCancel}
          footer={null}
        >
          {getCopyContent()}
        </Modal>
      </div>
    </>
  );
};

export default ProductInfoRoutes;
