import { useEffect, useState } from "react";
import { headerAndFilterHeight, HightMax } from "../../Constant/Common";

const TableLayout = (props) => {
  const [minHeight, setMinHeight] = useState(HightMax);

  useEffect(() => {
    const minHeightProp = props.minHeight ? props.minHeight : headerAndFilterHeight
    const updateHeight = () => {
      setMinHeight(`${window.innerHeight - minHeightProp}px`);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <div className={`border table-layout-container p-[8px] min-h-[${(minHeight)}]  !border-[#eeeff2] bg-white rounded-lg`}>
      {props.children}
    </div>
  );
};
export default TableLayout;
