import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import NotFound from "../Screens/ErrorPages/notFound";
import NotAuthorized from "../Screens/ErrorPages/notAuthorize";
import PrivateRoute from "./PrivateRoute";
import MainLayout from "../Components/Layout/mainLayout";
import LoginScreen from "../Screens/Auth/login";
import ProductsRoutes from "../Screens/Products";
import TenantUsersRoutes from "../Screens/TenantUsers";
import ProductInfoRoutes from "../Screens/ProductInfo";
import { accountLogout } from "../Store/Reducers/Slices/Auth/authSlice";
import { resetLookupSliceData } from "../Store/Reducers/Slices/LookUp/lookupSlice";
import { getLocalStorageAccountInfo } from "../Util/commonUtility";
import { matchPath } from "react-router";

const AppRoute = ({ isDarkMode, toggleTheme }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isUserAuthenticated = !!getLocalStorageAccountInfo()?.accessToken;

  useEffect(() => {
    if (location.pathname === "/logout") {
      dispatch(accountLogout());
      dispatch(resetLookupSliceData());
      window.location.href = "/login";
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (!isUserAuthenticated && location.pathname !== "/login") {
      navigate("/login");
    }
  }, [isUserAuthenticated, location.pathname, navigate]);

  const isResetPasswordRoute = matchPath(
    { path: "/resetPassword/:token", exact: true, strict: true },
    location.pathname
  );

  const isAuthExemptRoute =
    ["/login", "/logout", "/sendLink"].includes(location.pathname) ||
    isResetPasswordRoute;

  return (
    <>
      {isAuthExemptRoute ? (
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/logout" element={<LoginScreen />} />
        </Routes>
      ) : (
        <Routes>
          <Route
            element={
              <MainLayout isDarkMode={isDarkMode} onToggle={toggleTheme} />
            }
          >
            <Route path="/" element={<PrivateRoute />}>
              <Route index element={<ProductsRoutes />} />
              <Route path="dashboard" element={<ProductsRoutes />} />
              <Route path="products/*" element={<ProductsRoutes />} />
              <Route path="tenant-users/*" element={<TenantUsersRoutes />} />
              <Route path="product/*" element={<ProductInfoRoutes />} />
              {/* <Route path="settings/*" element={<Settings />} /> */}
            </Route>
            <Route
              path="*"
              element={!isUserAuthenticated ? <LoginScreen /> : <NotFound />}
            />
            <Route path="unauthorized" element={<NotAuthorized />} />
          </Route>
        </Routes>
      )}
    </>
  );
};

export default AppRoute;

