import { useEffect } from "react";
import { Form, Input, Button, Space, Tag, Popover } from "antd";
import { ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import FormLayout from "../Layout/formLayout";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSpaceValidationRule } from "../../Util/commonUtility";
import { ENVIRONMENT_CODE_REGEX } from "../../Constant/Common";

const HelpContent = () => (
  <div className="alert alert-warning d-flex mb-0 align-items-start">
    <InfoCircleOutlined className="me-2 mt-1" />
    <div>
      <p className="mb-0">
        If there are any changes in the environment, please log out of the
        system to ensure those changes are reflected. Otherwise, you may not see
        the updates.
      </p>
    </div>
  </div>
);

const AddProductEnvironmentForm = ({
  onSubmit,
  isEnvDataLoading,
  ProductEnvironmentDataById,
  backToListPage,
}) => {
  const location = useLocation();
  const templateHeading = location.pathname.includes("add") ? "Add" : "Edit";
  const [form] = Form.useForm();
  const productState = useSelector((state) => state.product);
  const productCode = productState?.selectedProduct?.code;
  const { id } = useParams();

  const handleSubmit = (values) => {
    let data;
    const payload = [
      {
        name: values.name,
        code: productCode ? `${productCode}${values.code}` : values.code,
        isProduction: false,
      },
    ];
    if (!ProductEnvironmentDataById) {
      data = payload;
    } else {
      data = values;
    }
    onSubmit(data, ProductEnvironmentDataById ? "Edit" : "Add");
    form.resetFields();
  };

  useEffect(() => {
    if (ProductEnvironmentDataById) {
      form.setFieldsValue(ProductEnvironmentDataById);
    }
  }, [ProductEnvironmentDataById, form]);

  return (
    <FormLayout formTitle={`${templateHeading} Environment`}>
      <Form
        form={form}
        initialValues={{
          environments: [{ name: "", code: "", isProduction: false }],
        }}
        onFinish={handleSubmit}
        layout="vertical"
        className="w-100"
      >
        <div className="flex flex-col w-50">
          <Form.Item
            name="name"
            label="Name"
            className="w-full !mb-2"
            rules={[
              {
                required: true,
                message: "Please enter the environment name",
              },
              getSpaceValidationRule()
            ]}
          >
            <Input
              addonAfter={
                ProductEnvironmentDataById?.isProduction === true ? (
                  <Tag color="blue" className="m-0">
                    Production
                  </Tag>
                ) : null
              }
            />
          </Form.Item>

          <Form.Item
            name="code"
            label="Code"
            className="w-full !mb-2"
            rules={
              id
                ? []
                : [
                    {
                      required: true,
                      message: "Please enter the environment code",
                    },
                    {
                      pattern: ENVIRONMENT_CODE_REGEX,
                      message: "Environment code must be exactly two characters without spaces!",
                    },
                  ]
            }
          >
            <Input maxLength={2} disabled={!!id} />
          </Form.Item>
        </div>
        <Form.Item
          className="!mb-0 mt-2 top-0 right-4 position-absolute"
          id="buttons"
        >
          <Space>
            <Button
              type="default"
              icon={<ArrowLeftOutlined />}
              onClick={backToListPage}
            >
              Back
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Popover content={<HelpContent />} trigger="click">
              <Button title="Click for Instructions">
                <InfoCircleOutlined className="text-[18px] cursor-pointer" />
              </Button>
            </Popover>
          </Space>
        </Form.Item>
      </Form>
    </FormLayout>
  );
};

export default AddProductEnvironmentForm;
