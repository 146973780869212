import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Divider, Input, Popconfirm, Space, Table, Tag } from "antd";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import CommonFilterForm from "../Common/commonFilterForm";
import { getLocalStorageAccountInfo, getSpaceValidationRule } from "../../Util/commonUtility";
import TableLayout from "../Layout/tableLayout";
import { useSelector } from "react-redux";
import { Role } from "../../Constant/Common";

const ListProductUserComponent = (props) => {
  const {
    productUsers,
    editProductUser,
    handlePageChange,
    paging,
    deleteProductUser,
    listFilterSubmit,
    resetFilter,
    listFilterData,
    handleTableChange,
  } = props;

  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);
  const productState = useSelector((state) => state.product);
  const existingEnvironments = productState?.selectedProduct?.environments;
  const isProductionEnvironment = existingEnvironments?.find(
    (env) => env.isProduction === true
  );

  useEffect(() => {
    const userData = getLocalStorageAccountInfo();
    setLoggedInUserEmail(userData?.email || null);
  }, []);
  const isFullAccess = useSelector(
    (state) => state.product.selectedEnvironment?.isFullAccess
  );
  const filterFields = [
    {
      name: "name",
      component: (
        <Input className="list-filter-common-style" placeholder="Name" />
      ),
      rules: [getSpaceValidationRule()]
    },
    {
      name: "email",
      component: (
        <Input className="list-filter-common-style" placeholder="Email" />
      ),
      rules: [getSpaceValidationRule()]
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Full Access",
      dataIndex: "userAccess",
      key: "userAccess",
      render: (userAccess, row) => {
        return userAccess.map((item, index) => {
          return (
            item.isFullAccess && (
              <Tag key={index} className="common-tag-color">
                {`${item.environmentName.toUpperCase()} ${isProductionEnvironment?.name === item.environmentName
                    ? "(Production)"
                    : ""
                  }`}
              </Tag>
            )
          );
        });
      },
    },
    {
      title: "Read-Only",
      dataIndex: "userAccess",
      key: "environmentName",
      render: (userAccess, row) => {
        return userAccess
          .filter(({ isFullAccess }) => !isFullAccess)
          .map(({ environmentName }, index) => (
            <Tag key={index} className="common-tag-color">
              {environmentName?.toUpperCase()}
            </Tag>
          ));
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        const isLoggedInUser = record.email === loggedInUserEmail;

        return (
          <Space>
            {!isLoggedInUser && (
              <>
                {record.role !== Role.ADMIN && (
                  <>
                    <EditOutlined
                      id={`editProductUser-${record.id}`}
                      title="Edit"
                      onClick={() => {
                        editProductUser(record);
                      }}
                    />
                    <Divider type="vertical" />
                  </>
                )}
                <Popconfirm
                  title="Are you sure to delete this access?"
                  onConfirm={() => {
                    deleteProductUser(record);
                  }}
                >
                  <DeleteOutlined
                    id={`deleteProductUser-${record.id}`}
                    title="Delete"
                  />
                </Popconfirm>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={listFilterSubmit}
        onReset={resetFilter}
        initialValues={listFilterData}
      />
      <TableLayout>
        <Table
          dataSource={productUsers?.result}
          pagination={{
            showTotal: (total, range) => (
              <TotalReocordsInListingComponent
                totalRecords={paging.totalRecords}
              />
            ),
            pageSize: paging.pageSize,
            total: paging.totalPages * paging.pageSize,
            current: paging.pageIndex,
            totalRecords: paging.totalRecords,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
          }}
          columns={columns.filter((column) => {
            if (column.title === "Action" && !isFullAccess) {
              return false;
            }
            return true;
          })}
          onChange={handleTableChange}
          rowKey={"id"}
          size="small"
        />
      </TableLayout>
    </>
  );
};

export default ListProductUserComponent;

