import React, { useEffect } from "react";
import ContentPlaceHolderForm from "./addContentPlaceholder";
import ListContentPlaceholder from "./listContentPlaceholder";
import { Button, Divider, Drawer, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const AddContentPlaceholder = ({
  setAllPlaceHolderData,
  placeHolderData,
  setPlaceHolderData,
  allPlaceHolderData,
  isEdit,
  open,
  setOpen,
  templateSchemaContent,
  setIsEdit,
}) => {
  const handleClose = () => {
    setPlaceHolderData(null);
    setOpen(false);
  };

  useEffect(() => {
    if (placeHolderData?.name) {
      window.scrollTo(0, 0);
    }
  }, [placeHolderData, placeHolderData?.name]);

  return (
    <Drawer
      width={650}
      closable={false}
      destroyOnClose
      title={<p>{`Configure Content Placeholder`}</p>}
      placement="right"
      open={open}
      onClose={handleClose}
      extra={
        <Space>
          <Button className="view-button-cancel" onClick={handleClose}>
            <CloseOutlined />
          </Button>
        </Space>
      }
    >
      <div className="flex flex-col gap-5">
        <ContentPlaceHolderForm
          setAllPlaceHolderData={setAllPlaceHolderData}
          placeHolderData={placeHolderData}
          setPlaceHolderData={setPlaceHolderData}
          allPlaceHolderData={allPlaceHolderData}
          isEdit={isEdit}
          open={open}
          setOpen={setOpen}
        />
        <Divider />
        <ListContentPlaceholder
          allPlaceHolderData={allPlaceHolderData}
          setAllPlaceHolderData={setAllPlaceHolderData}
          templateSchema={templateSchemaContent}
          setPlaceHolderData={setPlaceHolderData}
          setIsEdit={setIsEdit}
        />
      </div>
    </Drawer>
  );
};

export default AddContentPlaceholder;

