import {
  Button,
  Card,
  Col,
  Form,
  Image,
  message,
  Row,
  Select,
  Space,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined, InboxOutlined } from "@ant-design/icons";
import { LocalLoader } from "../Common/LocalLoader";
import { contentTypeOptions } from "../../Constant/DropDown";
import {
  FileListAcceptedFormats,
  FileListImageFormats,
  UploadFileListImageMaxSize,
} from "../../Constant/Common";
import FormLayout from "../Layout/formLayout";

const AddFileComponent = (props) => {
  const {
    onFinish,
    fileData,
    showLocalLoader,
    selectedImage,
    usedForAddFilePopUp,
    backToListPage,
    setSelectedImage,
  } = props;
  const [addFilesForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (fileData) {
      const formValues = {};
      fileData.forEach((value, key) => {
        formValues[key] = value;
      });
      const fileObject = {
        uid: formValues?.File?.uid,
        name: formValues?.File?.name,
        originFileObj: formValues?.File,
        lastModified: formValues?.File?.lastModified,
        lastModifiedDate: formValues?.File?.lastModifiedDate,
        size: formValues?.File?.size,
        type: formValues?.File?.type,
        percent: 100,
        status: "done",
        response: "ok",
      };

      const finalData = {
        ...formValues,
        File: {
          file: fileObject,
          fileList: [fileObject],
        },
      };
      setFileList([fileObject]);

      addFilesForm.setFieldsValue(finalData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData]);

  const handleFinish = (values) => {
    const data = new FormData();

    const file = fileList[0]?.originFileObj || fileList[0] || null;

    if (file) {
      data.append("FileUploadFor", values.FileUploadFor);
      data.append("File", file);
    } else {
      message.error("File upload failed.");
      return;
    }

    onFinish(data, addFilesForm);
  };

  const handleFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };

  const filesFormats = FileListImageFormats;
  const { Dragger } = Upload;

  const uploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      const isRightFormat = filesFormats.includes(file.type);
      if (!isRightFormat) {
        message.error("You can only upload image files (jpeg, jpg, png, gif).");
        return Upload.LIST_IGNORE;
      }

      const isLt5M = file.size / 1024 / 1024 <= UploadFileListImageMaxSize;
      if (!isLt5M) {
        message.error(
          `File is too big, Please upload a file smaller than 4MB!`
        );
        return Upload.LIST_IGNORE;
      }
      if (isRightFormat && isLt5M) {
        // Empty the current image if uploading new
        setSelectedImage && setSelectedImage();
        setFileList([file]);
      }
      return true;
    },
    onChange(info) {
      const { status } = info.file;

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleReset = () => {
    addFilesForm.resetFields();
    setFileList([]);
  };

  const renderForm = () => (
    <div>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Image/File Upload Type"
            name="FileUploadFor"
            id="fileType"
            rules={[
              {
                required: true,
                message: "Please select image/file type!",
              },
            ]}
          >
            <Select
              id="controlType"
              prefixCls="add-notification-placeholder-select"
            >
              {contentTypeOptions?.map((type) => (
                <Select.Option key={type.value} value={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="File"
            name="File"
            id="File"
            rules={[
              {
                required: true,
                message: "Please upload file!",
              },
            ]}
          >
            <Dragger
              {...uploadProps}
              accept={FileListAcceptedFormats}
              customRequest={dummyRequest}
              maxCount={1}
              fileList={fileList}
              onRemove={(file) => {
                setFileList((prevFileList) => {
                  return prevFileList.filter((item) => item?.uid !== file?.uid);
                });
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Accepted formats: JPG, JPEG, PNG, GIF <br />
                <br />
                Please note that only one file can be uploaded at a time.
              </p>
            </Dragger>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      {usedForAddFilePopUp ? (
        showLocalLoader ? (
          <LocalLoader />
        ) : (
          <Form
            form={addFilesForm}
            className="common-form"
            id="add-file"
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            {renderForm()}
            {!selectedImage?.addFileUri && (
              <Row>
                <Col span={24}>
                  <div className="flex gap-3">
                    <Form.Item
                      id="add-file-button"
                      prefixCls="submit-btn-placeholder"
                    >
                      <Space>
                        <Button
                          className="submit-btn"
                          id="submitAddFile"
                          type="primary"
                          htmlType="submit"
                        >
                          Submit
                        </Button>

                        <Button
                          className="submit-btn"
                          id="submitAddFile"
                          onClick={handleReset}
                        >
                          Clear
                        </Button>
                      </Space>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            )}
            {selectedImage?.addFileUri && (
              <Row>
                <Col span={24}>
                  <Image
                    src={selectedImage?.addFileUri}
                    width={"100%"}
                    height={200}
                    alt="CDN Preview"
                  />
                </Col>
              </Row>
            )}
          </Form>
        )
      ) : (
        <FormLayout formTitle={`Add File`}>
          <Form
            form={addFilesForm}
            className="common-form"
            id="add-file"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 1000,
            }}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            {renderForm()}
            {!selectedImage?.addFileUri && (
              <Row>
                <Col span={24}>
                  <div className="flex gap-3">
                    <Form.Item
                      id="add-file-button"
                      prefixCls="submit-btn-placeholder"
                    >
                      <Space>
                        <Button
                          className="submit-btn"
                          id="submitAddFile"
                          icon={<ArrowLeftOutlined />}
                          onClick={backToListPage}
                        >
                          Back
                        </Button>
                        <Button
                          className="submit-btn"
                          id="submitAddFile"
                          type="primary"
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </Space>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            )}
            {selectedImage?.addFileUri && (
              <Row>
                <Col span={24}>
                  <Image
                    src={selectedImage?.addFileUri}
                    width={"100%"}
                    height={200}
                    alt="CDN Preview"
                  />
                </Col>
              </Row>
            )}
          </Form>
        </FormLayout>
      )}
    </>
  );
};

export default AddFileComponent;

