import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { TARGET_ATTRIBUTE_TYPE } from "../../Constant/DropDown";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { NOT_ALLOW_WHITESPACE_REGEX } from "../../Constant/regex";
import { maxNameCharacterLength } from "../../Constant/Common";
import FormLayout from "../Layout/formLayout";
import InfoDetails from "../Common/editHistoryInfo";
import { getSpaceValidationRule } from "../../Util/commonUtility";

const AddTargetAttributes = (props) => {
  const { onFinish, onFinishFailed, backToListPage, targetattributeData } =
    props;
  const [form] = Form.useForm();
  const location = useLocation();
  const targetHeading = location.pathname.includes("add") ? "Add" : "Edit";

  useEffect(() => {
    targetattributeData && form.setFieldsValue(targetattributeData);
  }, [targetattributeData, form]);

  return (
    <FormLayout formTitle={`${targetHeading}  Target Attribute`}>
      <Form
        form={form}
        className="common-form flex flex-row items-start w-full gap-4 h-full"
        name="add-product"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        autoComplete="off"
      >
        <div className="p-3 border rounded-lg !border-[#eeeff2] flex flex-col space-y-4 !w-1/3">
          <Form.Item hidden={true} name="id">
            <Input />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            className="w-full !mb-2"
            rules={[
              {
                required: true,
                message: "Please input name here!",
              },
              {
                max: maxNameCharacterLength,
                message: "Input cannot exceed 50 characters!",
              },
              getSpaceValidationRule()
            ]}
          >
            <Input className="!border-[#eeeff2]" id="addTargetAttributeName" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            className="w-full mb-2"
            rules={[
              {
                required: true,
                message: "Please input your description!",
              },
              getSpaceValidationRule()
            ]}
          >
            <TextArea
              id="addTargetAttributeDescription"
              className="!border-[#eeeff2] max-h-[100px]"
            />
          </Form.Item>

          {targetattributeData && (
            <div>
              <p className="mb-1">Type </p>{" "}
              <div>
                {!targetattributeData?.isUsed ? (
                  <Form.Item
                    label={""}
                    name={"type"}
                    rules={[
                      {
                        required: true,
                        message: "Please select the type!",
                      },
                    ]}
                    // hidden={editTargetAttribute?.id ? true : false}
                  >
                    <Select
                      id="selectTargetAttributeType"
                      // defaultValue={targetattributeData?.type}
                      // disabled={editTargetAttribute?.id ? true : false}
                      options={TARGET_ATTRIBUTE_TYPE}
                    ></Select>
                  </Form.Item>
                ) : (
                  <>
                    <label
                      disabled={!targetattributeData?.isUsed}
                      className="p-1 !pl-[11px] rounded-[6px] border w-full bg-[#eeeff2] cursor-not-allowed !border-[#eeeff2]"
                    >
                      {targetattributeData.type}
                    </label>
                    <Form.Item
                      hidden={true}
                      name={"type"}
                      rules={[
                        {
                          required: true,
                          message: "Please select the type!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </>
                )}
              </div>
            </div>
          )}
          {!targetattributeData && (
            <Form.Item
              className="!mb-0"
              label={"Type"}
              name={"type"}
              rules={[
                {
                  required: true,
                  message: "Please select the type!",
                },
              ]}
              // hidden={editTargetAttribute?.id ? true : false}
            >
              <Select
                id="selectTargetAttributeType"
                showSearch
                optionFilterProp="label"
                options={TARGET_ATTRIBUTE_TYPE}
              ></Select>
            </Form.Item>
          )}
        </div>

        <Form.Item className="!mb-0 mt-2 top-0 right-4 position-absolute">
          <Space>
            <Button
              id="backToTargetAttributeListing"
              type="default"
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                backToListPage();
              }}
            >
              Back
            </Button>
            <Button id="submitTargetAttribute" type="primary" htmlType="submit">
              Submit
            </Button>
            <InfoDetails infoData={targetattributeData} />
          </Space>
        </Form.Item>
      </Form>
    </FormLayout>
  );
};
export default AddTargetAttributes;
