import React from "react";
import { Form, Select, Input, Button, Row, Col, Popconfirm, Tag } from "antd";
import { maxNameCharacterLength } from "../Constant/Common";
import { NOT_ALLOW_WHITESPACE_REGEX } from "../Constant/regex";
import { getSpaceValidationRule } from "./commonUtility";

const { Option } = Select;

export const getConfirmationText = (
  items,
  form,
  copyFromEnvironment,
  currentEnvironment
) => {
  const selectedItemName = items?.find(
    (item) => item.id === form?.getFieldValue("id")
  )?.name;

  if (selectedItemName && copyFromEnvironment && currentEnvironment) {
    return (
      <>
        Are you sure you want to copy <strong>{selectedItemName}</strong> from{" "}
        {copyFromEnvironment?.name}
        {copyFromEnvironment.isProduction && (
          <span className="!text-blue-400"> (Production)</span>
        )}{" "}
        to {currentEnvironment?.name}{" "}
        {currentEnvironment.isProduction && (
          <span className="!text-blue-400"> (Production)</span>
        )}{" "}
        ?
      </>
    );
  } else {
    return "Please fill out all the required fields before submitting the form!!!";
  }
};

export const FeatureFlagForm = ({
  form,
  onFinish,
  onCancel,
  onChange,
  items,
}) => (
  <Form layout="vertical" form={form} onFinish={onFinish}>
    <Form.Item
      name="id"
      label="Select Feature"
      rules={[
        {
          required: true,
          message: "Please select a feature!",
        },
      ]}
    >
      <Select placeholder="Select Feature" onChange={onChange}>
        {items?.map((feature) => (
          <Option key={feature.id} value={feature.id}>
            {feature.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item
      name="name"
      label="Feature Name"
      rules={[
        { required: true, message: "Please input feature name!" },
        {
          max: maxNameCharacterLength,
          message: "Input cannot exceed 50 characters!",
        },
        getSpaceValidationRule()
      ]}
    >
      <Input className="!border-[#eeeff2]" placeholder="Enter Feature Name" />
    </Form.Item>
    <Form.Item prefixCls="common-modal-footer-buttons">
      <Row gutter={10}>
        <Col>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit">
            Proceed
          </Button>
        </Col>
      </Row>
    </Form.Item>
  </Form>
);

export const FeatureRolloutForm = ({
  form,
  onFinish,
  onCancel,
  onChange,
  items,
  environmentChange,
  allEnvironments,
  showConfirm,
  setShowConfirm,
  selectedName,
  copyFromEnvironment,
  currentEnvironment,
  openPopConfirm,
}) => (
  <Form
    layout="vertical"
    form={form}
    onFinish={onFinish}
    onValuesChange={(changedValues) => {
      if (changedValues.environmentId) {
        form.setFieldsValue({
          id: undefined,
          name: undefined,
        });
        setShowConfirm && setShowConfirm(false);
      }
    }}
  >
    <Form.Item
      label="Select Environment"
      name="environmentId"
      rules={[{ required: true, message: "Please select environment!" }]}
    >
      <Select placeholder="Select Environment" onChange={environmentChange}>
        {allEnvironments?.map((env) => (
          <Option key={env.id} value={env.id}>
            <div className="flex gap-2 items-center">
              {env.name}
              {env.isProduction && (
                <Tag
                  color="processing"
                  bordered={false}
                  className="breadcrumb-tag"
                >
                  Production
                </Tag>
              )}
            </div>
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues.environmentId !== currentValues.environmentId
      }
    >
      {({ getFieldValue }) => {
        const environmentId = getFieldValue("environmentId");
        return environmentId ? (
          <>
            <Form.Item
              label="Select Feature Rollout"
              name="id"
              rules={[
                { required: true, message: "Please select feature rollout!" },
              ]}
            >
              <Select placeholder="Select Feature Rollout" onChange={onChange}>
                {items?.map((rollout) => (
                  <Option key={rollout.id} value={rollout.id}>
                    {rollout.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="name"
              label="Feature Rollout Name"
              rules={[
                {
                  required: true,
                  message: "Please input feature rollout name!",
                },
                {
                  max: maxNameCharacterLength,
                  message: "Input cannot exceed 50 characters!",
                },
                getSpaceValidationRule()
              ]}
            >
              <Input
                className="!border-[#eeeff2]"
                placeholder="Enter Feature Rollout Name"
              />
            </Form.Item>
          </>
        ) : null;
      }}
    </Form.Item>
    <Form.Item prefixCls="common-modal-footer-buttons">
      <Row gutter={10}>
        <Col>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Popconfirm
            open={showConfirm}
            prefixCls="copy-popover"
            title="Warning"
            description={getConfirmationText(
              items,
              form,
              copyFromEnvironment,
              currentEnvironment
            )}
            onConfirm={() => {
              form.submit();
              setShowConfirm && setShowConfirm(false);
            }}
            onCancel={() => setShowConfirm && setShowConfirm(false)}
            okText="Proceed"
            okButtonProps={{
              disabled: !(
                selectedName &&
                copyFromEnvironment &&
                currentEnvironment
              ),
            }}
            cancelText="No"
          >
            <Button type="primary" onClick={openPopConfirm}>
              Submit
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </Form.Item>
  </Form>
);

export const ContentTemplateForm = ({
  form,
  onFinish,
  onCancel,
  onChange,
  items,
}) => (
  <Form layout="vertical" form={form} onFinish={onFinish}>
    <Form.Item
      name="id"
      label="Select Content Template"
      rules={[
        {
          required: true,
          message: "Please select Content Template!",
        },
      ]}
    >
      <Select placeholder="Select Content Template" onChange={onChange}>
        {items?.map((content) => (
          <Option key={content.id} value={content.id}>
            {content.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item
      name="name"
      label="Content Template Name"
      rules={[
        { required: true, message: "Please input content template name!" },
        {
          max: maxNameCharacterLength,
          message: "Input cannot exceed 50 characters!",
        },
        getSpaceValidationRule()
      ]}
    >
      <Input
        className="!border-[#eeeff2]"
        placeholder="Enter Content Template Name"
      />
    </Form.Item>
    <Form.Item prefixCls="common-modal-footer-buttons">
      <Row gutter={10}>
        <Col>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit">
            Proceed
          </Button>
        </Col>
      </Row>
    </Form.Item>
  </Form>
);

export const ContentRolloutForm = ({
  form,
  onFinish,
  onCancel,
  onChange,
  items,
  environmentChange,
  allEnvironments,
  showConfirm,
  setShowConfirm,
  selectedName,
  copyFromEnvironment,
  currentEnvironment,
  openPopConfirm,
}) => (
  <Form
    layout="vertical"
    form={form}
    onFinish={onFinish}
    onValuesChange={(changedValues) => {
      if (changedValues.environmentId) {
        form.setFieldsValue({
          id: undefined,
          name: undefined,
        });
        setShowConfirm && setShowConfirm(false);
      }
    }}
  >
    <Form.Item
      label="Select Environment"
      name="environmentId"
      rules={[{ required: true, message: "Please select environment!" }]}
    >
      <Select placeholder="Select Environment" onChange={environmentChange}>
        {allEnvironments?.map((env) => (
          <Option key={env.id} value={env.id}>
            <div className="flex gap-2 items-center">
              {env.name}
              {env.isProduction && (
                <Tag color="processing" bordered={false} className="h-fit">
                  Production
                </Tag>
              )}
            </div>
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues.environmentId !== currentValues.environmentId
      }
    >
      {({ getFieldValue }) => {
        const environmentId = getFieldValue("environmentId");
        return environmentId ? (
          <>
            <Form.Item
              label="Select Content Rollout"
              name="id"
              rules={[
                { required: true, message: "Please select content rollout!" },
              ]}
            >
              <Select placeholder="Select Content Rollout" onChange={onChange}>
                {items?.map((rollout) => (
                  <Option key={rollout.id} value={rollout.id}>
                    {rollout.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="name"
              label="Content Rollout Name"
              rules={[
                {
                  required: true,
                  message: "Please input content rollout name!",
                },
                {
                  max: maxNameCharacterLength,
                  message: "Input cannot exceed 50 characters!",
                },
                getSpaceValidationRule()
              ]}
            >
              <Input
                className="!border-[#eeeff2]"
                placeholder="Enter Content Rollout Name"
              />
            </Form.Item>
          </>
        ) : null;
      }}
    </Form.Item>
    <Form.Item prefixCls="common-modal-footer-buttons">
      <Row gutter={10}>
        <Col>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Popconfirm
            open={showConfirm}
            prefixCls="copy-popover"
            title="Warning"
            description={getConfirmationText(
              items,
              form,
              copyFromEnvironment,
              currentEnvironment
            )}
            onConfirm={() => {
              form.submit();
              setShowConfirm && setShowConfirm(false);
            }}
            onCancel={() => setShowConfirm && setShowConfirm(false)}
            okText="Proceed"
            okButtonProps={{
              disabled: !(
                selectedName &&
                copyFromEnvironment &&
                currentEnvironment
              ),
            }}
            cancelText="No"
          >
            <Button type="primary" onClick={openPopConfirm}>
              Submit
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </Form.Item>
  </Form>
);

export const TargetForm = ({
  form,
  onFinish,
  onCancel,
  onChange,
  items,
  environmentChange,
  allEnvironments,
  showConfirm,
  setShowConfirm,
  selectedName,
  copyFromEnvironment,
  currentEnvironment,
  openPopConfirm,
}) => (
  <Form
    layout="vertical"
    form={form}
    onFinish={onFinish}
    onValuesChange={(changedValues) => {
      if (changedValues.environmentId) {
        form.setFieldsValue({
          id: undefined,
          name: undefined,
        });
        setShowConfirm && setShowConfirm(false);
      }
    }}
  >
    {/* <Form.Item
      label="Select Environment"
      name="environmentId"
      rules={[{ required: true, message: "Please select environment!" }]}
    >
      <Select placeholder="Select Environment" onChange={environmentChange}>
        {allEnvironments?.map((env) => (
          <Option key={env.id} value={env.id}>
            <div className="flex gap-2 items-center">
              {env.name}
              {env.isProduction && (
                <Tag color="processing" bordered={false} className="h-fit">
                  Production
                </Tag>
              )}
            </div>
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues.environmentId !== currentValues.environmentId
      }
    >
      {({ getFieldValue }) => {
        const environmentId = getFieldValue("environmentId");
        return environmentId ? (
          <>
            <Form.Item
              name="id"
              label="Select Target"
              rules={[
                {
                  required: true,
                  message: "Please select a Target!",
                },
              ]}
            >
              <Select placeholder="Select Target" onChange={onChange}>
                {items?.map((target) => (
                  <Option key={target.id} value={target.id}>
                    {target.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="name"
              label="Target Name"
              rules={[
                { required: true, message: "Please input target name!" },
                {
                  pattern: NOT_ALLOW_WHITESPACE_REGEX,
                  message: "Whitespace is not allowed in the name!",
                },
                {
                  max: maxNameCharacterLength,
                  message: "Input cannot exceed 50 characters!",
                },
              ]}
            >
              <Input placeholder="Enter Target Name" />
            </Form.Item>
          </>
        ) : null;
      }}
    </Form.Item> */}
    <>
      <Form.Item
        name="id"
        label="Select Target"
        rules={[
          {
            required: true,
            message: "Please select a Target!",
          },
        ]}
      >
        <Select placeholder="Select Target" onChange={onChange}>
          {items?.map((target) => (
            <Option key={target.id} value={target.id}>
              {target.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="name"
        label="Target Name"
        rules={[
          { required: true, message: "Please input target name!" },
          // {
          //   pattern: NOT_ALLOW_WHITESPACE_REGEX,
          //   message: "Whitespace is not allowed in the name!",
          // },
          {
            max: maxNameCharacterLength,
            message: "Input cannot exceed 50 characters!",
          },
          getSpaceValidationRule()
        ]}
      >
        <Input className="!border-[#eeeff2]" placeholder="Enter Target Name" />
      </Form.Item>
    </>

    <Form.Item prefixCls="common-modal-footer-buttons">
      <Row gutter={10}>
        <Col>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Popconfirm
            open={showConfirm}
            prefixCls="copy-popover"
            title="Warning"
            description={getConfirmationText(
              items,
              form,
              copyFromEnvironment,
              currentEnvironment
            )}
            onConfirm={() => {
              form.submit();
              setShowConfirm && setShowConfirm(false);
            }}
            onCancel={() => setShowConfirm && setShowConfirm(false)}
            okText="Proceed"
            okButtonProps={{
              disabled: !(
                selectedName
                // copyFromEnvironment &&
                // currentEnvironment
              ),
            }}
            cancelText="No"
          >
            <Button type="primary" onClick={openPopConfirm}>
              Submit
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </Form.Item>
  </Form>
);

export const ReleaseForm = ({
  form,
  onFinish,
  onCancel,
  onChange,
  items,
  environmentChange,
  allEnvironments,
  showConfirm,
  setShowConfirm,
  selectedName,
  copyFromEnvironment,
  currentEnvironment,
  openPopConfirm,
  selectedId = false,
}) => (
  <Form
    layout="vertical"
    form={form}
    onFinish={onFinish}
    onValuesChange={(changedValues) => {
      if (changedValues.environmentId) {
        form.setFieldsValue({
          id: undefined,
          name: undefined,
        });
        setShowConfirm && setShowConfirm(false);
      }
    }}
  >
    <Form.Item
      label="Select Environment"
      name="environmentId"
      rules={[{ required: true, message: "Please select environment!" }]}
    >
      <Select placeholder="Select Environment" onChange={environmentChange}>
        {allEnvironments?.map((env) => (
          <Option key={env.id} value={env.id}>
            <div className="flex gap-2 items-center">
              {env.name}
              {env.isProduction && (
                <Tag color="processing" bordered={false} className="h-fit">
                  Production
                </Tag>
              )}
            </div>
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues.environmentId !== currentValues.environmentId
      }
    >
      {({ getFieldValue }) => {
        const environmentId = getFieldValue("environmentId");
        return environmentId ? (
          <>
            <Form.Item
              name="id"
              label="Select Release"
              rules={[
                {
                  required: true,
                  message: "Please select a Release!",
                },
              ]}
            >
              <Select placeholder="Select Release" onChange={onChange}>
                {items?.map((release) => (
                  <Option key={release.id} value={release.id}>
                    {release.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="name"
              label="Release Name"
              rules={[
                { required: true, message: "Please input release name!" },
                {
                  max: maxNameCharacterLength,
                  message: "Input cannot exceed 50 characters!",
                },
                getSpaceValidationRule()
              ]}
            >
              <Input
                className="!border-[#eeeff2]"
                placeholder="Enter Release Name"
              />
            </Form.Item>
          </>
        ) : null;
      }}
    </Form.Item>

    <Form.Item prefixCls="common-modal-footer-buttons">
      <Row gutter={10}>
        <Col>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Popconfirm
            open={showConfirm}
            prefixCls="copy-popover"
            title="Warning"
            description={getConfirmationText(
              items,
              form,
              copyFromEnvironment,
              currentEnvironment
            )}
            onConfirm={() => {
              form.submit();
              setShowConfirm && setShowConfirm(false);
            }}
            onCancel={() => setShowConfirm && setShowConfirm(false)}
            okText="Proceed"
            okButtonProps={{
              disabled: !(
                selectedName &&
                copyFromEnvironment &&
                currentEnvironment &&
                selectedId
              ),
            }}
            cancelText="No"
          >
            <Button type="primary" onClick={openPopConfirm}>
              Submit
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </Form.Item>
  </Form>
);

