import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Radio, Row, Select, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { fieldGenerator, responseHandle } from "./fieldGenerator";
import { useNavigate } from "react-router-dom";
import FormLayout from "../Layout/formLayout";
import { getSpaceValidationRule, isNullOrEmpty, isValidVersion } from "../../Util/commonUtility";
import ErrorMessages from "../../Constant/strings/errors";

dayjs.locale("en");

const AddTestComponent = ({
  targetAttributeDropdownData,
  testDistributionData,
  testFormSubmitHandler,
  onClickClearButton,
  isDataAvailable,
}) => {
  const [formProductTest] = Form.useForm();
  const [testData, setTestData] = useState([]);
  const [typeArray, setTypeArray] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const navigate = useNavigate();

  const updateTypeArray = async (index, value) => {
    const formData = await formProductTest.getFieldsValue();
    const currentValue = formData?.data?.[index]?.value;
    if (!currentValue) {
      formProductTest.validateFields();
    }
    const updatedTypeArray = [...typeArray];
    updatedTypeArray[index] = value;
    setTypeArray(updatedTypeArray.filter(Boolean));
  };

  const onClearButton = () => {
    formProductTest.resetFields();
    setSelectedKeys([]);
    onClickClearButton();
  };

  const handleSubmit = (values) => {
    testFormSubmitHandler(values);
  };

  const handleSelectChange = (index, value, option) => {
    updateTypeArray(index, option.type);
    const newSelectedKeys = [...selectedKeys];
    newSelectedKeys[index] = value;
    setSelectedKeys(newSelectedKeys);
    const currentData = formProductTest.getFieldValue("data") || [];
    currentData[index] = { key: value, value: null };
    formProductTest.setFieldsValue({ data: currentData });
  };

  const isOptionDisabled = (optionKey) => {
    return selectedKeys.includes(optionKey);
  };

  useEffect(() => {
    if (targetAttributeDropdownData) {
      setTestData(targetAttributeDropdownData);
      formProductTest.resetFields();
      setSelectedKeys([]);
    }
  }, [targetAttributeDropdownData]);

  return (
    <FormLayout formTitle="Test Rollouts">
      <div className="bread-Crumb-Container">
        <Form
          form={formProductTest}
          onFinish={handleSubmit}
          initialValues={{
            testType: "build",
          }}
        >
          <Row gutter={28}>
            <Col sm={24} lg={24} xl={12} xxl={10}>
              <Form.Item label="Rollout Type" name="testType">
                <Radio.Group>
                  <Radio value={"build"} defaultChecked={true}>
                    Releases
                  </Radio>
                  <Radio value={"feature"}>Feature Rollout</Radio>
                  <Radio value={"content"}>Content Rollout</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item label="Test Data">
                <Form.List
                  label="Test Data"
                  name={"data"}
                  rules={[
                    {
                      validator: async (_, data) => {
                        if (!data || data.length < 1) {
                          return Promise.reject(
                            new Error(
                              "Please add at least one target attribute for test"
                            )
                          );
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Row key={key}>
                          <div
                            key={key}
                            className="flex flex-row gap-2 align-items-end justify-content-center"
                          >
                            {/* Select Key */}
                            <Form.Item
                              {...restField}
                              name={[name, "key"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select target attribute",
                                },
                              ]}
                            >
                              <Select
                                data-testid={`test-selection-${index}`}
                                className="test-page-select-width"
                                showSearch
                                placeholder="Key"
                                onChange={(value) => {
                                  const selectedOption = testData?.find(
                                    (option) => option.value === value
                                  );
                                  handleSelectChange(
                                    index,
                                    value,
                                    selectedOption
                                  );
                                }}
                              >
                                {testData?.map((option, idx) => (
                                  <Select.Option
                                    key={option.value}
                                    value={option.value}
                                    disabled={isOptionDisabled(option.value)}
                                    data-testid={`option-${option.value}-${idx}`}
                                  >
                                    {option.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            {/* Generate Field based on Key Type */}
                            <Form.Item
                              {...restField}
                              name={[name, "value"]}
                              initialValue={""}
                              validateTrigger="onChange"
                              getValueFromEvent={
                                typeArray[index] === "Date"
                                  ? (e) => e?.format("YYYY-MM-DD")
                                  : undefined
                              }
                              getValueProps={
                                typeArray[index] === "Date"
                                  ? (e) => ({ value: e && dayjs(e) })
                                  : undefined
                              }
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    const fieldValue = getFieldValue(["data", name, "key"]);
                                    const inputType = typeArray[0] // Get the input type
                                    const { message, pattern } = getSpaceValidationRule();
                              
                                    if (inputType === "Version") {
                                      return Promise.resolve();
                                    }
                              
                                    if (fieldValue) {
                                      if (isNullOrEmpty(value)) {
                                        return Promise.reject(`Please insert value for "${fieldValue}"`);
                                      }
                                      if (inputType === "String" && !pattern.test(value)) {
                                        return Promise.reject(message);
                                      }
                                    }
                              
                                    return Promise.resolve();
                                  },
                                }),
                                ...(typeArray[index] === "Version"
                                  ? [
                                      {
                                        required: true,
                                        message: "Please input build version!",
                                      },
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (value && !isValidVersion(value)) {
                                            return Promise.reject(
                                              `${ErrorMessages.QUERYBUILDER.TAGSELECTOR.VERSION.ERROR}`
                                            );
                                          }
                                          return Promise.resolve();
                                        },
                                      }),
                                    ]
                                  : []) // ✅ Replace `undefined` with `[]`
                              ]}
                            >
                              {fieldGenerator(typeArray[index], index)}
                            </Form.Item>
                            <Form.Item>
                              <MinusCircleOutlined
                                id="removeFieldTest"
                                onClick={() => {
                                  remove(name);
                                  const newSelectedKeys = [...selectedKeys];
                                  newSelectedKeys.splice(index, 1);
                                  setSelectedKeys(newSelectedKeys);
                                  const keyValue =
                                    formProductTest.getFieldValue("key");
                                  updateTypeArray(index, keyValue);
                                }}
                              />
                            </Form.Item>
                          </div>
                        </Row>
                      ))}

                      {/* Add Test Data Button */}
                      <Form.Item>
                        <Button
                          id="addTest"
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Test Data
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button
                    type="default"
                    htmlType="button"
                    onClick={onClearButton}
                  >
                    Clear
                  </Button>
                  <Button
                    className="submit-btn"
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </Col>
            <Col sm={24} lg={24} xl={12} xxl={14}>
              {/* Display Test Distribution Data */}
              {isDataAvailable &&
                responseHandle(
                  testDistributionData?.submittedData?.testType,
                  testDistributionData?.responseData,
                  navigate
                )}
            </Col>
          </Row>
          {/* Form.Item for Test Data */}

          {/* Clear and Submit Buttons */}
        </Form>
      </div>
    </FormLayout>
  );
};

export default AddTestComponent;
