import { fetchAllItems } from "./copyModalUtil";

export const copyButtons = (
  tabId,
  setCopyButton,
  prepareCopyExtraButton,
  setShowCopyPopUp,
  authState,
  productState,
  setAllFeatures,
  setAllTargets,
  setAllContents
) => {
  const buttonConfig = {
    2: { key: "Release", copy: "Copy Release" },
    3: { key: "Targets", copy: "Copy Target" },
    8: { key: "FeatureFlag", copy: "Copy Feature" },
    9: { key: "FeatureRollout", copy: "Copy Feature Rollout" },
    10: { key: "ContentTemplate", copy: "Copy Content Template" },
    11: { key: "ContentRollout", copy: "Copy Content Rollout" },
  };

  const config = buttonConfig[tabId];

  if (config) {
    setCopyButton(
      prepareCopyExtraButton(config.copy, () => {
        setShowCopyPopUp(config.key);
        if (config.key === "FeatureFlag") {
          fetchAllItems("features", setAllFeatures, authState, productState);
        }
        if (config.key === "Targets") {
          fetchAllItems("targets", setAllTargets, authState, productState);
        }
        if (config.key === "ContentTemplate") {
          fetchAllItems("contents", setAllContents, authState, productState);
        }
      })
    );
  } else {
    setCopyButton(null);
  }
};

