import { useState } from "react";
import "./style.css";
import SideBarMainLayout from "../Layout/sideBarMainLayout";
import SideBarContentLayout from "../Layout/sideBarContentLayout";
import { Outlet } from "react-router-dom";

const ProductInfoSidebarLayout = ({
  selectedTabId,
  sidebarItem,
  onTabChange,
  copyButton,
  extraButton,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="flex">
      <SideBarMainLayout
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        selectedTabId={selectedTabId}
        sidebarItems={sidebarItem}
        onTabChange={onTabChange}
      />
      <SideBarContentLayout
        collapsed={collapsed}
        selectedTabId={selectedTabId}
        sidebarItems={sidebarItem}
        copyButton={copyButton}
        extraButton={extraButton}
      >
        <Outlet />
      </SideBarContentLayout>
    </div>
  );
};

export default ProductInfoSidebarLayout;

