import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { PRODUCT_TYPE, PRODUCT_TYPE_ENUM } from "../../Constant/DropDown";
import { ENVIRONMENT_CODE_REGEX, maxNameCharacterLength } from "../../Constant/Common";
import FormLayout from "../Layout/formLayout";

import { useParams } from "react-router-dom";

import InfoPopover from "../Common/infoPopover";
import { useState } from "react";
import InfoDetails from "../Common/editHistoryInfo";
import NotificationService from "../../Services/notification";
import { getSpaceValidationRule } from "../../Util/commonUtility";
import { ALPHANUMERIC_REGEX, ONLY_ALPHABET_REGEX } from "../../Constant/regex";

const AddProductComponent = (props) => {
  const {
    onFinish,
    backToListPage,
    editProductData,
    setError,
    AddEnvInstructions,
    setIsModalOpen,
    isModalOpen,
  } = props;
  const [addProductForm] = Form.useForm();
  const { id } = useParams();
  const productHeading = editProductData ? "Edit" : "Add";
  const [environments, setEnvironments] = useState([]);
  const [envCodePrefixValue, setEnvCodePrefixValue] = useState();
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);

  const defaultInitialValues = {
    name: "",
    description: "",
    type: "",
    code: "",
    environments: [{ name: "", code: "", isProduction: false }],
  };

  const [isProductionAvailable, setIsProductionAvailable] = useState(false);

  const addEnvironment = () => {
    setIsAddButtonClicked(true);
    addProductForm
      .validateFields(["envName", "envCode", "isProduction"])
      .then((values) => {
        const isDuplicate = environments.some(
          (env) => env.name === values.envName || env.code === values.envCode
        );

        if (isDuplicate) {
          NotificationService.error(
            "Error",
            "Environment with the same name or code already exists."
          );
          setIsAddButtonClicked(false);
          return;
        }

        let updatedEnvironments;
        const newEnv = {
          key: Date.now(),
          name: values.envName,
          code: values.envCode?.toUpperCase() || "",
          isProduction: values.isProduction || false,
        };

        updatedEnvironments = [...environments, newEnv];

        if (newEnv.isProduction) {
          setIsProductionAvailable(true);
        }

        setEnvironments(updatedEnvironments);
        addProductForm.resetFields(["envName", "envCode", "isProduction"]);
        setIsAddButtonClicked(false);
      })
      .catch((error) => console.log("Validation Failed:", error));
  };

  const deleteEnvironment = (key) => {
    const updatedEnvironments = environments.filter((env) => env.key !== key);
    setEnvironments(updatedEnvironments);

    const hasProductionEnv = updatedEnvironments.some(
      (env) => env.isProduction
    );
    setIsProductionAvailable(hasProductionEnv);
  };

  const handleConfirmSubmit = async () => {
    if (!id && !environments?.length > 0) {
      NotificationService.error(
        "Error",
        "At least one production environment is required to create the product."
      );
      return;
    }

    try {
      setIsAddButtonClicked(false);
      await addProductForm.validateFields();
      if (id) {
        handleFinalSubmit();
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log("Validation failed:", error);
    }
  };

  const handleFinalSubmit = async () => {
    try {
      const values = addProductForm.getFieldsValue();
      const addPayload = {
        name: values.name,
        description: values.description,
        type: values.type,
        environments: environments,
        code: values.code,
        ...(values.type === PRODUCT_TYPE_ENUM.DESKTOP && {
          installerName: values.installerName,
        }),
      };
      const editPayload = {
        id: id,
        name: values.name,
        description: values.description,
        type: values.type,
        code: values.code,
        ...(values.type === PRODUCT_TYPE_ENUM.DESKTOP && {
          installerName: values.installerName,
        }),
      };

      onFinish(id ? editPayload : addPayload, id ? "Edit" : "Add");
      setIsModalOpen(false);
    } catch (error) {
      console.log("Submission failed:", error);
    }
  };

  const validateEnvField = (message, isAddBtnClicked) => ({
    validator(_, value) {
      if ((!value || value.length === 0) && environments.length === 0) {
        return Promise.reject(new Error(message));
      }

      if (value?.startsWith(" ")) {
        return Promise.reject("Environment name cannot start with spaces");
      }
      if (/\s{2,}/.test(value)) {
        return Promise.reject(
          "Environment name cannot contain consecutive spaces"
        );
      }
      if (isAddBtnClicked && (value === undefined || value === "")) {
        return Promise.reject(new Error(message));
      }
      return Promise.resolve();
    },
  });

  const validateEnvironmentCode = (message, isAddBtnClicked) => ({
    validator(_, value) {
      if ((!value || value.length === 0) && environments.length === 0) {
        return Promise.reject(new Error(message));
      }
      if (isAddBtnClicked && (value === undefined || value === "")) {
        return Promise.reject(new Error(message));
      }
      return Promise.resolve();
    },
  });

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text) => `${envCodePrefixValue || ""}${text}`,
    },
    {
      title: "Is Production",
      dataIndex: "isProduction",
      key: "isProduction",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Button type="text" onClick={() => deleteEnvironment(record.key)}>
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];
  const onFinishFormError = async () => {
    try {
      await addProductForm.validateFields(["add-product"]);
    } catch (err) {
      setError(true);
      console.log("Validation failed:", err);
    }
  };
  const ConfirmationMessage = (
    <>
      <p>Please review the environments before proceeding.</p>
      <p>Once submitted, the following restrictions apply:</p>
      <ul className="pl-4">
        <li>❌ Environments cannot be deleted.</li>
        <li>
          🔒 The production environment selection will be permanent and cannot
        </li>
        <li className="ml-4">be changed.</li>
      </ul>
    </>
  );
  const handleChangeCodeInput = (code) => {
    addProductForm.setFieldValue("code", code?.toUpperCase());
    if (code) {
      addProductForm.validateFields(["code"]).then(() => {
        setEnvCodePrefixValue(code.toUpperCase());
      });
    } else {
      setEnvCodePrefixValue("");
    }
  };

  return (
    <div className="p-[2rem] !py-3 position-relative">
      <FormLayout formTitle={`${productHeading} Product`}>
        <Form
          form={addProductForm}
          name="add-product"
          className="w-100"
          initialValues={
            editProductData ? editProductData : defaultInitialValues
          }
          onFinish={handleConfirmSubmit}
          onFinishFailed={onFinishFormError}
          autoComplete="off"
        >
          <Row gutter={24} align="top">
            <Col xs={24} sm={24} md={8} lg={7} xl={6} xxl={6}>
              <Form.Item hidden name="id">
                <Input />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: 0, padding: 0 }}
                rules={[
                  { required: true, message: "Please input your name!" },
                  {
                    max: maxNameCharacterLength,
                    message: "Input cannot exceed 50 characters!",
                  },
                  getSpaceValidationRule()
                ]}
              >
                <Input id="addProductName" className="w-full" />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: 0, padding: 0 }}
                rules={[
                  { required: true, message: "Please input your description!" },
                  getSpaceValidationRule()
                ]}
              >
                <TextArea rows={2} id="addProductDescription" />
              </Form.Item>

              <Form.Item
                label="Type"
                name="type"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: 0, padding: 0 }}
                rules={[{ required: true, message: "Please select the type!" }]}
              >
                <Select id="productType" options={PRODUCT_TYPE} disabled={id} />
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.type !== currentValues.type
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("type") === PRODUCT_TYPE_ENUM.DESKTOP ? (
                    <Form.Item
                      label="Installer Name"
                      name="installerName"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      style={{ margin: 0, padding: 0 }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter installer name!",
                        },
                        {
                          pattern: ALPHANUMERIC_REGEX,
                          message:
                            "Installer name cannot contain whitespace or special characters.",
                        },
                      ]}
                    >
                      <Input id="addProductInstallerName" className="w-full" />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Form.Item
                label="Product Code"
                name="code"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: 0, padding: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter the product code!",
                  },
                  {
                    max: 6,
                    message:
                      "The product code must be a maximum of 6 characters.",
                  },
                  {
                    min: 2,
                    message:
                      "The product code must be a minimum of 2 characters.",
                  },
                  {
                    pattern: ONLY_ALPHABET_REGEX,
                    message:
                      "Product code cannot contain whitespace, numbers or special characters.",
                  },
                ]}
              >
                <Input
                  id="addProductInstallerName"
                  className="w-full"
                  onChange={(e) => handleChangeCodeInput(e.target.value)}
                  disabled={id}
                />
              </Form.Item>
            </Col>
            {!editProductData && !id && (
              <Col xs={24} sm={24} md={16} lg={17} xl={18} xxl={18}>
                <div className="flex flex-row gap-6">
                  <p className="text-[18px] font-semibold">Environments</p>
                  {AddEnvInstructions && (
                    <InfoPopover
                      iconColor={"black"}
                      titleText={"Click for instructions to add environments"}
                      helpItems={AddEnvInstructions}
                    />
                  )}
                </div>
                <div className="flex flex-row gap-4 mt-[0.7rem]">
                  <Form.Item
                    name="envName"
                    label="Name"
                    rules={[
                      validateEnvField(
                        "Please enter the environment name",
                        isAddButtonClicked
                      ),
                      {
                        max: 50,
                        message: "Environment name cannot exceed 50 characters",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="envCode"
                    label="Code"
                    getValueProps={(e) => ({ value: e && e?.toUpperCase() })}
                    rules={
                      id
                        ? []
                        : [
                            validateEnvironmentCode(
                              "Please enter the environment code",
                              isAddButtonClicked
                            ),
                            {
                              pattern: ENVIRONMENT_CODE_REGEX,
                              message: "Environment code must be exactly two characters without spaces!",
                            },
                          ]
                    }
                  >
                    <Input maxLength={2} addonBefore={envCodePrefixValue} />
                  </Form.Item>
                  {!isProductionAvailable && (
                    <Form.Item name="isProduction" valuePropName="checked">
                      <Checkbox>Is Production Environment</Checkbox>
                    </Form.Item>
                  )}

                  <Button type="dashed" onClick={addEnvironment}>
                    Add
                  </Button>
                </div>

                <Table
                  dataSource={environments}
                  columns={columns}
                  pagination={false}
                  bordered
                />
              </Col>
            )}
          </Row>

          <Form.Item
            className="!mb-0 mt-2 top-0 right-8 position-absolute"
            id="buttons"
          >
            <Space className="btn_box">
              <Button
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={backToListPage}
              >
                Back
              </Button>

              <Button className="submit-btn" type="primary" htmlType="submit">
                Submit
              </Button>
              <InfoDetails infoData={editProductData} />
              <Modal
                title="Confirm Submission"
                open={isModalOpen}
                onOk={handleFinalSubmit}
                onCancel={() => setIsModalOpen(false)}
                okText="Yes, Confirm"
                cancelText="No"
                centered
              >
                {ConfirmationMessage}
              </Modal>
            </Space>
          </Form.Item>
        </Form>
      </FormLayout>
    </div>
  );
};
export default AddProductComponent;

