import React from "react";
import { Space, Table, Tag } from "antd";
import TableLayout from "../Layout/tableLayout";
import { EditOutlined } from "@ant-design/icons";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";
import { useSelector } from "react-redux";

const ListProductEnvironments = (props) => {
  const { productEnvironments, editProductEnvironment } = props;
  const isFullAccess = useSelector(
    (state) => state.product.selectedEnvironment?.isFullAccess
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, row) => (
        <span>
          {name}
          {row.isProduction && (
            <Tag color="blue" className="ml-2">
              Production
            </Tag>
          )}
        </span>
      ),
    },

    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <Space>
            {
              <>
                <ShowForRightAccessComponent>
                  <EditOutlined
                    id={`editProductEnvironment-${record.id}`}
                    title="Edit"
                    onClick={() => {
                      editProductEnvironment(record);
                    }}
                  />
                </ShowForRightAccessComponent>
              </>
            }
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <TableLayout minHeight={150}>
        <Table
          dataSource={productEnvironments?.result}
          columns={columns.filter((column) => {
            if (column.title === "Action" && !isFullAccess) {
              return false;
            }
            return true;
          })}
          rowKey={"id"}
          size="small"
        />
      </TableLayout>
    </>
  );
};

export default ListProductEnvironments;
