module.exports = {
  apiUrl: process.env.REACT_APP_API_URL,
  xamlUrl: process.env.REACT_APP_XAML_URL,
  productName: "Rollout System",
  footerText: "Copyright © Deco – All Rights Reserved ",
  inValidJson: "Invalid Template",
  emptyJson:
    "The feature form JSON must not be empty and should follow the correct format. Please fix the errors.",
};

