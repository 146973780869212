import { Divider, Input, Popconfirm, Select, Space, Table } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import ShowDescriptionInListing from "../Common/showDescriptionInListing";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import { useSelector } from "react-redux";
import CommonFilterForm from "../Common/commonFilterForm";
import TableLayout from "../Layout/tableLayout";
import { useState } from "react";
import AuditModalContainer from "../../Containers/Products/AuditModalContainer";
import { AuditEntityType } from "../../Constant/Common";
import { getSpaceValidationRule } from "../../Util/commonUtility";

const ListProductFeatureRolloutComponent = (props) => {
  const [isAuditModal, setIsAuditModal] = useState({
    open: false,
    data: null,
  });
  const {
    productFeatureRollouts,
    editProductFeatureRollout,
    featureRolloutFilterSubmit,
    resetFilter,
    handlePageChange,
    featureRolloutFilterData,
    paging,
    deleteProductFeatureRollout,
    handleTableChange,
    sortField,
    sortOrder,
  } = props;

  const isFullAccess = useSelector(
    (state) => state.product.selectedEnvironment?.isFullAccess
  );

  const filterFields = [
    {
      name: "name",
      component: (
        <Input className="list-filter-common-style" placeholder="Name" />
      ),
      rules: [getSpaceValidationRule()]
    },
    {
      name: "description",
      component: (
        <Input className="list-filter-common-style" placeholder="Description" />
      ),
      rules: [getSpaceValidationRule()]
    },
    {
      name: "featureName",
      component: (
        <Input
          className="list-filter-common-style"
          placeholder="Feature Name"
        />
      ),
      rules: [getSpaceValidationRule()]
    },
    {
      name: "isEnabled",
      component: (
        <Select
          className="list-filter-common-style select-target-min-width"
          placeholder="Is Enabled?"
        >
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <ShowDescriptionInListing title={text} text={text} />,
    },
    {
      title: "Feature Name",
      dataIndex: "featureName",
      key: "featureName",
      sorter: true,
    },
    {
      title: "Enabled",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (isEnabled) => (isEnabled ? "Yes" : "No"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <Space>
            <ShowForRightAccessComponent>
              <HistoryOutlined
                id={`auditFeatureRollout-${record.id}`}
                title="Audit Logs"
                onClick={() =>
                  setIsAuditModal({
                    open: true,
                    data: {
                      entityType: AuditEntityType.FeatureRollout,
                      entityName: record.name,
                      entityId: record.id,
                    },
                  })
                }
              />
            </ShowForRightAccessComponent>
            <Divider type="vertical"></Divider>
            <ShowForRightAccessComponent>
              <EditOutlined
                title="Edit"
                onClick={() => {
                  editProductFeatureRollout(record);
                }}
              />
            </ShowForRightAccessComponent>
            <Divider type="vertical"></Divider>
            <Popconfirm
              title="Are you sure to delete this feature rollout ?"
              onConfirm={() => {
                deleteProductFeatureRollout(record);
              }}
            >
              <DeleteOutlined title="Delete" />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  columns.forEach((column) => {
    if (column.key !== "action") {
      column.sortOrder = sortField === column.dataIndex && sortOrder;
    }
  });

  return (
    <>
      {isAuditModal.open &&
        isAuditModal.data?.entityType &&
        isAuditModal.data?.entityId && (
          <AuditModalContainer
            entityType={isAuditModal.data.entityType}
            entityName={isAuditModal.data.entityName}
            entityId={isAuditModal.data.entityId}
            onClose={() =>
              setIsAuditModal({
                open: false,
                data: null,
              })
            }
          />
        )}
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={featureRolloutFilterSubmit}
        onReset={resetFilter}
        initialValues={featureRolloutFilterData}
      />
      <TableLayout>
        <Table
          className="featureRollout-listing"
          dataSource={productFeatureRollouts?.result}
          pagination={{
            showTotal: (total, range) => (
              <TotalReocordsInListingComponent
                totalRecords={paging.totalRecords}
              />
            ),
            pageSize: paging.pageSize,
            total: paging.totalPages * paging.pageSize,
            current: paging.pageIndex,
            totalRecords: paging.totalRecords,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
          }}
          columns={columns.filter((column) => {
            if (column.title === "Action" && !isFullAccess) {
              return false;
            }
            return true;
          })}
          rowKey={"id"}
          onChange={handleTableChange}
          size="small"
        />
      </TableLayout>
    </>
  );
};
export default ListProductFeatureRolloutComponent;
