import { Input } from "antd";
import React, { useState } from "react";
import DiffViewer from "../../Common/JsonDiff";

const Diff = ({ data }) => {
  const [splitView, setSplitView] = useState(true);

  const toggleSplitView = () => {
    setSplitView((prevSplitView) => !prevSplitView);
  };

  return (
    <div className="diff-container h-full">
      <div className="diff-controls flex justify-end cursor-pointer">
        <Input
          type="checkbox"
          className="w-auto mr-2 flex items-center cursor-pointer"
          prefixCls="checkbox-split-input"
          id="split-checkbox"
          checked={splitView}
          onChange={toggleSplitView}
        />
        <span className="split-view-toggle" onClick={toggleSplitView}>
          Split View
        </span>
      </div>
      <div className={"overflow-y-scroll h-full w-full overflow-x-scroll"}>
        <DiffViewer
          oldValue={JSON.stringify(data[0], null, 2)}
          newValue={JSON.stringify(data[1], null, 2)}
          splitView={splitView}
          disableWordDiff
          showDiffOnly
          hideLineNumbers
        />
      </div>
    </div>
  );
};

export default Diff;

