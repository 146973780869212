import React from "react";
import { Popover, Tag, Space, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const InfoDetails = ({ infoData }) => {
  const isAddData = window.location.pathname.includes('add')

  const content = (
    <Space direction="vertical">

      {infoData?.createdBy && (
        <div>
          <strong>Created By:</strong> <Tag>{infoData.createdBy}</Tag>
        </div>
      )}
      {infoData?.createdAt && (
        <div>
          <strong>Created Date:</strong> <Tag>{new Date(infoData.createdAt).toDateString()}</Tag>
        </div>
      )}

      {infoData?.updatedBy && (
        <div>
          <strong>Updated By:</strong> <Tag>{infoData.updatedBy}</Tag>
        </div>
      )}
      {infoData?.updatedAt && (
        <div>
          <strong>Updated Date:</strong> <Tag>{new Date(infoData.updatedAt).toDateString()}</Tag>
        </div>
      )}
    </Space>
  );

  return (
    infoData && !isAddData ? (
      <Popover content={content} trigger="click">
        <Button title="Edit history information">
          <InfoCircleOutlined className="text-[18px] cursor-pointer" />
        </Button>
      </Popover>
    ) : null
  )
};

export default InfoDetails;
