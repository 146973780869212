import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import TargetCriteria from "../../Containers/TargetCriteria/TargetCriteria";
import {
  fromObjectToKeyValueArray,
  getSpaceValidationRule,
  hasInvalidNested,
  isNullOrEmpty,
  isValidVersion,
} from "../../Util/commonUtility";
import { maxNameCharacterLength } from "../../Constant/Common";
import NumericInput from "../Common/numericInput";
import dayjs from "dayjs";
import { useLocation, useParams } from "react-router-dom";
import FormLayout from "../Layout/formLayout";
import InfoDetails from "../Common/editHistoryInfo";
import ErrorMessages from "../../Constant/strings/errors";
import { JsonEditor } from "json-edit-react";
const AddProductBuildRollOutComponent = (props) => {
  const {
    backToListPage,
    editBuildRolloutData,
    onFinish,
    productBuildArray,
    productTargets,
    targetOperators,
    targetAttributeDropdownData,
    showDuplicationPopUp,
    setShowDuplicationPopUp,
    duplicateErrorMessage,
  } = props;
  const [form] = Form.useForm();
  const { id } = useParams();
  const location = useLocation();
  const [selectedBuild, setSelectedBuild] = useState(undefined);
  const [selectedTargetIdsArray, setSelectedTargetIdsArray] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [metaDataArray, setMetaDataArray] = useState([]);

  const buildRolloutHeading = location.pathname.includes("add")
    ? "Add"
    : "Edit";

  useEffect(() => {
    if (editBuildRolloutData) {
      const modifiedBuildMeta =
        fromObjectToKeyValueArray(
          editBuildRolloutData?.noUpdateableBuildMeta
        )?.map((a) => ({ ...a, editable: false })) || [];

      const targetIds = editBuildRolloutData.targets?.map((target) => {
        return { value: target.targetId };
      });
      const metaDataArray = editBuildRolloutData?.updateBuildMeta?.map(
        (meta) => {
          const foundAttribute = targetAttributeDropdownData?.find(
            (a) => a.value === meta.key
          );
          if (!foundAttribute) {
            throw new Error(`Attribute with key "${meta.key}" not found`);
          }
          return foundAttribute;
        }
      );
      setSelectedBuild({ ...selectedBuild, modifiedBuildMeta });
      setSelectedTargetIdsArray(targetIds);
      setMetaDataArray(metaDataArray);

      form.setFieldsValue(editBuildRolloutData);
    }
  }, [editBuildRolloutData]);

  const TargetCriteriaFinalValue = (data, json) => {
    if (!data?.equationGroup) {
      setCustomErrorMessage("Please add at least one target criteria!");
    } else {
      setCustomErrorMessage("");
    }
    form.setFieldValue("targetCriteria", data);
    form.setFieldValue("targetCriteriaJson", JSON.stringify(json));
    const hasInvalidDeepNested = hasInvalidNested(data);
    setDisableSubmitButton(hasInvalidDeepNested);
  };

  const onSelectTarget = (data, name) => {
    setSelectedTargetIdsArray((prevSelectedTargets) => {
      const newArray = [...prevSelectedTargets];
      const existingIndex = newArray.findIndex((item) => item.id === name);
      if (existingIndex !== -1) {
        newArray[existingIndex] = { ...newArray[existingIndex], value: data };
      } else {
        newArray.push({ id: name, value: data });
      }

      return newArray;
    });
  };
  const onRemoveTarget = (targetId) => {
    setSelectedTargetIdsArray((prevSelectedTargets) =>
      prevSelectedTargets.filter((a) => a.value !== targetId)
    );
  };

  const onSelectMetaData = (selected, index) => {
    const selectedAttribute = targetAttributeDropdownData.find(
      (a) => a.value === selected
    );
    setMetaDataArray((prev) => {
      const newMetaDataArray = [...prev];
      newMetaDataArray[index] = {
        ...newMetaDataArray[index],
        ...selectedAttribute,
      };
      return newMetaDataArray;
    });
  };

  const onRemoveMetaData = (index) => {
    setMetaDataArray((prev) => prev.filter((_, i) => i !== index));
  };
  const onSelectProductBuild = (id) => {
    const buildData = productBuildArray.find((obj) => obj.id === id);

    buildData.modifiedBuildMeta = fromObjectToKeyValueArray(
      buildData.buildMeta
    )?.map((a) => ({ ...a, editable: false }));

    setSelectedBuild(buildData);
  };

  const onFinishFormError = async () => {
    try {
      await form.validateFields(["targets"]);
      setCustomErrorMessage({});
    } catch {
      setCustomErrorMessage({
        targets: "Please add at least one target criteria",
      });
    }
  };

  const fieldGenerator = (opt) => {
    switch (opt) {
      case "String":
        return <Input placeholder="Please enter string value" />;
      case "Boolean":
        return (
          <Radio.Group>
            <Radio value={true}>True</Radio>
            <Radio value={false}>False</Radio>
          </Radio.Group>
        );
      case "Date":
        return <DatePicker className="react-query-builder-input-string" />;
      case "Number":
        return <NumericInput className="react-query-builder-input-string" />;
      case "Version":
        return <Input className="react-query-builder-input-string" />;
      default:
        return null;
    }
  };

  const handleOk = () => {
    const formattedData = {
      ...editBuildRolloutData,
      AllowForceInsert: true,
    };
    onFinish(formattedData);
    setShowDuplicationPopUp(false);
  };
  const handleCancel = () => {
    setShowDuplicationPopUp(false);
  };

  const handleFinish = (values) => {
    onFinish(values);
  };
  return (
    <FormLayout formTitle={`${buildRolloutHeading}  Release`}>
      <>
        <Form
          initialValues={editBuildRolloutData}
          form={form}
          className="common-form flex flex-row items-start w-full gap-4 h-full"
          name="add-product"
          onFinish={handleFinish}
          onFinishFailed={onFinishFormError}
          autoComplete="off"
          layout="vertical"
        >
          <Modal
            open={showDuplicationPopUp}
            title="Warning: Duplicate Target Detected"
            okText="Proceed and Save"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={(_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            )}
          >
            <p className="text-gray-700 mb-4">
              A target with the same identifier already exists in the release.
              Do you want to proceed with forcefully inserting this release?
              Please note that this action may overwrite the existing target.
            </p>
            <span className="text-sm font-bold mb-2">
              {duplicateErrorMessage}
            </span>
          </Modal>
          <Col className="flex flex-col space-y-4 w-1/3 h-full ">
            <div className="border p-3 rounded-lg !border-[#eeeff2] min-h-1/3">
              <Form.Item hidden={true} name="id">
                <Input />
              </Form.Item>
              <Form.Item
                label="Name"
                name="name"
                className="w-full mb-2"
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                  {
                    max: maxNameCharacterLength,
                    message: "Input cannot exceed 50 characters!",
                  },
                  getSpaceValidationRule()
                ]}
              >
                <Input id="releaseName" className="!border-[#eeeff2]" />
              </Form.Item>
              <Form.Item
                label="Build"
                className="w-full mb-2"
                name="buildId"
                rules={[
                  {
                    required: true,
                    message: "Please select build!",
                  },
                ]}
              >
                <Select
                  id="selectBuildVersion"
                  onChange={(data) => onSelectProductBuild(data)}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    const searchTerm = input.toLowerCase();
                    const label = option?.label?.toLowerCase() || "";
                    return label.indexOf(searchTerm) >= 0;
                  }}
                >
                  {productBuildArray?.map((build) => (
                    <Select.Option
                      key={build.id}
                      value={build.id}
                      label={`${build.name} ( ${build.version} )`}
                    >
                      <Tooltip
                        title={
                          productBuildArray
                            ? `Build Name:  ${build.name} ( ${build.version} )`
                            : ""
                        }
                      >
                        {build.name} ( {build.version} )
                      </Tooltip>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                className="w-full mb-2"
                rules={[
                  {
                    required: true,
                    message: "Please input your description!",
                  },
                  getSpaceValidationRule()
                ]}
              >
                <TextArea
                  id="releaseDescription"
                  className="!border-[#eeeff2] max-h-[100px]"
                />
              </Form.Item>
              {editBuildRolloutData?.id && (
                <Form.Item
                  label={"Enabled ?"}
                  name={"isEnabled"}
                  valuePropName="checked"
                  className="flex !mb-0"
                  prefixCls="enabled-form"
                >
                  <Switch id="releaseSwitch" className="flex" />
                </Form.Item>
              )}
            </div>
            <div className="border px-3 py-2 rounded-lg !border-[#eeeff2] flex-grow h-1/3 overflow-y-scroll">
              <Form.Item className="!mb-2 mt-1">
                {targetAttributeDropdownData && (
                  <Form.List
                    className="form-list !mb-0 "
                    label="Targets"
                    name={"updateBuildMeta"}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        <div className="flex justify-between">
                          <p className="text-sm font-semibold">Meta Data</p>
                          <Form.Item className="flex justify-between !mb-2">
                            <Button
                              type="primary"
                              id="addMetaDataInRelease"
                              onClick={() => add()}
                              block
                              className="!border-[#eeeff2] !w-auto !p-2"
                              icon={<PlusOutlined />}
                            >
                              Add Metadata
                            </Button>
                          </Form.Item>
                        </div>
                        <Space className="meta-data-string" size={[1, 1]} wrap>
                          {selectedBuild?.modifiedBuildMeta?.map((a, index) => {
                            return (
                              <Tag key={index}>
                                <Space>
                                  {a.key} : {a.value}
                                </Space>
                              </Tag>
                            );
                          })}
                        </Space>
                        {fields.length > 0 && (
                          <div
                            className={`flex-col flex meta-data-container p-2 m-0`}
                          >
                            {fields.map(
                              ({ key, name, ...restField }, index) => (
                                <div
                                  key={key}
                                  className="flex flex-row space-x-2 space-y-2 align-items-center w-full justify-content-between"
                                >
                                  <div className="flex flex-row gap-2 align-items-inline">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "key"]}
                                      style={{ marginBottom: "0px" }}
                                      className="!flex w-full"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please select target attribute",
                                        },
                                      ]}
                                    >
                                      <Select
                                        wrap
                                        className="react-query-builder-input-string"
                                        onChange={(data) => {
                                          form.setFieldsValue({
                                            updateBuildMeta: {
                                              [name]: { value: undefined },
                                            },
                                          });
                                          onSelectMetaData(data, name);
                                        }}
                                      >
                                        {targetAttributeDropdownData?.map(
                                          (attribute) => {
                                            return (
                                              <Select.Option
                                                key={attribute.value}
                                                value={attribute.value}
                                                disabled={metaDataArray?.some(
                                                  (a) =>
                                                    a.value === attribute.value
                                                )}
                                              >
                                                {attribute.label}
                                              </Select.Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "value"]}
                                      style={{
                                        marginBottom: "0px",
                                        width: "100%",
                                      }}
                                      initialValue={""}
                                      validateTrigger="onChange"
                                      dependencies={[
                                        ["updateBuildMeta", name, "key"],
                                      ]}
                                      getValueFromEvent={
                                        metaDataArray[index]?.type === "Date"
                                          ? (e) => e?.format("YYYY-MM-DD")
                                          : undefined
                                      }
                                      getValueProps={
                                        metaDataArray[index]?.type === "Date"
                                          ? (e) => ({ value: e && dayjs(e) })
                                          : undefined
                                      }
                                      rules={[
                                        ({ getFieldValue }) => ({
                                          validator(_, value) {
                                            const fieldValue = getFieldValue(["updateBuildMeta", name, "key"]);
                                            const inputType = metaDataArray[index]?.type; // Get the input type
                                            const { message, pattern } = getSpaceValidationRule()
                                            if (inputType === 'Version') {
                                              return Promise.resolve();
                                            } else {
                                              if (fieldValue) {
                                                if (isNullOrEmpty(value)) {
                                                  return Promise.reject(`Please insert value for "${fieldValue}"`);
                                                }
                                                if (inputType === "String" && !pattern.test(value)) {
                                                  return Promise.reject(message);
                                                }
                                              }
                                            }
                                            return Promise.resolve();
                                          },
                                        }),
                                        ...(metaDataArray[index]?.type ===
                                          "Version"
                                          ? [
                                            ({ getFieldValue }) => ({
                                              validator(_, value) {
                                                if (
                                                  value &&
                                                  !isValidVersion(value)
                                                ) {
                                                  return Promise.reject(
                                                    `${ErrorMessages.QUERYBUILDER.TAGSELECTOR.VERSION.ERROR}`
                                                  );
                                                }
                                                return Promise.resolve();
                                              },
                                            }),
                                          ]
                                          : []),
                                      ]}
                                    >
                                      {fieldGenerator(
                                        metaDataArray[index]?.type,
                                        index
                                      )}
                                    </Form.Item>
                                  </div>

                                  <Form.Item>
                                    <MinusCircleOutlined
                                      onClick={() => {
                                        onRemoveMetaData(name);
                                        remove(name);
                                      }}
                                    />
                                  </Form.Item>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </Form.List>
                )}
              </Form.Item>
            </div>
          </Col>
          <Col className="w-2/3 p-3 border rounded-lg !border-[#eeeff2] h-full overflow-y-scroll">
            <Form.Item>
              <Form.List
                name={"targets"}
                rules={[
                  {
                    required: true,
                    validator: async (_, datas) => {
                      let equationGroup =
                        form.getFieldValue("targetCriteria")?.equationGroup ||
                        [];
                      let condition =
                        equationGroup.length > 0 || (datas && datas.length > 0);

                      if (!condition) {
                        return Promise.reject(new Error(""));
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <div className="flex custom-form-label align-items-end mb-2 justify-between">
                      <label className="flex required !mb-0 text-sm font-semibold">
                        Define Targeting Rules
                      </label>
                      <Form.Item className="!mb-0">
                        <Button
                          id="addTargetInRelease"
                          onClick={() => add()}
                          block
                          type="primary"
                          className="!border-[#eeeff2] !w-auto !p-2"
                          icon={<PlusOutlined />}
                        >
                          Add Existing Targets
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </div>

                    {fields.map(({ key, name, ...restField }) => {
                      return (
                        <div key={key}>
                          <Row>
                            <Col>
                              <Space align="baseline">
                                <Form.Item
                                  {...restField}
                                  name={[name, "targetId"]}
                                  className="build-target-max-width deco-ant-form-item-no-bottom-margin"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select target",
                                    },
                                  ]}
                                >
                                  <Select
                                    className="build-target-max-width deco-ant-form-item-no-bottom-margin"
                                    onChange={(data) => {
                                      onSelectTarget(data, name);
                                    }}
                                    showSearch
                                    filterOption={(input, option) => {
                                      const searchTerm = input.toLowerCase();
                                      const label =
                                        option?.children?.toLowerCase() || "";

                                      return label.indexOf(searchTerm) >= 0;
                                    }}
                                  >
                                    {productTargets?.map((build) => {
                                      return (
                                        <Select.Option
                                          key={build.id}
                                          value={build.id}
                                          disabled={selectedTargetIdsArray?.some(
                                            (a) => a.value === build.id
                                          )}
                                        >
                                          {build.name}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  hidden={true}
                                  {...restField}
                                  name={[name, "targetOperator"]}
                                  className="build-target-max-width"
                                  initialValue={
                                    targetOperators &&
                                      targetOperators?.length > 0
                                      ? targetOperators[0].name
                                      : ""
                                  }
                                >
                                  <Input defaultValue={"AND"} value={"AND"} />
                                  {/* <Select
                                options={targetOperators?.map(
                                  (build, index) => ({
                                    label: build.label,
                                    value: build.name,
                                  })
                                )}
                                className="build-target-max-width"
                              ></Select> */}
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={async () => {
                                    await onRemoveTarget(
                                      form.getFieldValue([
                                        "targets",
                                        name,
                                        "targetId",
                                      ])
                                    );

                                    remove(name);
                                  }}
                                />
                              </Space>
                            </Col>
                          </Row>
                          <div
                            style={{ textAlign: "center", maxWidth: "300px" }}
                          >
                            And
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </Form.List>
              <Form.Item
                name="targetCriteria"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <TargetCriteria
                  initialQuery={editBuildRolloutData?.queryJson}
                  TargetCriteriaFinalValue={(data, json) => {
                    TargetCriteriaFinalValue(data, json);
                  }}
                />
              </Form.Item>

              <div className="common-error">{customErrorMessage?.targets}</div>
            </Form.Item>
          </Col>

          <Form.Item name={"targetCriteriaJson"} hidden></Form.Item>
          <Form.Item className="!mb-0 mt-2 top-0 right-4 position-absolute">
            <Space>
              <Button
                id="backToReleaseListingPage"
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  backToListPage();
                }}
              >
                Back
              </Button>
              <Button
                className="submit-btn"
                id="submitRelease"
                type="primary"
                htmlType="submit"
                disabled={disableSubmitButton}
              >
                Submit
              </Button>
              {id && <InfoDetails infoData={editBuildRolloutData} />}
            </Space>
          </Form.Item>
        </Form>
      </>
    </FormLayout>
  );
};

export default AddProductBuildRollOutComponent;

