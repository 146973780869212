import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { Divider, Input, Popconfirm, Select, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import ShowDescriptionInListing from "../Common/showDescriptionInListing";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import CommonFilterForm from "../Common/commonFilterForm";
import { TARGET_ATTRIBUTE_TYPE } from "../../Constant/DropDown";
import TableLayout from "../Layout/tableLayout";
import AuditModalContainer from "../../Containers/Products/AuditModalContainer";
import { AuditEntityType, Role } from "../../Constant/Common";
import { getLocalStorageAccountInfo, getSpaceValidationRule } from "../../Util/commonUtility";

const ListTargetAttributes = (props) => {
  const [isAuditModal, setIsAuditModal] = useState({
    open: false,
    data: null,
  });
  const {
    dataSource,
    handlePageChange,
    paging,
    editTargetAttribute,
    deleteTargetAttributes,
    listFilterSubmit,
    resetFilter,
    listFilterData,
    handleTableChange,
    sortField,
    sortOrder,
  } = props;
  const [loggedInUserDetails, setLoggedInUserDetails] = useState(null);

  useEffect(() => {
    const userData = getLocalStorageAccountInfo();
    setLoggedInUserDetails(userData);
  }, []);
  const filterFields = [
    {
      name: "name",
      component: (
        <Input className="list-filter-common-style" placeholder="Name" />
      ),
      rules: [getSpaceValidationRule()], 
    },
    {
      name: "description",
      component: (
        <Input className="list-filter-common-style" placeholder="Description" />
      ),
      rules: [getSpaceValidationRule()], 
    },
    {
      name: "type",
      component: (
        <Select
          id="selectTargetAttributeType"
          className="list-filter-common-style"
          options={TARGET_ATTRIBUTE_TYPE}
          placeholder="Type"
        />
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <ShowDescriptionInListing title={text} text={text} />,
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: true,
      key: "type",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <Space>
            <HistoryOutlined
              id={`auditTargetAttribute-${record.id}`}
              title="Audit Logs"
              onClick={() =>
                setIsAuditModal({
                  open: true,
                  data: {
                    entityType: AuditEntityType.TargetAttribute,
                    entityName: record.name,
                    entityId: record.id,
                  },
                })
              }
            />
            {loggedInUserDetails?.roles[0] === Role.ADMIN && (
              <>
                <Divider type="vertical"></Divider>
                <EditOutlined
                  id={`editTargetAttribute-${record.id}`}
                  title="Edit"
                  onClick={() => {
                    editTargetAttribute(record);
                  }}
                />
                <Divider type="vertical"></Divider>
                <Popconfirm
                  title="Are you sure to delete this target attribute ?"
                  onConfirm={() => {
                    deleteTargetAttributes(record);
                  }}
                >
                  <DeleteOutlined
                    id={`deleteTargetAttribute-${record.id}`}
                    title="Delete"
                  />
                </Popconfirm>
              </>
            )}
          </Space>
        );
      },
    },
  ];
  columns.forEach((column) => {
    if (column.key !== "action") {
      column.sortOrder = sortField === column.dataIndex && sortOrder;
    }
  });

  return (
    <>
      {isAuditModal.open &&
        isAuditModal.data?.entityType &&
        isAuditModal.data?.entityId && (
          <AuditModalContainer
            entityType={isAuditModal.data.entityType}
            entityName={isAuditModal.data.entityName}
            entityId={isAuditModal.data.entityId}
            onClose={() =>
              setIsAuditModal({
                open: false,
                data: null,
              })
            }
          />
        )}
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={listFilterSubmit}
        onReset={resetFilter}
        initialValues={listFilterData}
      />
      <TableLayout>
        <Table
          dataSource={dataSource?.result}
          pagination={{
            showTotal: (total, range) => (
              <TotalReocordsInListingComponent
                totalRecords={paging.totalRecords}
              />
            ),
            pageSize: paging.pageSize,
            total: paging.totalPages * paging.pageSize,
            current: paging.pageIndex,
            totalRecords: paging.totalRecords,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
          }}
          columns={columns}
          rowKey={"id"}
          onChange={handleTableChange}
          size="small"
        />
      </TableLayout>
    </>
  );
};

export default ListTargetAttributes;

