import { useState, useCallback } from "react";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { internalRoutes } from "../Constant/internalRoutes";
import {
  fetchAllItems,
  findEnvironmentName,
  getFormFieldValue,
} from "../Util/copyModalUtil";
import { copyButtons } from "../Util/copyButtonUtils";
import {
  ContentRolloutForm,
  ContentTemplateForm,
  FeatureFlagForm,
  FeatureRolloutForm,
  ReleaseForm,
  TargetForm,
} from "../Util/copyFormUtils";

export const useCopyModal = (prepareCopyExtraButton) => {
  const navigate = useNavigate();
  const [featureCopyForm] = Form.useForm();
  const [targetCopyForm] = Form.useForm();
  const [releaseCopyForm] = Form.useForm();
  const [contentCopyForm] = Form.useForm();
  const [featureRolloutCopyForm] = Form.useForm();
  const [contentRolloutCopyForm] = Form.useForm();
  const [copyButton, setCopyButton] = useState(null);
  const [showCopyPopUp, setShowCopyPopUp] = useState(false);
  const [showFeatureRolloutConfirm, setShowFeatureRolloutConfirm] =
    useState(false);
  const [showContentRolloutConfirm, setShowContentRolloutConfirm] =
    useState(false);
  const [showTargetConfirm, setShowTargetConfirm] = useState(false);
  const [showReleaseConfirm, setShowReleaseConfirm] = useState(false);
  const [allFeatures, setAllFeatures] = useState([]);
  const [allContents, setAllContents] = useState([]);
  const [allTargets, setAllTargets] = useState([]);
  const [allRelease, setAllRelease] = useState([]);
  const [allFeatureRollouts, setAllFeatureRollouts] = useState([]);
  const [allContentRollouts, setAllContentRollouts] = useState([]);

  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const allEnvironments = productState?.selectedProduct?.environments;
  const currentSelectedEnvironment = productState?.selectedEnvironment;

  const selectedFeatureRolloutName = getFormFieldValue(
    featureRolloutCopyForm,
    "name"
  );
  const selectedContentRolloutName = getFormFieldValue(
    contentRolloutCopyForm,
    "name"
  );
  const selectedTargetName = getFormFieldValue(targetCopyForm, "name");
  const selectedReleaseName = getFormFieldValue(releaseCopyForm, "name");

  const featureEnvironmentId = getFormFieldValue(
    featureRolloutCopyForm,
    "environmentId"
  );

  const contentEnvironmentId = getFormFieldValue(
    contentRolloutCopyForm,
    "environmentId"
  );

  const targetEnvironmentId = getFormFieldValue(
    targetCopyForm,
    "environmentId"
  );
  const releaseEnvironmentId = getFormFieldValue(
    releaseCopyForm,
    "environmentId"
  );
  const copyFromEnvironmentFeatureRollout = findEnvironmentName(
    allEnvironments,
    featureEnvironmentId
  );

  const copyFromEnvironmentContentRollout = findEnvironmentName(
    allEnvironments,
    contentEnvironmentId
  );
  const copyFromEnvironmentTarget = findEnvironmentName(
    allEnvironments,
    targetEnvironmentId
  );
  const copyFromEnvironmentRelease = findEnvironmentName(
    allEnvironments,
    releaseEnvironmentId
  );
  const selectedCopyTargetId = getFormFieldValue(targetCopyForm, "id");
  const selectedCopyReleaseId = getFormFieldValue(releaseCopyForm, "id");
  const selectedCopyFeatureId = getFormFieldValue(featureCopyForm, "id");
  const selectedCopyFeatureRolloutId = getFormFieldValue(
    featureRolloutCopyForm,
    "id"
  );
  const selectedCopyContentRolloutId = getFormFieldValue(
    contentRolloutCopyForm,
    "id"
  );
  const selectedCopyContentId = getFormFieldValue(contentCopyForm, "id");

  const resetForm = useCallback(
    (key) => {
      const form = {
        FeatureFlag: featureCopyForm,
        FeatureRollout: featureRolloutCopyForm,
        ContentTemplate: contentCopyForm,
        ContentRollout: contentRolloutCopyForm,
        Targets: targetCopyForm,
        Release: releaseCopyForm,
      }[key];
      form.resetFields();
      setShowCopyPopUp(false);
    },
    [
      contentCopyForm,
      contentRolloutCopyForm,
      featureCopyForm,
      featureRolloutCopyForm,
      releaseCopyForm,
      targetCopyForm,
    ]
  );

  const handleOk = useCallback(
    (key, value) => {
      const routes = {
        FeatureFlag: internalRoutes.featureFlagAdd,
        FeatureRollout: internalRoutes.featureRolloutAdd,
        ContentTemplate: internalRoutes.contentTemplatesAdd,
        ContentRollout: internalRoutes.contentRolloutAdd,
        Targets: internalRoutes.targetAdd,
        Release: internalRoutes.releaseAdd,
      };

      if (routes[key]) {
        navigate(routes[key], { state: value });
        setShowCopyPopUp(false);
        resetForm(key);
      }
    },
    [navigate, resetForm]
  );

  const handleCancelPopConfirm = useCallback(() => {
    setShowFeatureRolloutConfirm(false);
    setShowContentRolloutConfirm(false);
    setShowTargetConfirm(false);
    setShowReleaseConfirm(false);
  }, []);

  const handleCancel = useCallback(async () => {
    // Ensure the popconfirm is closed before resetting the form.
    await handleCancelPopConfirm(); // Wait for the popconfirm to close.
    resetForm(showCopyPopUp); // Reset the form afterwards.
  }, [handleCancelPopConfirm, resetForm, showCopyPopUp]);

  const openFeatureRolloutPopConfirm = useCallback(() => {
    setShowFeatureRolloutConfirm(true);
  }, []);
  const openContentRolloutPopConfirm = useCallback(() => {
    setShowContentRolloutConfirm(true);
  }, []);
  const openTargetPopConfirm = useCallback(() => {
    setShowTargetConfirm(true);
  }, []);
  const openReleasePopConfirm = useCallback(() => {
    setShowReleaseConfirm(true);
  }, []);

  const handleFeatureChange = useCallback(
    (value) => {
      const featureName = allFeatures?.find((obj) => obj.id === value)?.name;
      const appendedName = `${featureName}_Copy`;
      featureCopyForm.setFieldValue("name", appendedName);
    },
    [allFeatures, featureCopyForm]
  );

  const handleTargetChange = useCallback(
    (value) => {
      const targetName = allTargets?.find((obj) => obj.id === value)?.name;
      const appendedName = `${targetName}_Copy`;
      targetCopyForm.setFieldValue("name", appendedName);
    },
    [allTargets, targetCopyForm]
  );
  const handleReleaseChange = useCallback(
    (value) => {
      const releaseName = allRelease?.find((obj) => obj.id === value)?.name;
      const appendedName = `${releaseName}_Copy`;
      releaseCopyForm.setFieldValue("name", appendedName);
    },
    [allRelease, releaseCopyForm]
  );
  const handleContentChange = useCallback(
    (value) => {
      const contentName = allContents?.find((obj) => obj.id === value)?.name;
      const appendedName = `${contentName}_Copy`;
      contentCopyForm.setFieldValue("name", appendedName);
    },
    [allContents, contentCopyForm]
  );
  const handleFeatureRolloutChange = useCallback(
    (value) => {
      const featureRolloutName = allFeatureRollouts?.find(
        (obj) => obj.id === value
      )?.name;
      const appendedName = `${featureRolloutName}_Copy`;
      featureRolloutCopyForm.setFieldValue("name", appendedName);
    },
    [allFeatureRollouts, featureRolloutCopyForm]
  );
  const handleContentRolloutChange = useCallback(
    (value) => {
      const contentRolloutName = allContentRollouts?.find(
        (obj) => obj.id === value
      )?.name;
      const appendedName = `${contentRolloutName}_Copy`;
      contentRolloutCopyForm.setFieldValue("name", appendedName);
    },
    [allContentRollouts, contentRolloutCopyForm]
  );

  const featureEnvironmentChange = useCallback(
    (value) => {
      const environmentId = value;
      fetchAllItems(
        "featureRollouts",
        setAllFeatureRollouts,
        authState,
        productState,
        environmentId
      );
    },
    [authState, productState]
  );
  const contentEnvironmentChange = useCallback(
    (value) => {
      const environmentId = value;
      fetchAllItems(
        "contentRollouts",
        setAllContentRollouts,
        authState,
        productState,
        environmentId
      );
    },
    [authState, productState]
  );
  const targetEnvironmentChange = useCallback(
    (value) => {
      const environmentId = value;
      fetchAllItems(
        "targets",
        setAllTargets,
        authState,
        productState,
        environmentId
      );
    },
    [authState, productState]
  );
  const releaseEnvironmentChange = useCallback(
    (value) => {
      const environmentId = value;
      fetchAllItems(
        "release",
        setAllRelease,
        authState,
        productState,
        environmentId
      );
    },
    [authState, productState]
  );

  const handleFeatureFinish = useCallback(
    (values) => {
      handleOk("FeatureFlag", values);
    },
    [handleOk]
  );
  const handleTargetFinish = useCallback(
    (values) => {
      handleOk("Targets", values);
    },
    [handleOk]
  );
  const handleReleaseFinish = useCallback(
    (values) => {
      handleOk("Release", values);
    },
    [handleOk]
  );
  const handleContentFinish = useCallback(
    (values) => {
      handleOk("ContentTemplate", values);
    },
    [handleOk]
  );
  const handleFeatureRolloutFinish = useCallback(
    (values) => {
      handleOk("FeatureRollout", values);
    },
    [handleOk]
  );
  const handleContentRolloutFinish = useCallback(
    (values) => {
      handleOk("ContentRollout", values);
    },
    [handleOk]
  );

  const getCopyContent = useCallback(() => {
    const formProps = {
      onCancel: handleCancel,
      allEnvironments,
      currentEnvironment: currentSelectedEnvironment,
    };

    switch (showCopyPopUp) {
      case "FeatureFlag":
        return (
          <FeatureFlagForm
            form={featureCopyForm}
            onFinish={handleFeatureFinish}
            onChange={handleFeatureChange}
            selectedId={selectedCopyFeatureId}
            items={allFeatures}
            {...formProps}
          />
        );
      case "FeatureRollout":
        return (
          <FeatureRolloutForm
            form={featureRolloutCopyForm}
            onFinish={handleFeatureRolloutFinish}
            onChange={handleFeatureRolloutChange}
            environmentChange={featureEnvironmentChange}
            items={allFeatureRollouts}
            showConfirm={showFeatureRolloutConfirm}
            setShowConfirm={setShowFeatureRolloutConfirm}
            selectedName={selectedFeatureRolloutName}
            copyFromEnvironment={copyFromEnvironmentFeatureRollout}
            selectedId={selectedCopyFeatureRolloutId}
            openPopConfirm={openFeatureRolloutPopConfirm}
            {...formProps}
          />
        );
      case "ContentTemplate":
        return (
          <ContentTemplateForm
            form={contentCopyForm}
            onFinish={handleContentFinish}
            onChange={handleContentChange}
            items={allContents}
            selectedId={selectedCopyContentId}
            {...formProps}
          />
        );
      case "ContentRollout":
        return (
          <ContentRolloutForm
            form={contentRolloutCopyForm}
            onFinish={handleContentRolloutFinish}
            onChange={handleContentRolloutChange}
            environmentChange={contentEnvironmentChange}
            items={allContentRollouts}
            showConfirm={showContentRolloutConfirm}
            setShowConfirm={setShowContentRolloutConfirm}
            selectedName={selectedContentRolloutName}
            copyFromEnvironment={copyFromEnvironmentContentRollout}
            openPopConfirm={openContentRolloutPopConfirm}
            selectedId={selectedCopyContentRolloutId}
            {...formProps}
          />
        );
      case "Targets":
        return (
          <TargetForm
            form={targetCopyForm}
            onFinish={handleTargetFinish}
            onChange={handleTargetChange}
            environmentChange={targetEnvironmentChange}
            showConfirm={showTargetConfirm}
            setShowConfirm={setShowTargetConfirm}
            selectedName={selectedTargetName}
            copyFromEnvironment={copyFromEnvironmentTarget}
            openPopConfirm={openTargetPopConfirm}
            items={allTargets}
            selectedId={selectedCopyTargetId}
            {...formProps}
          />
        );
      case "Release":
        return (
          <ReleaseForm
            form={releaseCopyForm}
            onFinish={handleReleaseFinish}
            onChange={handleReleaseChange}
            environmentChange={releaseEnvironmentChange}
            showConfirm={showReleaseConfirm}
            setShowConfirm={setShowReleaseConfirm}
            selectedName={selectedReleaseName}
            copyFromEnvironment={copyFromEnvironmentRelease}
            openPopConfirm={openReleasePopConfirm}
            items={allRelease}
            selectedId={selectedCopyReleaseId}
            {...formProps}
          />
        );
      default:
        return null;
    }
  }, [
    allContentRollouts,
    allContents,
    allEnvironments,
    allFeatureRollouts,
    allFeatures,
    allRelease,
    allTargets,
    contentCopyForm,
    contentEnvironmentChange,
    contentRolloutCopyForm,
    copyFromEnvironmentContentRollout,
    copyFromEnvironmentFeatureRollout,
    copyFromEnvironmentRelease,
    copyFromEnvironmentTarget,
    currentSelectedEnvironment,
    featureCopyForm,
    featureEnvironmentChange,
    featureRolloutCopyForm,
    handleCancel,
    handleContentChange,
    handleContentFinish,
    handleContentRolloutChange,
    handleContentRolloutFinish,
    handleFeatureChange,
    handleFeatureFinish,
    handleFeatureRolloutChange,
    handleFeatureRolloutFinish,
    handleReleaseChange,
    handleReleaseFinish,
    handleTargetChange,
    handleTargetFinish,
    openContentRolloutPopConfirm,
    openFeatureRolloutPopConfirm,
    openReleasePopConfirm,
    openTargetPopConfirm,
    releaseCopyForm,
    releaseEnvironmentChange,
    selectedContentRolloutName,
    selectedCopyContentId,
    selectedCopyContentRolloutId,
    selectedCopyFeatureId,
    selectedCopyFeatureRolloutId,
    selectedCopyReleaseId,
    selectedCopyTargetId,
    selectedFeatureRolloutName,
    selectedReleaseName,
    selectedTargetName,
    showContentRolloutConfirm,
    showCopyPopUp,
    showFeatureRolloutConfirm,
    showReleaseConfirm,
    showTargetConfirm,
    targetCopyForm,
    targetEnvironmentChange,
  ]);

  const getCopyTitle = () => {
    const titles = {
      FeatureFlag: "Copy Feature",
      FeatureRollout: "Copy Feature Rollout",
      ContentTemplate: "Copy Content Template",
      ContentRollout: "Copy Content Rollout",
      Targets: "Copy Target",
      Release: "Copy Release",
    };
    return titles[showCopyPopUp] || "";
  };

  const updateCopyButtons = useCallback(
    (tabId) => {
      copyButtons(
        tabId,
        setCopyButton,
        prepareCopyExtraButton,
        setShowCopyPopUp,
        authState,
        productState,
        setAllFeatures,
        setAllTargets,
        setAllContents
      );
    },
    [authState, prepareCopyExtraButton, productState]
  );

  return {
    showCopyPopUp,
    handleCancel,
    getCopyTitle,
    getCopyContent,
    copyButton,
    updateCopyButtons,
  };
};

