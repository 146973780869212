import React from "react";
import { Route, Routes } from "react-router-dom";
import EnvironmentsListScreen from "./environmentsListScreen";
import EnvironmentsAddScreen from "./environmentsAddScreen";

const EnvironmentsRoute = () => {
  return (
    <>
      <React.Fragment>
        <Routes>
          <Route index path="/" element={<EnvironmentsListScreen />} />
          <Route index path="/add" element={<EnvironmentsAddScreen />} />
          <Route index path="/edit/:id" element={<EnvironmentsAddScreen />} />
          {/* <Route path="*" element={<Notfound />} /> */}
        </Routes>
      </React.Fragment>
    </>
  );
};

export default EnvironmentsRoute;
