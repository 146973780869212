import React, { useState } from "react";
import ListProductEnvironments from "../../Components/ProductEnvironments/listProductEnvironmentsComponent";
import useFetchData from "../../Hooks/useFetchData";
import Urls from "../../Constant/Urls";
import { convertDataToQueryParam } from "../../Util/commonUtility";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { DefaultSortingValue } from "../../Constant/Common";

const ProductEnvironmentsContainer = () => {
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [listFilterData, setListFilterData] = useState({});
  const navigate = useNavigate();
  const [paging, setPaging] = useState({
    pageSize: 1000,
    pageIndex: 1,
  });

  const { data: productEnvironments, loading: showLoading } = useFetchData(
    `${Urls.TENANT_GET_ALL_PRODUCT_ENVIRONMENTS.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    ).replace(
      "#{productId}",
      productState?.selectedProduct?.id
    )}?${convertDataToQueryParam(listFilterData)}&PageNumber=${
      paging.pageIndex
    }&PageSize=${paging.pageSize}`,
    [
      listFilterData,
      productState.selectedProduct?.id,
      productState.selectedEnvironment?.id,
    ]
  );

  const addEditProductEnvironmentAction = (data) => {
    navigate(`/product/environments/edit/${data.id}`);
  };

  const listFilterSubmit = (data) => {
    setListFilterData(data);
  };

  const resetFilter = () => {
    setListFilterData({});
  };
  return (
    <div>
      {showLoading ? (
        <Loader />
      ) : (
        <ListProductEnvironments
          productEnvironments={productEnvironments}
          listFilterData={listFilterData}
          editProductEnvironment={addEditProductEnvironmentAction}
          listFilterSubmit={listFilterSubmit}
          resetFilter={resetFilter}
        />
      )}
    </div>
  );
};

export default ProductEnvironmentsContainer;
