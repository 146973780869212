import { useEffect, useState } from "react";
import { headerHeight, HightMax } from "../../Constant/Common";

const FormLayout = ({ formTitle, children, className, formButton }) => {
  const [minHeight, setMinHeight] = useState(HightMax);

  useEffect(() => {
    const updateHeight = () => {
      setMinHeight(`${window.innerHeight - headerHeight}px`);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <div className={`${className} `}>
      <div className="flex justify-between">
        {formTitle && (
          <h2 className="text-[22px] font-semibold !pt-[5px] mb-3">
            {formTitle}
          </h2>
        )}
        {formButton && formButton}
      </div>
      <div
        className={`h-[${minHeight}] form-layout-container p-3 border bg-white rounded-lg !border-[#eeeff2] overflow-y-scroll`}
      >
        {children}
      </div>
    </div>
  );
};
export default FormLayout;

