/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";
import { useEffect, useRef, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import {
  getSpaceValidationRule,
  hasInvalidNested,
  isJson,
  isNullOrEmpty,
  scrollToErrorOnForm,
} from "../../Util/commonUtility";
import { SearchProductFeatures } from "../../Services/searchService";
import { useSelector } from "react-redux";
import RolloutEditor from "./rolloutEditor";
import { maxNameCharacterLength } from "../../Constant/Common";
import { useLocation } from "react-router-dom";
import FormLayout from "../Layout/formLayout";
import InfoDetails from "../Common/editHistoryInfo";
import TargetCriteria from "../../Containers/TargetCriteria/TargetCriteria";

const AddProductFeatureRolloutComponent = (props) => {
  const {
    id,
    onFinish,
    backToListPage,
    editProductFeatureRolloutData,
    targetOperators,
    productTargets,
    setCanSubmit,
    showDuplicationPopUp,
    setShowDuplicationPopUp,
    duplicateErrorMessage,
  } = props;
  const location = useLocation();
  const [addProductFeatureRolloutForm] = Form.useForm();
  const [featureArray, setFeatureArray] = useState([]);
  const [selectedTargetIdsArray, setSelectedTargetIdsArray] = useState([]);
  const [editorSchema, setEditorSchema] = useState({});
  const [formSchema, setFormSchema] = useState({});
  const [formValues, setFormValues] = useState({});
  const [selectedFeatureId, setSelectedFeatureId] = useState();
  const [targetErrorMessage, setTargetErrorMessage] = useState("");
  const formContainerRef = useRef(null);
  const formEditorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [targetCriteriaErrorMessage, setTargetCriteriaErrorMessage] =
    useState("");
  const [invalidEquationData, setInvalidEquationData] = useState();
  const featureRolloutHeading = location.pathname.includes("add")
    ? "Add"
    : "Edit";
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);

  const handleFinish = (values) => {
    if (
      !addProductFeatureRolloutForm.getFieldValue("targets") &&
      !addProductFeatureRolloutForm.getFieldValue("targetCriteria")
    ) {
      setTargetErrorMessage("Please add at least one target criteria");
    } else {
      const payload = {
        ...values,
        targets:
          values.targets ||
          addProductFeatureRolloutForm.getFieldValue("targets") ||
          [],
        targetCriteria:
          values.targetCriteria ||
          addProductFeatureRolloutForm.getFieldValue("targetCriteria") ||
          {},
        targetCriteriaJson:
          values.targetCriteriaJson ||
          addProductFeatureRolloutForm.getFieldValue("targetCriteriaJson"),
      };
      onFinish(payload);
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    if (invalidEquationData) {
      setTargetCriteriaErrorMessage("Please complete adding targeting rules.");
    } else {
      setOpen(false);
    }
  };

  const handleFinishFailed = async (errorInfo) => {
    if (
      !errorInfo?.values?.targets?.length &&
      (!Array.isArray(errorInfo?.values?.targetCriteria?.equationGroup) ||
        !errorInfo.values.targetCriteria.equationGroup.length)
    ) {
      const errMessage = "Please add at least one target criteria";
      setTargetErrorMessage(errMessage);
      setTargetCriteriaErrorMessage(errMessage);
    }
    if (
      errorInfo?.values.targets?.length > 0 &&
      selectedTargetIdsArray?.length === 0
    ) {
      setTargetErrorMessage("Please select target!");
    }

    if (validationErrors.length > 0) {
      scrollToErrorOnForm(validationErrors.slice(-1)[0].path, formEditorRef);
    }
  };

  const onSearchFeature = async (data) => {
    let queryParam = data
      ? `&SearchText=${data}&isEnabled=true`
      : "&isEnabled=true";

    const newResult = await SearchProductFeatures(
      queryParam,
      authState,
      productState
    );
    setFeatureArray(newResult);
  };

  useEffect(() => {
    if (formValues && !isNullOrEmpty(formValues)) {
      setEditorSchema(formValues);
      addProductFeatureRolloutForm.setFieldValue("config", formValues);
    }
  }, [addProductFeatureRolloutForm, formValues]);

  const onSelectFeature = (featureId) => {
    setSelectedFeatureId(featureId);
    const feature = featureArray.find((obj) => obj.id === featureId);
    addProductFeatureRolloutForm.setFieldValue("feature", feature);
    if (feature && isJson(feature.defaultConfig)) {
      try {
        let parsedJson = JSON.parse(feature.defaultConfig);
        setFormValues(parsedJson);
        setEditorSchema(parsedJson);
        let jsonSchema = JSON.parse(feature.schemaConfig);
        setFormSchema(jsonSchema);
        addProductFeatureRolloutForm.setFieldsValue({
          config: parsedJson,
        });
      } catch (error) {
        console.error("Failed to parse JSON:", error);
      }
    }
  };

  const onSelectTarget = (targetId, name) => {
    setTargetErrorMessage("");
    setTargetCriteriaErrorMessage("");
    setSelectedTargetIdsArray((prev) => {
      const updatedArray = [...prev];
      const existingIndex = updatedArray.findIndex((item) => item.id === name);
      if (existingIndex !== -1) {
        updatedArray[existingIndex].value = targetId;
      } else {
        updatedArray.push({ id: name, value: targetId });
      }
      return updatedArray;
    });
  };

  const onRemoveTarget = (targetId) => {
    setSelectedTargetIdsArray((prev) =>
      prev.filter((target) => target.value !== targetId)
    );
  };

  const TargetCriteriaFinalValue = (data, json) => {
    addProductFeatureRolloutForm.setFieldValue("targetCriteria", data);
    addProductFeatureRolloutForm.setFieldValue(
      "targetCriteriaJson",
      JSON.stringify(json)
    );
    if (data?.equationGroup) {
      setTargetErrorMessage("");
      setTargetCriteriaErrorMessage("");
    }
    const hasInvalidDeepNested = hasInvalidNested(data);
    if (hasInvalidDeepNested) {
      setInvalidEquationData(true);
    } else {
      setInvalidEquationData(false);
    }
  };

  useEffect(() => {
    if (!editProductFeatureRolloutData) return;
    const setFeatureConfig = () => {
      if (id) {
        const config = editProductFeatureRolloutData.config;
        const parsedConfig =
          typeof config === "object" ? config : JSON.parse(config);
        setFormValues(parsedConfig);
        setEditorSchema(parsedConfig);
      } else {
        let featureConfig;
        featureConfig = editProductFeatureRolloutData.config;
        setFormValues(featureConfig);
        setEditorSchema(featureConfig);
      }
    };
    const setFeatureSchemaConfig = () => {
      const feature = featureArray.find(
        (obj) => obj.id === editProductFeatureRolloutData.featureId
      );
      const parsedSchema = feature ? JSON.parse(feature.schemaConfig) : {};
      setFormSchema(parsedSchema);
    };

    setFeatureConfig();
    setFeatureSchemaConfig();
    const featureData = featureArray.find(
      (obj) => obj.id === editProductFeatureRolloutData.featureId
    );
    if (editProductFeatureRolloutData) {
      setSelectedFeatureId(editProductFeatureRolloutData?.featureId);
      addProductFeatureRolloutForm.setFieldsValue({
        targets: editProductFeatureRolloutData?.targets || [],
        targetCriteria: editProductFeatureRolloutData?.targetCriteria || {},
      });
      addProductFeatureRolloutForm.setFieldsValue(
        editProductFeatureRolloutData
      );
    }
  }, [
    id,
    editProductFeatureRolloutData,
    featureArray,
    addProductFeatureRolloutForm,
    duplicateErrorMessage,
  ]);

  useEffect(() => {
    onSearchFeature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOk = () => {
    const formattedData = {
      ...editProductFeatureRolloutData,
      AllowForceInsert: true,
    };

    onFinish(formattedData);
    setShowDuplicationPopUp(false);
  };
  const handleCancel = () => {
    setShowDuplicationPopUp(false);
  };

  return (
    <FormLayout formTitle={`${featureRolloutHeading} Feature Rollout`}>
      <>
        <Form
          form={addProductFeatureRolloutForm}
          initialValues={editProductFeatureRolloutData}
          className="common-form flex flex-row items-start w-full gap-4 h-full"
          name="add-feature-rollout"
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Modal
            open={showDuplicationPopUp}
            title="Warning: Duplicate Target Detected"
            okText="Proceed and Save"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={(_, { OkBtn, CancelBtn }) => (
              <div className="flex flex-row w-full justify-end gap-2">
                <CancelBtn />
                <div className="flex flex-col items-center min-w-0">
                  <OkBtn />
                  {targetErrorMessage && (
                    <span className="text-red-500 text-center whitespace-pre-line max-w-[150px]">
                      {targetErrorMessage}
                    </span>
                  )}
                </div>
              </div>
            )}
          >
            <p className="text-gray-700 mb-4">
              A target with the same identifier already exists in the feature
              rollout. Do you want to proceed with forcefully inserting this
              feature rollout? Please note that this action may overwrite the
              existing target.
            </p>
            <span className="text-sm font-bold mb-2">
              {duplicateErrorMessage}
            </span>
          </Modal>

          <Col className="flex flex-col space-y-4 w-1/3 h-full">
            <div className="border p-3 rounded-lg !border-[#eeeff2] min-h-1/3">
              <Form.Item hidden={true} name="id">
                <Input />
              </Form.Item>
              <Form.Item
                label="Name"
                name="name"
                className="w-full !mb-2"
                rules={[
                  {
                    required: true,
                    message: "Please enter feature rollout name!",
                  },
                  {
                    max: maxNameCharacterLength,
                    message: "Input cannot exceed 50 characters!",
                  },
                  getSpaceValidationRule()
                ]}
              >
                <Input className="!border-[#eeeff2]" id="releaseName" />
              </Form.Item>
              <Form.Item
                label="Feature"
                name="featureId"
                className="w-full !mb-2"
                rules={[
                  {
                    required: true,
                    message: "Please select feature!",
                  },
                ]}
              >
                <Select
                  id="featureId"
                  onChange={(data) => onSelectFeature(data)}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    const feature = featureArray.find(
                      (f) => f.id === option.value
                    );

                    if (!feature) return false;

                    return (
                      feature.name.toLowerCase().indexOf(input.toLowerCase()) >=
                        0 ||
                      feature.code.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    );
                  }}
                >
                  {featureArray?.map((feature, index) => (
                    <Select.Option key={feature.id} value={feature.id}>
                      <Tooltip
                        title={
                          featureArray
                            ? `Feature Name: ${
                                feature.name
                              } ${`( ${feature.code} )`}`
                            : ""
                        }
                      >
                        {feature.name} {`( ${feature.code} )`}
                      </Tooltip>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                className="w-full !mb-2"
                rules={[
                  {
                    required: true,
                    message: "Please enter description!",
                  },
                  { max: 200, message: "Code must be at least 200 characters" },
                  getSpaceValidationRule()
                ]}
              >
                <TextArea
                  className="!border-[#eeeff2] max-h-[100px]"
                  id="releaseDescription"
                />
              </Form.Item>
              <div className="flex flex-row justify-content-between align-items-center">
                <Form.Item
                  name="isEnable"
                  className="!mb-1"
                  valuePropName="checked"
                  prefixCls="enabled-form"
                  label={"Enabled?"}
                >
                  <Switch id="releaseSwitch" />
                </Form.Item>
                <Form.Item className="!mb-0 flex flex-col" id="buttons">
                  <Button
                    id="backToListingPage"
                    type="primary"
                    onClick={showDrawer}
                  >
                    Configure Targeting Rules
                  </Button>
                  {targetErrorMessage && (
                    <p className="text-red-500 whitespace-pre-line max-w-[250px]">
                      {targetErrorMessage}
                    </p>
                  )}
                </Form.Item>
              </div>
            </div>
          </Col>
          {(selectedFeatureId || id) && (
            <Col className="w-2/3 border p-3 rounded-lg !border-[#eeeff2] h-full flex flex-col">
              <Form.Item name={"config"} label="Config" hidden={true}>
                <Input></Input>
              </Form.Item>
              <RolloutEditor
                editorSchema={editorSchema}
                formSchema={formSchema}
                formValues={formValues}
                setFormValues={setFormValues}
                setCanSubmit={setCanSubmit}
                formContainerRef={formContainerRef}
                formEditorRef={formEditorRef}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                mode="view"
              />
            </Col>
          )}

          <Drawer
            width={950}
            closable={false}
            prefixCls="json-editor-drawer"
            placement="right"
            open={open}
            onClose={onClose}
            maskClosable={true} // Ensure this is true
          >
            <div className="w-full p-3 h-full">
              <Form.Item>
                <Form.List
                  name={"targets"}
                  rules={[
                    {
                      required: true,
                      validator: async (_, data) => {
                        let equationGroup =
                          addProductFeatureRolloutForm.getFieldValue(
                            "targetCriteria"
                          )?.equationGroup || [];
                        let condition =
                          equationGroup.length > 0 || (data && data.length > 0);

                        if (!condition) {
                          return Promise.reject(new Error(""));
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      <div className="flex custom-form-label align-items-end mb-2 justify-between">
                        <label className="required text-sm font-semibold">
                          Define Targeting Rules
                        </label>
                        <Form.Item className="!mb-0">
                          <Button
                            id="addTargetInRelease"
                            onClick={() => add()}
                            block
                            type="primary"
                            className="!border-[#eeeff2] !w-auto !p-2"
                            icon={<PlusOutlined />}
                          >
                            Add Existing Targets
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </div>

                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <div key={key}>
                            <Row>
                              <Col>
                                <Space align="baseline">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "targetId"]}
                                    className="build-target-max-width deco-ant-form-item-no-bottom-margin"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select target",
                                      },
                                    ]}
                                  >
                                    <Select
                                      className="build-target-max-width deco-ant-form-item-no-bottom-margin"
                                      onChange={(data) => {
                                        onSelectTarget(data, name);
                                      }}
                                      showSearch
                                      filterOption={(input, option) => {
                                        const searchTerm = input.toLowerCase();
                                        const label =
                                          option?.children?.toLowerCase() || "";

                                        return label.indexOf(searchTerm) >= 0;
                                      }}
                                    >
                                      {productTargets?.map((build) => {
                                        return (
                                          <Select.Option
                                            key={build.id}
                                            value={build.id}
                                            disabled={selectedTargetIdsArray?.some(
                                              (a) => a.value === build.id
                                            )}
                                          >
                                            {build.name}
                                          </Select.Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    hidden={true}
                                    {...restField}
                                    name={[name, "targetOperator"]}
                                    className="build-target-max-width"
                                    initialValue={
                                      targetOperators &&
                                      targetOperators?.length > 0
                                        ? targetOperators[0].name
                                        : ""
                                    }
                                  >
                                    <Input defaultValue={"AND"} value={"AND"} />
                                  </Form.Item>
                                  <MinusCircleOutlined
                                    onClick={async () => {
                                      await onRemoveTarget(
                                        addProductFeatureRolloutForm.getFieldValue(
                                          ["targets", name, "targetId"]
                                        )
                                      );

                                      remove(name);
                                    }}
                                  />
                                </Space>
                              </Col>
                            </Row>
                            <div
                              style={{ textAlign: "center", maxWidth: "300px" }}
                            >
                              And
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </Form.List>
                <Form.Item
                  name="targetCriteria"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <TargetCriteria
                    initialQuery={editProductFeatureRolloutData?.queryJson}
                    TargetCriteriaFinalValue={(data, json) => {
                      TargetCriteriaFinalValue(data, json);
                    }}
                  />
                </Form.Item>
                <Form.Item name={"targetCriteriaJson"} hidden></Form.Item>

                {targetCriteriaErrorMessage && (
                  <div className="common-error">
                    {targetCriteriaErrorMessage}
                  </div>
                )}
              </Form.Item>
            </div>
          </Drawer>

          <Form.Item className="!mb-0 mt-2 top-0 right-4 position-absolute">
            <Space>
              <Button
                id="backToListingPage"
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  backToListPage();
                }}
              >
                Back
              </Button>
              <Button
                className="submit-btn"
                id="submitRelease"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
              {id && <InfoDetails infoData={editProductFeatureRolloutData} />}
            </Space>
          </Form.Item>
        </Form>
      </>
    </FormLayout>
  );
};
export default AddProductFeatureRolloutComponent;

