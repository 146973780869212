import {
  ArrowLeftOutlined,
  DownloadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Popover,
  Row,
  Space,
  Switch,
} from "antd";
import Loader from "../Common/Loader";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import {
  fromKeyValueArrayToObject,
  fromObjectToKeyValueArray,
  getSpaceValidationRule,
  isValidVersion,
} from "../../Util/commonUtility";
import ErrorMessages from "../../Constant/strings/errors";
import { maxNameCharacterLength } from "../../Constant/Common";
import FormLayout from "../Layout/formLayout";
import { useLocation } from "react-router-dom";
import InfoDetails from "../Common/editHistoryInfo";
const AddProductBuildComponent = (props) => {
  const { id, onFinish, onFinishFailed, backToListPage, editProductBuildData } =
    props;
  const [addProductBuildForm] = Form.useForm();
  const [initialFormValues, SetInitialFormValues] = useState();
  const location = useLocation();
  const buildHeading = location.pathname.includes("add") ? "Add" : "Edit";

  useEffect(() => {
    if (id) {
      SetInitialFormValues(editProductBuildData);
    } else {
      SetInitialFormValues({ description: null });
    }
  }, [editProductBuildData, id]);

  return (
    <FormLayout formTitle={`${buildHeading}  Build`}>
      <>
        {editProductBuildData && id && (
          <Row className="row-container">
            <Col lg={4} md={8} sm={12} xs={24}>
              <div className="label-wapper">
                <label> Build Name / Version</label>
                <p>
                  {editProductBuildData.name} ( # {editProductBuildData.version}{" "}
                  )
                </p>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={4} md={8} sm={12} xs={24}>
              <div className="label-wapper">
                <label> Description</label>
                <p>{editProductBuildData.description}</p>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={4} md={8} sm={12} xs={24}>
              <div className="label-wapper">
                <label> Published Date</label>
                <p>
                  {new Date(editProductBuildData.publishedDate).toDateString()}
                </p>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={4} md={8} sm={12} xs={24}>
              <div className="label-wapper">
                <label> CdCiIdentifier / URL</label>
                <p>{editProductBuildData.cdCiIdentifier}</p>
                <p>
                  <Popover
                    content={editProductBuildData.downloadUrl}
                    title="Url"
                  >
                    <a href={editProductBuildData.downloadUrl}>
                      <Button size="small" icon={<DownloadOutlined />}>
                        Download
                      </Button>
                    </a>
                  </Popover>
                  {}
                </p>
              </div>
            </Col>
            <Divider type="vertical"></Divider>
            <Col lg={4} md={8} sm={12} xs={24}>
              <div className="label-wapper">
                <label>Meta Data </label>
                <p className="discription">
                  {fromObjectToKeyValueArray(
                    editProductBuildData?.buildMeta
                  ).map((a) => {
                    return (
                      <Space key={a.key}>
                        {a.key} : {a.value}
                      </Space>
                    );
                  })}
                </p>
              </div>
            </Col>
          </Row>
        )}
        {initialFormValues ? (
          <Form
            colon={false}
            form={addProductBuildForm}
            className="common-form h-full"
            name="add-build"
            initialValues={editProductBuildData}
            onFinish={(values) => {
              values.buildMeta = fromKeyValueArrayToObject(values.buildMeta);
              onFinish(values);
            }}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <div className="flex flex-row space-x-4 items-start w-full h-full">
              <Col className="flex flex-col space-y-4 w-1/3">
                <div
                  className={`${
                    !id && "border p-3 rounded-lg !border-[#eeeff2]"
                  }`}
                >
                  <Form.Item hidden={true} name="id">
                    <Input id="buildAddInput" />
                  </Form.Item>
                  {!id && (
                    <>
                      <Form.Item
                        name="name"
                        label="Name"
                        className="w-full !mb-2"
                        rules={[
                          {
                            required: true,
                            message: "Please input build name!",
                          },
                          {
                            max: maxNameCharacterLength,
                            message: "Input cannot exceed 50 characters!",
                          },
                          getSpaceValidationRule()
                        ]}
                      >
                        <Input className="!border-[#eeeff2]" id="buildName" />
                      </Form.Item>
                      <Form.Item
                        name="version"
                        label="Version"
                        className="w-full !mb-2"
                        rules={[
                          {
                            required: true,
                            message: "Please input build version!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value && !isValidVersion(value)) {
                                return Promise.reject(
                                  `${ErrorMessages.QUERYBUILDER.TAGSELECTOR.VERSION.ERROR}`
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input
                          className="!border-[#eeeff2]"
                          id="buildVersion"
                        />
                      </Form.Item>
                      <Form.Item
                        name="downloadUrl"
                        label="Download Url"
                        className="w-full !mb-2"
                        rules={[
                          {
                            required: true,
                            message: "Please input build download url!",
                          },
                          {
                            type: "url",
                            message: "Please input valid build download url!",
                          },
                        ]}
                      >
                        <Input
                          className="!border-[#eeeff2]"
                          id="buildDownloadUrl"
                        />
                      </Form.Item>

                      {/* <Form.Item
                name="cdCiIdentifier"
                label="Cd Ci Identifier"
                rules={[
                  {
                    required: true,
                    message: "Please input build  cd ci identifier!",
                  },
                ]}
              >
                <Input id="buildCdCiIdentifier" />
              </Form.Item> */}
                    </>
                  )}
                  <Form.Item
                    label="Description"
                    name="description"
                    className="w-full !mb-2"
                    rules={[
                      {
                        required: true,
                        message: "Please input build description!",
                      },
                      getSpaceValidationRule()
                    ]}
                  >
                    <TextArea
                      className="!border-[#eeeff2] max-h-[100px]"
                      id="buildDescription"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Enabled ?"
                    name={"isEnabled"}
                    className="w-full !mb-2"
                    valuePropName="checked"
                    prefixCls="enabled-form"
                  >
                    <Switch id="buildSwitch" />
                  </Form.Item>
                </div>
              </Col>
              {!id && (
                <Col className="flex flex-col space-y-4 w-2/3 h-full">
                  <div className="border px-3 rounded-lg !border-[#eeeff2] flex !flex-col flex-grow min-h-0 h-full overflow-y-scroll">
                    <Form.Item
                      className="w-full !mb-2 mt-3 "
                      name="metaData" // No need for validation here as it's handled in Form.List
                      required
                    >
                      <Form.List
                        name="buildMeta"
                        className="w-full flex-grow min-h-0 h-full"
                        rules={[
                          {
                            validator: async (_, metaData) => {
                              if (!metaData || metaData.length < 1) {
                                return Promise.reject(
                                  new Error("Please add at least 1 metadata")
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            <div className="flex custom-form-label mb-2 justify-between w-full">
                              <p className="text-sm font-semibold">
                                <span className="text-red-500">*</span> Meta
                                Data
                              </p>
                              <Form.Item className="!mb-0 max-w-[150px]">
                                {/* Add Metadata Button */}
                                <Button
                                  id="addMetaDataInRelease"
                                  type="primary"
                                  onClick={() => add()}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add Metadata
                                </Button>

                                {/* Error List */}
                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </div>
                            {fields.length > 0 && (
                              <div className="border p-3 rounded-lg !border-[#eeeff2]">
                                {fields.map(({ key, name, ...restField }) => (
                                  <div
                                    key={key}
                                    className="flex flex-row w-full space-x-2"
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name, "key"]}
                                      className="w-full !mb-2 mt-3"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input key",
                                        },
                                        getSpaceValidationRule()
                                      ]}
                                    >
                                      <Input
                                        id="inputKeyOfTarget"
                                        placeholder="key"
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "value"]}
                                      className="w-full !mb-2 mt-3"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input value",
                                        },
                                        getSpaceValidationRule()
                                      ]}
                                    >
                                      <Input
                                        id="inputValueOfTarget"
                                        placeholder="Value"
                                      />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                      className="mt-1"
                                      onClick={() => remove(name)}
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        )}
                      </Form.List>
                    </Form.Item>
                  </div>
                </Col>
              )}
            </div>

            <Form.Item className="!mb-0 mt-2 top-0 right-4 position-absolute">
              <Space>
                <Button
                  id="backToBuildList"
                  type="default"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    backToListPage();
                  }}
                >
                  Back
                </Button>
                <Button
                  className="submit-btn"
                  id="submitBuild"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
                {id && <InfoDetails infoData={editProductBuildData} />}
              </Space>
            </Form.Item>
          </Form>
        ) : (
          <>
            <Loader />
          </>
        )}
      </>
    </FormLayout>
  );
};
export default AddProductBuildComponent;

