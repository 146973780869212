// QueryBuilderHelpers.js
import React, { useEffect, useState } from "react";
import { Input, Radio, Space, DatePicker } from "antd";
import { getSpaceValidationRule, handleKeyDownAllowOnlyNumber } from "../../Util/commonUtility";
import CommonTagSelector from "./CommonTagSelector";
import CommonRangeSelector from "./CommonRangeSelector";
import dayjs from "dayjs";
import ErrorMessages from "../../Constant/strings/errors";
import ErrorToolTip from "./ErrorToolTip";

export const CustomValueEditor = (props) => {
  const [value, setValue] = useState(props.value);
  const [operatorValue, setOperator] = useState(props.operator)

  useEffect(() => {
    setValue(null);
    setOperator(props.operator)
  }, [props.operator]);

  const onFieldChange = (data, type) => {
    props.fieldData.touched = true;
    switch (type) {
      case "empty":
        props.handleOnChange("");
        break;
      case "select":
      case "date":
      case "RangeRule":
        props.handleOnChange(data);
        break;
      case "input":
      case "boolean":
        props.handleOnChange(data?.target?.value);
        break;
      case "input-number":
        props.handleOnChange(data?.target?.value);
        break;
      case "input-maked-value":
        props.handleOnChange(data);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (props.operator !== operatorValue) {
      onFieldChange('', 'empty')
      setValue(null);
    }
  }, [props.operator])

  return generateInputElement(
    props?.fieldData?.type,
    props?.operator,
    value,
    onFieldChange,
    props
  );
};
const generateErrorMessage = (props, message) => {
  const { value, fieldData, validation } = props;
  const inputType = fieldData?.type;
  const validationRule = getSpaceValidationRule();

  let errorMsg = message;
  const isEmpty = value.length === 0;
  if (inputType === "String") {
    if (isEmpty) {
      errorMsg = message;
    } else if (!validationRule.pattern.test(value)) {
      errorMsg = validationRule.message;
    } else {
      errorMsg = '';
    }
  }
  const shouldShowError =
    ((!validation || inputType === "String") && fieldData?.touched && errorMsg);
  return shouldShowError ? <ErrorToolTip title={errorMsg} /> : null;
};


const generateInputComponent = (value, props, onFieldChange) => {
  const inputComponent = (
    <Space.Compact direction="vertical">
      <Input
        onChange={(data) => onFieldChange(data, "input")}
        onPressEnter={(data) => onFieldChange(data, "input")}
        defaultValue={props?.value}
        value={props.value}
        placeholder={ErrorMessages.QUERYBUILDER.STRING}
        status={!props.validation && props.fieldData.touched ? "error" : ""}
        className={`react-query-builder-input-string ${props.validation ? "" : "common-error"
          }`}
      />
      {generateErrorMessage(props, ErrorMessages.QUERYBUILDER.STRING)}
    </Space.Compact>
  );
  return inputComponent;
};

const generateCommonTagSelector = (value, props, onFieldChange, inputType) => {
  return (
    <CommonTagSelector
      initialValue={props?.value}
      onTagChange={(data) => {
        onFieldChange(data, "select");
      }}
      inputType={inputType}
      validationMessage={!props.validation && props.fieldData.touched}
    />
  );
};
const generateDateComponent = (value, props, onFieldChange) => {
  return (
    <Space.Compact>
      <DatePicker
        defaultValue={value && dayjs(value).isValid() ? dayjs(value) : null}
        value={props?.value && dayjs(props?.value).isValid() ? dayjs(props?.value) : null}
        onChange={(date, dateString) => {
          onFieldChange(dateString, "date");
        }}
      />
      {generateErrorMessage(props, ErrorMessages.QUERYBUILDER.DATE)}
    </Space.Compact>
  );
};
const generateBooleanComponent = (value, props, onFieldChange) => {
  return (
    <Space.Compact direction="vertical">
      <Radio.Group
        onChange={(data) => onFieldChange(data, "boolean")}
        defaultValue={value}
        value={props?.value}
      >
        <Radio value={true}>True</Radio>
        <Radio value={false}>False</Radio>
      </Radio.Group>
      {generateErrorMessage(props, ErrorMessages.QUERYBUILDER.BOOLEAN)}
    </Space.Compact>
  );
};
const generateInputNumberComponent = (value, props, onFieldChange) => {
  return (
    <Space.Compact direction="vertical">
      <Input
        type="number"
        onChange={(data) => onFieldChange(data, "input-number")}
        // onPressEnter={(data) => onFieldChange(data, "input-number")}
        value={props?.value}
        defaultValue={props?.value}
        onKeyDown={handleKeyDownAllowOnlyNumber}
        controls={false}
        status={!props.validation && props.fieldData.touched ? "error" : ""}
        className={`react-query-builder-input-string ${props.validation ? "" : "common-error"
          }`}
      // min={0}
      />
      {generateErrorMessage(props, ErrorMessages.QUERYBUILDER.NUMBER)}
    </Space.Compact>
  );
};
const generateMaskedVersionComponent = (value, props, onFieldChange) => {
  return (
    <Space.Compact direction="vertical">
      <Input
        defaultValue={value}
        onChange={(e) => {
          onFieldChange(e.target.value, "input-maked-value");
        }}
        value={props?.value}
        status={!props.validation && props.fieldData.touched ? "error" : ""}
        className={`react-query-builder-input-string ${props.validation ? "" : "common-error"
          }`}
      />
      {generateErrorMessage(props, ErrorMessages.QUERYBUILDER.VERSION)}
    </Space.Compact>
  );
};
const generateRangeRuleComponent = (value, props, onFieldChange, inputType) => {
  return (
    <Space.Compact direction="horizontal">
      <CommonRangeSelector
        initialValue={props.value}
        inputType={inputType}
        onKeyDown={handleKeyDownAllowOnlyNumber}
        onTagChange={(data) => {
          onFieldChange(data, "RangeRule");
        }}
        touched={props.fieldData.touched}
        validationMessage={!props.validation}
      />
    </Space.Compact>
  );
};
const generateInputElement = (type, operator, value, onFieldChange, props) => {
  const elementMapping = {
    String: {
      Empty: <></>,
      NotEmpty: <></>,
      EqualsTo: generateInputComponent(value, props, onFieldChange),
      NotEqualsTo: generateInputComponent(value, props, onFieldChange),
      In: generateCommonTagSelector(value, props, onFieldChange, "Input"),
      NotIn: generateCommonTagSelector(value, props, onFieldChange, "Input"),
      Contains: generateInputComponent(value, props, onFieldChange),
      NotContains: generateInputComponent(value, props, onFieldChange),
      StartWith: generateInputComponent(value, props, onFieldChange),
      EndWith: generateInputComponent(value, props, onFieldChange),
    },
    Date: {
      Empty: <></>,
      NotEmpty: <></>,
      EqualsTo: generateDateComponent(value, props, onFieldChange),
      NotEqualsTo: generateDateComponent(value, props, onFieldChange),
      In: generateCommonTagSelector(value, props, onFieldChange, "Date"),
      NotIn: generateCommonTagSelector(value, props, onFieldChange, "Date"),
      GreaterThan: generateDateComponent(value, props, onFieldChange),
      LessThan: generateDateComponent(value, props, onFieldChange),
      GreaterThanEqualsTo: generateDateComponent(value, props, onFieldChange),
      LessThanEqualsTo: generateDateComponent(value, props, onFieldChange),
      FromTo: generateRangeRuleComponent(value, props, onFieldChange, "Date"),
      StartWith: generateDateComponent(value, props, onFieldChange),
      EndWith: generateDateComponent(value, props, onFieldChange),
    },
    Boolean: {
      Empty: <></>,
      NotEmpty: <></>,
      EqualsTo: generateBooleanComponent(value, props, onFieldChange),
      NotEqualsTo: generateBooleanComponent(value, props, onFieldChange),
    },
    Number: {
      Empty: <></>,
      NotEmpty: <></>,
      EqualsTo: generateInputNumberComponent(value, props, onFieldChange),
      NotEqualsTo: generateInputNumberComponent(value, props, onFieldChange),
      In: generateCommonTagSelector(value, props, onFieldChange, "InputNumber"),
      NotIn: generateCommonTagSelector(
        value,
        props,
        onFieldChange,
        "InputNumber"
      ),
      GreaterThan: generateInputNumberComponent(value, props, onFieldChange),
      LessThan: generateInputNumberComponent(value, props, onFieldChange),
      StartWith: generateInputNumberComponent(value, props, onFieldChange),
      EndWith: generateInputNumberComponent(value, props, onFieldChange),
      GreaterThanEqualsTo: generateInputNumberComponent(
        value,
        props,
        onFieldChange
      ),
      LessThanEqualsTo: generateInputNumberComponent(
        value,
        props,
        onFieldChange
      ),
      FromTo: generateRangeRuleComponent(value, props, onFieldChange, "Number"),
    },

    Version: {
      Empty: <></>,
      NotEmpty: <></>,
      EqualsTo: generateMaskedVersionComponent(value, props, onFieldChange),
      NotEqualsTo: generateMaskedVersionComponent(value, props, onFieldChange),
      In: generateCommonTagSelector(value, props, onFieldChange, "Version"),
      NotIn: generateCommonTagSelector(value, props, onFieldChange, "Version"),
      GreaterThan: generateMaskedVersionComponent(value, props, onFieldChange),
      LessThan: generateMaskedVersionComponent(value, props, onFieldChange),
      StartWith: generateMaskedVersionComponent(value, props, onFieldChange),
      EndWith: generateMaskedVersionComponent(value, props, onFieldChange),
      GreaterThanEqualsTo: generateMaskedVersionComponent(
        value,
        props,
        onFieldChange
      ),
      LessThanEqualsTo: generateMaskedVersionComponent(
        value,
        props,
        onFieldChange
      ),
      FromTo: generateRangeRuleComponent(
        value,
        props,
        onFieldChange,
        "Version"
      ),
    },
  };
  if (elementMapping[type] && elementMapping[type][operator]) {
    return elementMapping[type][operator];
  } else {
    return null;
  }
};

