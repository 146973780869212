import React, { useEffect, useState } from "react";
import AddProductEnvironmentForm from "../../Components/ProductEnvironments/addProductEnvironmentComponent";
import { useNavigate, useParams } from "react-router-dom";
import Urls from "../../Constant/Urls";
import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../Api/client";
import NotificationService from "../../Services/notification";

const AddEnvironmentContainer = () => {
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const backToListPage = () => {
    navigate("/product/environments");
  };
  const [isEnvDataLoading, setIsEnvDataLoading] = useState(false);
  const [isEnvironmentDataAdded, setIsEnvironmentDataAdded] = useState(false);
  const [productEnvironmentData, setProductEnvironmentData] = useState(false);
  const { id } = useParams();

  const AddEditEnvironment = async (payload, action = "Add") => {
    setIsEnvironmentDataAdded(true);
    const url =
      action === "Edit"
        ? Urls.TENANT_UPDATE_PRODUCT_ENVIRONMENTS.replace(
            "#{tenantId}",
            authState?.accountData?.tenantId
          )
            .replace("#{productId}", productState?.selectedProduct?.id)
            .replace("#{environmentId}", id)
        : Urls.TENANT_ADD_PRODUCT_ENVIRONMENTS.replace(
            "#{tenantId}",
            authState?.accountData?.tenantId
          ).replace("#{productId}", productState?.selectedProduct?.id);

    const response =
      action === "Edit"
        ? await httpClient.put(url, payload)
        : await httpClient.post(url, payload);

    if (isSuccessStatus(response?.status)) {
      NotificationService.success(
        "Success",
        `Product Environment ${action.toLowerCase()}ed successfully`
      );
      backToListPage();
    }

    setIsEnvironmentDataAdded(false);
  };

  const fetchEnvironmentDataById = async (id) => {
    setIsEnvDataLoading(true);
    const response = await httpClient.get(
      Urls.TENANT_GET_PRODUCT_ENVIRONMENT_BY_ID.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{environmentId}", id)
    );
    if (isSuccessStatus(response?.status)) {
      setProductEnvironmentData(response.data);
    } else {
      backToListPage();
    }
    setIsEnvDataLoading(false);
  };

  useEffect(() => {
    if (id) {
      fetchEnvironmentDataById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, productState, authState]);

  return (
    <AddProductEnvironmentForm
      onSubmit={AddEditEnvironment}
      isEnvDataLoading={isEnvDataLoading}
      ProductEnvironmentDataById={productEnvironmentData}
      backToListPage={backToListPage}
    />
  );
};

export default AddEnvironmentContainer;
