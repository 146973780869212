import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  TimePicker,
} from "antd";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  debounceForSearchApiCall,
  getSpaceValidationRule,
  hasInvalidNested,
  replaceContentWithPlaceholders,
} from "../../Util/commonUtility";
import { Option } from "antd/es/mentions";
import {
  DAYS_OPTIONS,
  weekDays,
  END_CONDITION,
  FREQUENCY,
  SHOW_AT,
  CONTROL_TYPE,
  DateFormat,
  ContentTypes,
  maxNameCharacterLength,
} from "../../Constant/Common";
import dayjs from "dayjs";
import { useLocation, useParams } from "react-router-dom";
import DynamicForm from "./LanguagePlaceHolder/dynamicForm";
import FormLayout from "../Layout/formLayout";
import InfoDetails from "../Common/editHistoryInfo";
import TargetCriteria from "../../Containers/TargetCriteria/TargetCriteria";

const AddContentRolloutsComponent = (props) => {
  const {
    backToListPage,
    onFinish,
    productBuildArray,
    targetOperators,
    productTargets,
    editContentRolloutData,
    allContentTemplates,
    onPreviewXaml,
    usedForCopy,
    showDuplicationPopUp,
    setShowDuplicationPopUp,
    duplicateErrorMessage,
  } = props;

  const [formContentRollout] = Form.useForm();
  const location = useLocation();
  const { id } = useParams();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [selectedTargetIdsArray, setSelectedTargetIdsArray] = useState([]);
  const [currentLanguagePlaceholderData, setCurrentLanguagePlaceholderData] =
    useState();
  const contentRolloutHeading = location.pathname.includes("add")
    ? "Add"
    : "Edit";
  const [targetCriteriaErrorMessage, setTargetCriteriaErrorMessage] =
    useState("");
  const [open, setOpen] = useState(false);
  const [frequency, setFrequency] = useState();
  const [targetErrorMessage, setTargetErrorMessage] = useState();
  const [templateId, setTemplateId] = useState();
  const [showAt, setShowAt] = useState();
  const [invalidEquationData, setInvalidEquationData] = useState();
  const [endCondition, setEndCondition] = useState(null);
  const [templateData, setTemplateData] = useState({
    selectedTemplateType: "",
    languages: [],
    templateSchema: "",
    templatePlaceholder: [],
  });

  const initialValueFormat = (data) => {
    if (data.controlType === CONTROL_TYPE.DATE) {
      return dayjs(data.defaultValue);
    }
    return data.defaultValue;
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    if (invalidEquationData) {
      setTargetCriteriaErrorMessage("Please complete adding targeting rules.");
    } else {
      setOpen(false);
    }
  };

  const [allFormValues, setAllFormValues] = useState();
  const [activeTab, setActiveTab] = useState();

  const setActiveTabValue = (templateData) => {
    const isEnglishLanguageAvailable = templateData.languages?.find(
      (obj) => obj.code === "en"
    );

    const initialActiveTabValue = isEnglishLanguageAvailable
      ? isEnglishLanguageAvailable?.code
      : templateData.languages[0]?.code;

    setActiveTab(initialActiveTabValue);
  };
  const TargetCriteriaFinalValue = (data, json) => {
    formContentRollout.setFieldValue("targetCriteria", data);
    formContentRollout.setFieldValue(
      "targetCriteriaJson",
      JSON.stringify(json)
    );
    if (data?.equationGroup) {
      setTargetErrorMessage("");
      setTargetCriteriaErrorMessage("");
    }
    const hasInvalidDeepNested = hasInvalidNested(data);
    if (hasInvalidDeepNested) {
      setInvalidEquationData(true);
    } else {
      setInvalidEquationData(false);
    }
  };
  useEffect(() => {
    if (templateData) {
      setActiveTabValue(templateData);
    }
  }, [templateData]);

  const setInitialFormValues = useCallback((templateData) => {
    const initialFormValues = templateData.languages.reduce((acc, lang) => {
      const config = {};
      templateData.templatePlaceholder.forEach((field) => {
        config[`${lang.code}_${field.name}`] = initialValueFormat(field);
      });
      acc[lang.code] = config;
      return acc;
    }, {});
    setAllFormValues(initialFormValues);
  }, []);

  const handleOk = () => {
    const formattedData = {
      ...editContentRolloutData,
      AllowForceInsert: true,
    };
    onFinish(formattedData);
    setShowDuplicationPopUp(false);
  };
  const handleCancel = () => {
    setShowDuplicationPopUp(false);
  };

  useEffect(() => {
    if (!id && !usedForCopy) {
      setInitialFormValues(templateData);
    }
  }, [id, setInitialFormValues, templateData]);

  const formatDefaultValues = useCallback((record, defaultValue) => {
    if (defaultValue === "" || defaultValue === null || !defaultValue) {
      let finalValue = record.defaultValue;
      return finalValue;
    } else {
      let finalValue = defaultValue;

      if (record.controlType === CONTROL_TYPE.COLOR_PICKER) {
        finalValue =
          typeof defaultValue === "string"
            ? defaultValue
            : defaultValue?.toHexString();
      }
      if (record.controlType === CONTROL_TYPE.DATE) {
        finalValue = dayjs(defaultValue).format(DateFormat);
      }
      return finalValue;
    }
  }, []);

  const getConfig = useCallback(
    (valuesSource, isDefault = false) =>
      templateData.languages.reduce((acc, lang) => {
        const config = {};
        templateData.templatePlaceholder.forEach((field) => {
          const value = isDefault
            ? field?.defaultValue
            : valuesSource[`${lang.code}_${field.name}`];
          config[`${lang.code}_${field.name}`] = formatDefaultValues(
            field,
            value
          );
        });
        acc[lang.code] = config;
        return acc;
      }, {}),
    [
      formatDefaultValues,
      templateData.languages,
      templateData.templatePlaceholder,
    ]
  );

  const handleSetCurrentPlaceholderData = useCallback(
    (valueSource) => {
      const formValues = getConfig(valueSource, false);
      const currentFormValues = formValues[activeTab];

      const updatedPlaceholderData = templateData?.templatePlaceholder?.map(
        (placeholder) => {
          const value = currentFormValues[`${activeTab}_${placeholder.name}`];
          return {
            ...placeholder,
            defaultValue: formatDefaultValues(placeholder, value),
          };
        }
      );
      setCurrentLanguagePlaceholderData(updatedPlaceholderData);
    },
    [
      getConfig,
      activeTab,
      templateData?.templatePlaceholder,
      formatDefaultValues,
    ]
  );

  const handleApiCall = (values, TemplateLanguageData) => {
    const formattedDays = values?.days?.join();
    const formattedStartDate = values.startDate
      ? dayjs(values.startDate).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = values.endDate
      ? dayjs(values.endDate).format("YYYY-MM-DD")
      : null;

    const formattedData = {
      id: values.id,
      buildId: values.buildId,
      contentTemplateId: values.contentTemplateId,
      name: values.name,
      isEnable: values.isEnable,
      targets: values.targets || formContentRollout.getFieldValue("targets"),
      targetCriteria:
        values.targetCriteria ||
        formContentRollout.getFieldValue("targetCriteria"),
      targetCriteriaJson:
        values.targetCriteriaJson ||
        formContentRollout.getFieldValue("targetCriteriaJson"),
      frequency: frequency,
      showAt: values.showAt,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      end: values.end,
      numberOfShow: values.numberOfShow,
      days: formattedDays,
      templateType: templateData?.selectedTemplateType,
      ...(values.showAt === SHOW_AT.SET_TIME && {
        showTime: values?.showTime,
      }),
      templateSchema: values.templateSchema,
      templatePlaceholder: values.templatePlaceholder,
      templateData: TemplateLanguageData,
    };
    onFinish(formattedData);
  };

  const getFormattedValuesForLang = (langCode, values) => {
    return templateData.templatePlaceholder.reduce((acc, field) => {
      acc[`${langCode}_${field.name}`] = values[`${langCode}_${field.name}`];
      return acc;
    }, {});
  };

  const handleValueChange = (_, allValues) => {
    const currentLangCode = activeTab;

    // Update the form values for the active language only
    setAllFormValues((prevValues) => ({
      ...prevValues,
      [currentLangCode]: getFormattedValuesForLang(currentLangCode, allValues),
    }));

    handleSetCurrentPlaceholderData(allValues);
  };

  const handleSubmit = (values) => {
    if (
      !formContentRollout.getFieldValue("targets") &&
      !formContentRollout.getFieldValue("targetCriteria")
    ) {
      setTargetErrorMessage("Please add at least one target criteria");
    } else {
      const submitData = templateData.languages.map((lang) => {
        const langValues = allFormValues[lang.code] || {};
        const config = templateData.templatePlaceholder.reduce((acc, field) => {
          acc[field.name] = formatDefaultValues(
            field,
            langValues[`${lang.code}_${field.name}`]
          );
          return acc;
        }, {});
        const placeholderData = templateData?.templatePlaceholder?.map(
          (placeholder) => {
            return {
              ...placeholder,
              defaultValue: formatDefaultValues(
                placeholder,
                langValues[`${lang.code}_${placeholder.name}`]
              ),
            };
          }
        );
        return {
          langName: lang.name,
          langCode: lang.code,
          config,
          content: replaceContentWithPlaceholders(
            templateData.templateSchema,
            placeholderData
          )?.replacedContent,
        };
      });
      handleApiCall(values, submitData);
    }
  };
  const handleFinishFailed = async (errorInfo) => {
    if (
      !errorInfo?.values?.targets?.length &&
      (!Array.isArray(errorInfo?.values?.targetCriteria?.equationGroup) ||
        errorInfo.values.targetCriteria.equationGroup.length === 0)
    ) {
      const errMessage = "Please add at least one target criteria";
      setTargetErrorMessage(errMessage);
      setTargetCriteriaErrorMessage(errMessage);
    }
    if (
      errorInfo?.values.targets?.length > 0 &&
      selectedTargetIdsArray?.length === 0
    ) {
      setTargetErrorMessage("Please select target!");
    }

    console.error(errorInfo);
  };

  const onSelectTarget = (targetId, name) => {
    setTargetErrorMessage("");
    setTargetCriteriaErrorMessage("");
    setSelectedTargetIdsArray((prev) => {
      const updatedArray = [...prev];
      const existingIndex = updatedArray.findIndex((item) => item.id === name);
      if (existingIndex !== -1) {
        updatedArray[existingIndex].value = targetId;
      } else {
        updatedArray.push({ id: name, value: targetId });
      }
      return updatedArray;
    });
  };

  const onRemoveTarget = (targetId) => {
    setSelectedTargetIdsArray((prev) =>
      prev.filter((target) => target.value !== targetId)
    );
  };

  const onSelectTemplate = (templateId) => {
    setTemplateId(templateId);
    const template = allContentTemplates.find((obj) => obj.id === templateId);
    formContentRollout.setFieldValue("contentTemplateId", template?.id);
    formContentRollout.setFieldValue(
      "templateSchema",
      template?.templateSchema
    );
    formContentRollout.setFieldValue(
      "templatePlaceholder",
      template.templatePlaceholder
    );
    const templateDataValue = {
      selectedTemplateType: template.templateType,
      languages: template.languages || [],
      templatePlaceholder: template.templatePlaceholder || [],
      templateSchema: template.templateSchema || "",
    };
    setTemplateData(templateDataValue);
    setCurrentLanguagePlaceholderData(template.templatePlaceholder);
    setInitialFormValues(templateDataValue);
    setActiveTabValue(templateDataValue);
  };

  const handleFrequencyChange = (value) => {
    setFrequency(value);
    if (frequency !== value) {
      formContentRollout.setFieldValue("days", null);
    }
  };
  const handleShowAtChange = (value) => {
    formContentRollout.setFieldValue("showTime", null);
    setShowAt(value);
  };
  const handleEndConditionChange = (value) => setEndCondition(value);

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  useEffect(() => {
    // const currentFormValues = formContentRollout.getFieldsValue();
    const currentConfigData = allFormValues ? allFormValues[activeTab] : {};
    const currentFormValues = activeTab ? allFormValues[activeTab] : {};
    if (allFormValues) {
      formContentRollout.setFieldsValue({
        ...currentFormValues,
        ...currentConfigData,
      });
      const updatedPlaceholderData = templateData?.templatePlaceholder?.map(
        (placeholder) => {
          const value = currentFormValues[`${activeTab}_${placeholder.name}`];
          return {
            ...placeholder,
            defaultValue: formatDefaultValues(placeholder, value),
          };
        }
      );
      setCurrentLanguagePlaceholderData(updatedPlaceholderData);
    }
  }, [
    activeTab,
    allFormValues,
    formContentRollout,
    formatDefaultValues,
    templateData,
    templateData?.templatePlaceholder,
  ]);

  useEffect(() => {
    const editTemplateDataValueFormat = (record, defaultValue) => {
      if (record.controlType === CONTROL_TYPE.DATE) {
        return dayjs(defaultValue);
      }

      return defaultValue;
    };
    if (editContentRolloutData) {
      setTemplateId(editContentRolloutData?.contentTemplateId);
      const editedTemplateData = editContentRolloutData?.templateData;
      const parsedTemplateData =
        typeof editedTemplateData === "string"
          ? JSON.parse(editedTemplateData)
          : editedTemplateData;
      const selectedTemplateData = allContentTemplates?.find(
        (obj) => obj.id === editContentRolloutData.contentTemplateId
      );
      //Performing a set operation for a rollout ensures that if only one language was there when template  initially created, editing the template to add more languages won't affect the existing rollout.
      const templateDataLanguageSet = new Set(
        parsedTemplateData?.map((item) => item?.langCode)
      );
      const filterTemplateDataLanguages =
        selectedTemplateData?.languages.filter((item) =>
          templateDataLanguageSet.has(item.code)
        );
      const editedTemplateSchema = editContentRolloutData?.templateSchema;
      const templateSchema =
        editContentRolloutData?.templateType === ContentTypes.JSON
          ? editedTemplateSchema
          : editedTemplateSchema;
      setTemplateData({
        selectedTemplateType: editContentRolloutData?.templateType,
        languages: filterTemplateDataLanguages || [],
        templatePlaceholder: editContentRolloutData?.templatePlaceHolder || [],
        templateSchema: templateSchema || "",
      });

      const templateDataFormat = parsedTemplateData?.reduce((acc, item) => {
        acc[item?.langCode] = item.config;
        return acc;
      }, {});

      const finalFormattedData = filterTemplateDataLanguages?.reduce(
        (acc, lang) => {
          const config = {};

          const currentLangTemplateDataValue = templateDataFormat[lang.code];
          editContentRolloutData?.templatePlaceHolder?.forEach((field) => {
            config[`${lang.code}_${field.name}`] = editTemplateDataValueFormat(
              field,
              currentLangTemplateDataValue[field.name]
            );
          });
          acc[lang.code] = config;
          return acc;
        },
        {}
      );
      setAllFormValues(finalFormattedData);
      setEndCondition(editContentRolloutData.end);
      setFrequency(editContentRolloutData.frequency);
      setShowAt(editContentRolloutData.showAt);

      formContentRollout.setFieldsValue({
        ...editContentRolloutData,
        templateData: editContentRolloutData.templateData || "[]",
        frequency: editContentRolloutData.frequency,
        showAt: editContentRolloutData.showAt || "",
        days: editContentRolloutData?.days?.split(","),
        startDate: editContentRolloutData.startDate
          ? dayjs(editContentRolloutData.startDate)
          : null,
        endDate: editContentRolloutData.endDate
          ? dayjs(editContentRolloutData.endDate)
          : null,
        end: editContentRolloutData.end,
        buildId: editContentRolloutData.buildId,
        numberOfShow: editContentRolloutData.numberOfShow,
        templatePlaceholder: selectedTemplateData?.templatePlaceholder || [],
        targets: editContentRolloutData?.targets || [],
        targetCriteria: editContentRolloutData?.targetCriteria || {},
      });

      const buildData = productBuildArray?.find(
        (obj) => obj.id === editContentRolloutData?.buildId
      );
    }
  }, [allContentTemplates, editContentRolloutData, formContentRollout]);

  return (
    <FormLayout formTitle={`${contentRolloutHeading} Content Rollout`}>
      <>
        <Form
          form={formContentRollout}
          className="form-container flex flex-row items-start w-full gap-4 h-full"
          name="add-product"
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={handleFinishFailed}
          onValuesChange={handleValueChange}
        >
          <Modal
            open={showDuplicationPopUp}
            title="Warning: Duplicate Target Detected"
            okText="Proceed and Save"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={(_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            )}
          >
            <p className="text-gray-700 mb-4">
              A target with the same identifier already exists in the content
              rollout. Do you want to proceed with forcefully inserting this
              content rollout? Please note that this action may overwrite the
              existing target.
            </p>
            <span className="text-sm font-bold mb-2">
              {duplicateErrorMessage}
            </span>
          </Modal>
          <Col className="flex flex-col space-y-4 w-1/3 h-full">
            <div className="border p-3 rounded-lg !border-[#eeeff2]">
              <Form.Item
                label="Select Template"
                name="contentTemplateId"
                className="w-full !mb-2"
                rules={[
                  {
                    required: true,
                    message: "Please select the content template!",
                  },
                ]}
                hidden={id}
              >
                <Select
                  id="templateId"
                  onChange={(data) => onSelectTemplate(data)}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    const content = allContentTemplates.find(
                      (f) => f.id === option.value
                    );

                    if (!content) return false;

                    return (
                      content.name.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }}
                >
                  {allContentTemplates?.map((template) => (
                    <Option key={template.id} value={template.id}>
                      {template.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Name"
                name={"name"}
                className="w-full !mb-2"
                rules={[
                  {
                    required: true,
                    message: "Please enter content rollout name!",
                  },
                  {
                    max: maxNameCharacterLength,
                    message: "Input cannot exceed 50 characters!",
                  },
                   getSpaceValidationRule()
                ]}
              >
                <Input id="ContentRolloutName" className="!border-[#eeeff2]" />
              </Form.Item>
              <div className="flex flex-row justify-content-between align-items-start">
                <Form.Item
                  name="isEnable"
                  className="!mb-1"
                  valuePropName="checked"
                  label={"Enabled?"}
                  prefixCls="enabled-form"
                >
                  <Switch id="ContentRolloutSwitch" />
                </Form.Item>
                <Form.Item className="!mb-0" id="buttons">
                  <Button
                    id="backToListingPage"
                    type="primary"
                    onClick={showDrawer}
                  >
                    Configure Targeting Rules
                  </Button>
                  {targetErrorMessage && (
                    <p className="text-red-500 whitespace-pre-line max-w-[250px]">
                      {targetErrorMessage}
                    </p>
                  )}
                </Form.Item>
              </div>
              <Form.Item hidden={true} name="id" id="ContentRolloutId">
                <Input />
              </Form.Item>
              <Form.Item
                hidden={true}
                name="templateType"
                className="w-full !mb-2"
                id="templateType"
              >
                <Input />
              </Form.Item>
              <Form.Item hidden name="templateSchema" />{" "}
              <Form.Item hidden name="templatePlaceholder" />{" "}
            </div>
            <div className="border p-3 rounded-lg !border-[#eeeff2] flex-grow overflow-y-scroll">
              <p className="!mb-3 font-semibold text-sm">
                Content Rollout Scheduling
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                <Form.Item
                  className="w-full !mb-2"
                  name="frequency"
                  label="Frequency"
                >
                  <Select
                    className="!mb-0"
                    onChange={handleFrequencyChange}
                    showSearch
                    filterOption={(input, option) => {
                      const searchTerm = input.toLowerCase();
                      const label = option?.children?.toLowerCase() || "";

                      return label.indexOf(searchTerm) >= 0;
                    }}
                  >
                    {DAYS_OPTIONS.map((options) => (
                      <Select.Option key={options.label} value={options.value}>
                        {options.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {frequency === FREQUENCY.DAILY && (
                  <>
                    <Form.Item
                      rules={[
                        {
                          required: frequency,
                          message: "Please select the Show At",
                        },
                      ]}
                      className="w-full !mb-2"
                      name="showAt"
                      label="Show At"
                    >
                      <Select
                        onChange={handleShowAtChange}
                        showSearch
                        filterOption={(input, option) => {
                          const searchTerm = input.toLowerCase();
                          const label = option?.children?.toLowerCase() || "";

                          return label.indexOf(searchTerm) >= 0;
                        }}
                      >
                        <Select.Option value={SHOW_AT.SET_TIME}>
                          Set Time
                        </Select.Option>
                        <Select.Option value={SHOW_AT.SYSTEM_START_UP}>
                          System Startup
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    {showAt === SHOW_AT.SET_TIME && (
                      <Form.Item
                        className="w-full !mb-2"
                        rules={[{ required: showAt }]}
                        name="showTime"
                        label="Set Time"
                        getValueFromEvent={(e) =>
                          e ? dayjs(e).format("HH:mm:ss") : null
                        }
                        getValueProps={(e) => {
                          const timeValue = e ? dayjs(e, "HH:mm:ss") : null;
                          return {
                            value: timeValue,
                          };
                        }}
                      >
                        <TimePicker className="w-full" format="HH:mm:ss" />
                      </Form.Item>
                    )}
                  </>
                )}

                {frequency === FREQUENCY.WEEKLY && (
                  <>
                    <Form.Item
                      name="days"
                      label="Days"
                      className="w-full !mb-2"
                      rules={[{ required: frequency }]}
                    >
                      <Select
                        allowClear
                        mode="multiple"
                        placeholder="Select days"
                        options={weekDays}
                        tagRender={({ label, value }) => {
                          return <Tag>{label}</Tag>;
                        }}
                        maxTagCount={1}
                        maxTagPlaceholder={(omittedValues) => {
                          const count = omittedValues.length;
                          return `+${count}`;
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="w-full !mb-2"
                      rules={[
                        {
                          required: frequency,
                          message: "Please select the Show At",
                        },
                      ]}
                      name="showAt"
                      label="Show At"
                    >
                      <Select
                        onChange={handleShowAtChange}
                        showSearch
                        filterOption={(input, option) => {
                          const searchTerm = input.toLowerCase();
                          const label = option?.children?.toLowerCase() || "";

                          return label.indexOf(searchTerm) >= 0;
                        }}
                      >
                        <Select.Option value={SHOW_AT.SET_TIME}>
                          Set Time
                        </Select.Option>
                        <Select.Option value={SHOW_AT.SYSTEM_START_UP}>
                          System Startup
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    {showAt === SHOW_AT.SET_TIME && (
                      <Form.Item
                        className="w-full !mb-2"
                        rules={[{ required: showAt }]}
                        name="showTime"
                        label="Set Time"
                        getValueFromEvent={(e) =>
                          e ? dayjs(e).format("HH:mm:ss") : null
                        }
                        getValueProps={(e) => {
                          const timeValue = e ? dayjs(e, "HH:mm:ss") : null;
                          return {
                            value: timeValue,
                          };
                        }}
                      >
                        <TimePicker className="w-full" />
                      </Form.Item>
                    )}
                  </>
                )}

                {frequency === FREQUENCY.MONTHLY && (
                  <>
                    <Form.Item
                      name="days"
                      label="Days"
                      className="w-full !mb-2"
                      rules={[{ required: frequency }]}
                    >
                      <Select
                        allowClear
                        mode="multiple"
                        placeholder="Select days"
                        options={Array.from({ length: 31 }, (_, day) => {
                          const index = day + 1;
                          return {
                            label: index?.toString(),
                            value: index?.toString(),
                          };
                        })}
                        tagRender={({ label, value }) => {
                          return <Tag>{label}</Tag>;
                        }}
                        maxTagCount={4}
                        maxTagPlaceholder={(omittedValues) => {
                          const count = omittedValues.length;
                          return `+${count}`;
                        }}
                        showSearch
                        filterOption={(input, option) => {
                          const searchTerm = input.toLowerCase();
                          const label = option?.children?.toLowerCase() || "";

                          return label.indexOf(searchTerm) >= 0;
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="w-full !mb-2"
                      rules={[
                        {
                          required: frequency,
                          message: "Please select the Show At",
                        },
                      ]}
                      name="showAt"
                      label="Show At"
                    >
                      <Select onChange={handleShowAtChange}>
                        <Select.Option value={SHOW_AT.SET_TIME}>
                          Set Time
                        </Select.Option>
                        <Select.Option value={SHOW_AT.SYSTEM_START_UP}>
                          System Startup
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    {showAt === SHOW_AT.SET_TIME && (
                      <Form.Item
                        className="w-full !mb-2"
                        rules={[{ required: showAt }]}
                        name="showTime"
                        label="Set Time"
                        getValueFromEvent={(e) =>
                          e ? dayjs(e).format("HH:mm:ss") : null
                        }
                        getValueProps={(e) => {
                          const timeValue = e ? dayjs(e, "HH:mm:ss") : null;
                          return {
                            value: timeValue,
                          };
                        }}
                      >
                        <TimePicker className="w-full" />
                      </Form.Item>
                    )}
                  </>
                )}
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
                {" "}
                {/* Rollout Duration */}
                <Form.Item
                  className="w-full !mb-2"
                  getValueFromEvent={(e) => e?.format("YYYY-MM-DD")}
                  getValueProps={(e) => ({ value: e && dayjs(e) })}
                  name="startDate"
                  label="Start Date"
                >
                  <DatePicker
                    className="!border-[#eeeff2] w-full"
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  className="w-full !mb-2"
                  name="end"
                  label="End Condition"
                >
                  <Select
                    onChange={handleEndConditionChange}
                    showSearch
                    filterOption={(input, option) => {
                      const searchTerm = input.toLowerCase();
                      const label = option?.children?.toLowerCase() || "";

                      return label.indexOf(searchTerm) >= 0;
                    }}
                  >
                    <Select.Option value={END_CONDITION.SET_DATE}>
                      Set Date
                    </Select.Option>
                    <Select.Option value={END_CONDITION.AFTER_N_SHOW}>
                      After N Shows
                    </Select.Option>
                    <Select.Option value={END_CONDITION.ENDLESS}>
                      Endless
                    </Select.Option>
                  </Select>
                </Form.Item>
                {endCondition === END_CONDITION.SET_DATE && (
                  <Form.Item
                    className="w-full !mb-2"
                    rules={[
                      { required: endCondition },
                      ({ getFieldValue }) => ({
                        validator: async (_, value) => {
                          try {
                            const startDate = getFieldValue("startDate");
                            const startDateValue = startDate
                              ? dayjs(startDate)
                              : null;
                            const endDateValue = value ? dayjs(value) : null;

                            if (!startDateValue || !endDateValue) {
                              return Promise.resolve();
                            }

                            if (!endDateValue.isAfter(startDateValue)) {
                              return Promise.reject(
                                "Rollout end date and time must be after rollout start date and time!!"
                              );
                            }

                            return Promise.resolve();
                          } catch (error) {
                            return Promise.reject(error.message);
                          }
                        },
                      }),
                    ]}
                    name="endDate"
                    label="End Date"
                    getValueFromEvent={(e) => e?.format("YYYY-MM-DD")}
                    getValueProps={(e) => ({ value: e && dayjs(e) })}
                  >
                    <DatePicker
                      className="w-full"
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                )}
                {endCondition === END_CONDITION.AFTER_N_SHOW && (
                  <Form.Item
                    className="w-full !mb-2"
                    rules={[
                      { required: endCondition },
                      {
                        pattern: /^[1-9]\d*$/,
                        message:
                          "Please input a valid integer without decimal points!",
                      },
                    ]}
                    name="numberOfShow"
                    label="Number of Shows"
                  >
                    <Input type="number" min={1} />
                  </Form.Item>
                )}
                {endCondition === END_CONDITION.ENDLESS && (
                  <Form.Item
                    className="w-full !mb-2"
                    hidden
                    name="end"
                    rules={[{ required: endCondition }]}
                  >
                    <Input type="number" value={null} hidden min={1} />
                  </Form.Item>
                )}
              </div>
            </div>
          </Col>
          {(templateId || id) && (
            <Col className="w-2/3 border p-3 rounded-lg !border-[#eeeff2] h-full overflow-y-scroll">
              <p className="!mb-2 font-semibold text-sm">Content Data</p>
              {templateData.languages.length > 0 && (
                <DynamicForm
                  templateData={templateData}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  onPreviewXaml={onPreviewXaml}
                  currentLanguagePlaceholderData={
                    currentLanguagePlaceholderData
                  }
                  formContentRollout={formContentRollout}
                  allFormValues={allFormValues}
                  setAllFormValues={setAllFormValues}
                />
              )}
            </Col>
          )}
          <Drawer
            width={950}
            closable={false}
            placement="right"
            prefixCls="json-editor-drawer"
            open={open}
            onClose={onClose}
          >
            <div className="w-full p-3 h-full">
              <Form.Item>
                <Form.List
                  name={"targets"}
                  rules={[
                    {
                      required: true,
                      validator: async (_, datas) => {
                        let equationGroup =
                          formContentRollout.getFieldValue("targetCriteria")
                            ?.equationGroup || [];
                        let condition =
                          equationGroup.length > 0 ||
                          (datas && datas.length > 0);

                        if (!condition) {
                          return Promise.reject(new Error(""));
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      <div className="flex custom-form-label align-items-end mb-2 justify-between">
                        <label className="required text-sm font-semibold">
                          Define Targeting Rules
                        </label>
                        <Form.Item className="!mb-0">
                          <Button
                            id="addTargetInRelease"
                            onClick={() => {
                              add();
                            }}
                            block
                            type="primary"
                            className="!border-[#eeeff2] !w-auto !p-2"
                            icon={<PlusOutlined />}
                          >
                            Add Existing Targets
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </div>

                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <div key={key}>
                            <Row>
                              <Col>
                                <Space align="baseline">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "targetId"]}
                                    className="build-target-max-width deco-ant-form-item-no-bottom-margin"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select target",
                                      },
                                    ]}
                                  >
                                    <Select
                                      className="build-target-max-width deco-ant-form-item-no-bottom-margin"
                                      onChange={(data) => {
                                        onSelectTarget(data, name);
                                      }}
                                      showSearch
                                      filterOption={(input, option) => {
                                        const searchTerm = input.toLowerCase();
                                        const label =
                                          option?.children?.toLowerCase() || "";

                                        return label.indexOf(searchTerm) >= 0;
                                      }}
                                    >
                                      {productTargets?.map((build) => {
                                        return (
                                          <Select.Option
                                            key={build.id}
                                            value={build.id}
                                            disabled={selectedTargetIdsArray?.some(
                                              (a) => a.value === build.id
                                            )}
                                          >
                                            {build.name}
                                          </Select.Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    hidden={true}
                                    {...restField}
                                    name={[name, "targetOperator"]}
                                    className="build-target-max-width"
                                    initialValue={
                                      targetOperators &&
                                      targetOperators?.length > 0
                                        ? targetOperators[0].name
                                        : ""
                                    }
                                  >
                                    <Input defaultValue={"AND"} value={"AND"} />
                                  </Form.Item>
                                  <MinusCircleOutlined
                                    onClick={async () => {
                                      await onRemoveTarget(
                                        formContentRollout.getFieldValue([
                                          "targets",
                                          name,
                                          "targetId",
                                        ])
                                      );

                                      remove(name);
                                    }}
                                  />
                                </Space>
                              </Col>
                            </Row>
                            <div
                              style={{ textAlign: "center", maxWidth: "300px" }}
                            >
                              And
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </Form.List>
                <Form.Item
                  name="targetCriteria"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <TargetCriteria
                    initialQuery={editContentRolloutData?.queryJson}
                    TargetCriteriaFinalValue={(data, json) => {
                      TargetCriteriaFinalValue(data, json);
                    }}
                  />
                </Form.Item>
                <Form.Item name={"targetCriteriaJson"} hidden></Form.Item>
                {targetCriteriaErrorMessage && (
                  <div className="common-error">
                    {targetCriteriaErrorMessage}
                  </div>
                )}
              </Form.Item>
            </div>
          </Drawer>
          <Form.Item className="!mb-0 mt-2 top-0 right-4 position-absolute">
            <Space>
              <Button
                id="ContentRolloutBackButton"
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  backToListPage();
                }}
                className="back-button"
              >
                Back
              </Button>
              <Button
                className="submit-button"
                id="ContentRolloutSubmitButton"
                type="primary"
                htmlType="submit"
                disabled={disableSubmitButton}
              >
                Submit
              </Button>
              {id && <InfoDetails infoData={editContentRolloutData} />}
            </Space>
          </Form.Item>
        </Form>
      </>
    </FormLayout>
  );
};

export default AddContentRolloutsComponent;

