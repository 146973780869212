export const DECIMAL_REGEX = /^\d+(\.\d+)?$/;
export const ONLY_ALPHABET_REGEX = /^[a-zA-Z]+$/;
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]+$/;
export const INTEGER_REGEX = /^[0-9]+$/;
export const UNDER_SCORE_REGEX = /^(?=.*_).*$/;
export const NOT_ALLOW_WHITESPACE_REGEX = /^(?!.*\s).*$/;
export const UNDER_SCORE_REGEX_REGEXP =
  /^(?!_)(?!.*__)(?!.*_$)([A-Za-z0-9]+(_[A-Za-z0-9]+)*)?$/;
export const CAPITALIZED_NAME = /([a-z])([A-Z])/g;

