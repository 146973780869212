import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  Popconfirm,
  Popover,
  Select,
  Space,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import ShowDescriptionInListing from "../Common/showDescriptionInListing";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";
import config from "../../Util/config";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import CommonFilterForm from "../Common/commonFilterForm";
import TableLayout from "../Layout/tableLayout";
import AuditModalContainer from "../../Containers/Products/AuditModalContainer";
import { AuditEntityType } from "../../Constant/Common";
import { useSelector } from "react-redux";
import { getSpaceValidationRule } from "../../Util/commonUtility";

const ListProductBuildRollOutComponent = (props) => {
  const [isAuditModal, setIsAuditModal] = useState({
    open: false,
    data: null,
  });
  const {
    productBuildRollout,
    paging,
    handlePageChange,
    deleteProductBuildRollOut,
    editBuildRolloutData,
    listFilterSubmit,
    resetFilter,
    listFilterData,
    copyPatnerDownloadUrl,
    handleTableChange,
    sortField,
    sortOrder,
  } = props;
  const [listFilter] = Form.useForm();
  const isFullAccess = useSelector(
    (state) => state.product.selectedEnvironment?.isFullAccess
  );
  useEffect(() => {
    listFilter.setFieldsValue(listFilterData);
  }, [listFilterData, listFilter]);

  const filterFields = [
    {
      name: "name",
      component: (
        <Input className="list-filter-common-style" placeholder="Name" />
      ),
      rules: [getSpaceValidationRule()],
    },
    {
      name: "description",
      component: (
        <Input className="list-filter-common-style" placeholder="Description" />
      ),
      rules: [getSpaceValidationRule()],
    },
    {
      name: "isEnabled",
      component: (
        <Select
          className="list-filter-common-style select-target-min-width"
          placeholder="Is Enabled?"
        >
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <ShowDescriptionInListing title={text} text={text} />,
    },

    {
      title: "Download Url",
      dataIndex: "downloadUrl",
      key: "downloadUrl",
      render: (downloadUrl, row) => (
        <Popover content={downloadUrl} title="Url">
          <a href={downloadUrl}>
            <Button size="small" icon={<DownloadOutlined />}>
              Download
            </Button>
          </a>
        </Popover>
      ),
    },
    {
      title: "CI/CD Identifier",
      dataIndex: "cdCiIdentifier",
      key: "cdCiIdentifier",
      render: (cdCiIdentifier, row) => cdCiIdentifier,
    },
    {
      title: "Enabled",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (isEnabled, row) => (isEnabled ? "Yes" : "No"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <Space style={{ width: "200px" }}>
            <CopyOutlined
              title="Copy partner download url"
              onClick={() => {
                copyPatnerDownloadUrl(
                  config.apiUrl.replace("/api/", "") + record.partnerDownloadUrl
                );
              }}
            />
            <ShowForRightAccessComponent>
              <Divider type="vertical"></Divider>
              <HistoryOutlined
                id={`auditBuildRollout-${record.id}`}
                title="Audit Logs"
                onClick={() =>
                  setIsAuditModal({
                    open: true,
                    data: {
                      entityType: AuditEntityType.BuildRollout,
                      entityId: record.id,
                      entityName: record.name,
                    },
                  })
                }
              />
            </ShowForRightAccessComponent>
            <ShowForRightAccessComponent>
              <Divider type="vertical"></Divider>
              <EditOutlined
                title="Edit"
                onClick={() => {
                  editBuildRolloutData(record);
                }}
              />
            </ShowForRightAccessComponent>
            <ShowForRightAccessComponent>
              <Divider type="vertical"></Divider>
              <Popconfirm
                title="Are you sure to delete this release ?"
                onConfirm={() => {
                  deleteProductBuildRollOut(record);
                }}
              >
                <DeleteOutlined title="Delete" />
              </Popconfirm>
            </ShowForRightAccessComponent>
          </Space>
        );
      },
    },
  ];

  columns.forEach((column) => {
    if (column.key !== "action") {
      column.sortOrder = sortField === column.dataIndex && sortOrder;
    }
  });
  return (
    <>
      {isAuditModal.open &&
        isAuditModal.data?.entityType &&
        isAuditModal.data?.entityId && (
          <AuditModalContainer
            entityType={isAuditModal.data.entityType}
            entityName={isAuditModal.data.entityName}
            entityId={isAuditModal.data.entityId}
            onClose={() =>
              setIsAuditModal({
                open: false,
                data: null,
              })
            }
          />
        )}
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={listFilterSubmit}
        onReset={resetFilter}
        initialValues={listFilterData}
      />
      <TableLayout>
        <Table
          dataSource={productBuildRollout?.result}
          pagination={{
            showTotal: (total, range) => (
              <TotalReocordsInListingComponent
                totalRecords={paging.totalRecords}
              />
            ),
            pageSize: paging.pageSize,
            total: paging.totalPages * paging.pageSize,
            current: paging.pageIndex,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
          }}
          columns={columns.filter((column) => {
            if (column.title === "Action" && !isFullAccess) {
              return false;
            }
            return true;
          })}
          rowKey={"id"}
          onChange={handleTableChange}
          size="small"
        />
      </TableLayout>
    </>
  );
};

export default ListProductBuildRollOutComponent;

