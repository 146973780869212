import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Row, Col, Form, Input, Space, Switch, Tag, Card } from "antd";
import React, { useEffect, useState } from "react";
import TargetCriteria from "../../Containers/TargetCriteria/TargetCriteria";
import { getSpaceValidationRule, hasInvalidNested } from "../../Util/commonUtility";
import { NOT_ALLOW_WHITESPACE_REGEX } from "../../Constant/regex";
import { maxNameCharacterLength } from "../../Constant/Common";
import { useLocation, useParams } from "react-router-dom";
import FormLayout from "../Layout/formLayout";
import InfoDetails from "../Common/editHistoryInfo";
import TextArea from "antd/es/input/TextArea";

const AddProductTargetComponent = (props) => {
  const { backToListPage, addEditTargetData, onFinish, onFinishFailed } = props;
  const [form] = Form.useForm();
  const { id } = useParams();
  const location = useLocation();
  const targetHeading = location.pathname.includes("add") ? "Add" : "Edit";
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const TargetCriteriaFinalValue = (data, json) => {
    form.setFieldValue("targetCondition", data);
    form.setFieldValue("targetConditionJson", JSON.stringify(json));
    const hasInvalidDeepNested = hasInvalidNested(data);
    setDisableSubmitButton(hasInvalidDeepNested);
  };

  useEffect(() => {
    addEditTargetData && form.setFieldValue(addEditTargetData);
  }, [addEditTargetData]);

  return (
    <FormLayout formTitle={`${targetHeading}  Target`}>
      <>
        <Form
          initialValues={addEditTargetData}
          form={form}
          className="common-form flex flex-row items-start w-full gap-4 h-full"
          name="add-product"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Col className="p-3 border rounded-lg !border-[#eeeff2] flex flex-col space-y-4 w-1/3 flex-grow">
            <Form.Item hidden={true} name="id">
              <Input />
            </Form.Item>
            <Form.Item
              className="w-full !mb-2"
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input name here!",
                },
                {
                  max: maxNameCharacterLength,
                  message: "Input cannot exceed 50 characters!",
                },
                getSpaceValidationRule()
              ]}
            >
              <Input id="targetName" className="!border-[#eeeff2]" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              className="w-full mb-2"
              rules={[
                {
                  required: true,
                  message: "Please input your description!",
                },
                getSpaceValidationRule()
              ]}
            >
              <TextArea
                id="targetDescription"
                className="!border-[#eeeff2] max-h-[100px]"
              />
            </Form.Item>

            <Form.Item
              className="w-full !mb-2"
              label="Enabled?"
              name={"isEnabled"}
              valuePropName="checked"
              prefixCls="enabled-form"
            >
              <Switch id="targetSwitch" />
            </Form.Item>
          </Col>
          <Col className="w-2/3 p-3 border rounded-lg !border-[#eeeff2] h-full overflow-y-scroll">
            <Form.Item
              label={
                <span style={{ fontWeight: 600, fontSize: "14px" }}>
                  Define Targeting Rules
                </span>
              }
              name="targetCondition"
              rules={[
                {
                  required: true,
                  validator: async (_, datas) => {
                    if (
                      !datas?.equationGroup ||
                      datas?.equationGroup?.length < 1
                    ) {
                      return Promise.reject(
                        new Error("Please add target criteria")
                      );
                    }
                  },
                },
              ]}
            >
              <TargetCriteria
                initialQuery={addEditTargetData?.queryJson}
                TargetCriteriaFinalValue={(data, json) => {
                  TargetCriteriaFinalValue(data, json);
                }}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please select your query!",
                },
              ]}
              name={"targetConditionJson"}
            ></Form.Item>
          </Col>

          <Form.Item className="!mb-0 mt-2 top-0 right-4 position-absolute">
            <Space>
              <Button
                id="backToTargetListing"
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  backToListPage();
                }}
              >
                Back
              </Button>
              <Button
                className="submit-btn"
                id="submitTarget"
                type="primary"
                htmlType="submit"
                disabled={disableSubmitButton}
              >
                Submit
              </Button>
              {id && <InfoDetails infoData={addEditTargetData} />}
            </Space>
          </Form.Item>
        </Form>
      </>
    </FormLayout>
  );
};

export default AddProductTargetComponent;

