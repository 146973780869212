const defaultConfigSchema = {
  type: "object",
  required: ["Prop"],
  properties: {
    Prop: {
      type: "string",
    },
  },
};
export const defaultConfig = defaultConfigSchema;

