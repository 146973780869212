import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { useCallback, useEffect, useState } from "react";
import Loader from "../../Components/Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import AddProductComponent from "../../Components/Products/AddProductsComponent";
import NotificationService from "../../Services/notification";

const AddProductsContainer = () => {
  const authState = useSelector((state) => state.auth);
  const [showLoading, setShowLoading] = useState(false);
  const [editProductData, setEditProductData] = useState();
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const submitAddProductForm = async (data, action) => {
    setShowLoading(true);
    data.tenantId = authState?.accountData?.tenantId;
    const hasProductionEnv = data?.environments?.some(
      (env) => env.isProduction
    );

    if (
      action === "Add" &&
      !hasProductionEnv &&
      data?.environments?.length > 0
    ) {
      NotificationService.error(
        "Error",
        "At least one environment must be production."
      );
      setError(true);
      setShowLoading(false);
      return;
    }
    if (data.id) {
      const response = await httpClient.put(
        Urls.TENANT_UPDATE_PRODUCT_BY_ID.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        ).replace("#{productId}", data.id),
        data
      );
      if (isSuccessStatus(response?.status)) {
        backToListPage();
        NotificationService.success("Success", "Product edit successfully");
        // resetEditFormState();
        // fetchAllTenantProducts();
        // SetSearchInput(undefined);
      }
    } else {
      const response = await httpClient.post(
        Urls.TENANT_ADD_PRODUCT.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        ),
        data
      );
      if (isSuccessStatus(response?.status)) {
        backToListPage();
        NotificationService.success("Success", "Product added successfully");
        // resetEditFormState();
        // fetchAllTenantProducts();
        // SetSearchInput(undefined);
      }
    }

    setShowLoading(false);
  };
  const { id } = useParams();
  const backToListPage = useCallback(() => {
    navigate("/products");
  }, [navigate]);

  useEffect(() => {
    const getProductDataById = async (id) => {
      setShowLoading(true);
      try {
        const response = await httpClient.get(
          `${Urls.TENANT_GET_PRODUCT_BY_ID.replace(
            "#{tenantId}",
            authState?.accountData?.tenantId
          ).replace("#{productId}", id)}`
        );

        if (isSuccessStatus(response?.status)) {
          setEditProductData(response?.data);
        } else {
        }
      } catch (error) {
        // Handle any potential error during API call
      } finally {
        setShowLoading(false);
      }
    };
    if (id) {
      getProductDataById(id);
    }
  }, [id, authState, backToListPage, navigate, setShowLoading]);

  const AddEnvInstructions = () => (
    <div className="p-2" style={{ maxWidth: "350px" }}>
      <h5 className="fw-bold mb-2">Important Environment Rules</h5>
      <ul className="list-group">
        <li className="list-group-item !py-1 border-0 ps-0 space-x-1">
          • Once an environment is added,
          <span>only the name can be edited</span>.
          <strong>It cannot be deleted.</strong>
        </li>
        <li className="list-group-item !py-1 border-0 ps-0 space-x-1">
          • Only <strong>one environment</strong> can be marked as
          <strong>Production</strong>.
          <span>
            You must select a production environment when adding a product.
          </span>
        </li>
        <li className="list-group-item !py-1 border-0 ps-0 space-x-1">
          • To edit or add new environments, navigate to the
          <strong className="ml-1">Product Details</strong> page. You will find
          a dedicated
          <strong>Environments</strong> tab listing all associated environments.
        </li>
      </ul>
    </div>
  );

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <AddProductComponent
          onFinish={(data, action) => {
            submitAddProductForm(data, action);
          }}
          editProductData={editProductData}
          backToListPage={() => backToListPage()}
          setError={setError}
          error={error}
          AddEnvInstructions={<AddEnvInstructions />}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      )}
    </>
  );
};
export default AddProductsContainer;
