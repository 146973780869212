import { useEffect, useState } from "react";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { useSelector } from "react-redux";
import { isJson, isNullOrEmpty } from "../../Util/commonUtility";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import NotificationService from "../../Services/notification";
import AddProductFeatureRolloutComponent from "../../Components/FeaturerRollout/addProductFeatureRolloutComponent";
import { DefaultPagingValueForSearchableDropdown } from "../../Constant/Common";
import { SearchAllTargetOperators } from "../../Services/searchService";
import { setAllTargetOperatorsDetails } from "../../Store/Reducers/Slices/LookUp/lookupSlice";
import { useDispatch } from "react-redux";
import { DUPLICATE_FORCE_INSERT } from "../../Constant/HttpStatus";

const AddFeatureRolloutContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const { allTargetOperators } = useSelector((state) => state.lookup);
  const [showLoading, setShowLoading] = useState(false);
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [productTargets, setProductTargets] = useState([]);
  const [targetOperators, setTargetOperators] = useState([]);
  const [addEditProductFeatureRollout, setAddEditProductFeatureRollout] =
    useState();
  const [showDuplicationPopUp, setShowDuplicationPopUp] = useState();
  const [duplicateErrorMessage, setDuplicateErrorMessage] = useState();
  const [canSubmit, setCanSubmit] = useState(true);

  useEffect(() => {
    const getProductFeatureRolloutByFeatureRolloutId = async (id) => {
      setShowLoading(true);
      const response = await httpClient.get(
        `${Urls.TENANT_GET_PRODUCT_FEATURE_ROLLOUT_BY_ID.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{featureRolloutId}", id)
          .replace("#{environmentId}", productState?.selectedEnvironment?.id)}`
      );
      if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
        let feature = await getProductFeatureFlagByFeatureFlagId(
          response.data?.featureId
        );
        response.data.feature = feature;
        if (isJson(response?.data?.targetCriteriaJson)) {
          response.data.queryJson = JSON.parse(
            response.data.targetCriteriaJson
          );
        }
        setAddEditProductFeatureRollout(response.data);
      } else {
        backToListPage();
      }
      setShowLoading(false);
    };
    const getProductFeatureRolloutForCopy = async (state) => {
      setShowLoading(true);
      const response = await httpClient.get(
        `${Urls.TENANT_GET_PRODUCT_FEATURE_ROLLOUT_BY_ID.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{featureRolloutId}", state.id)
          .replace("#{environmentId}", state.environmentId)}`
      );
      if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
        let feature = await getProductFeatureFlagByFeatureFlagId(
          response.data?.featureId
        );
        response.data.feature = feature;
        const Data = {
          ...response.data,
          config: JSON.parse(response.data.config),
          name: state.name,
          id: null,
        };
        setAddEditProductFeatureRollout(Data);
      } else {
        backToListPage();
      }
      setShowLoading(false);
    };
    const fetchAllProductTarget = async () => {
      setShowLoading(true);
      try {
        const response = await httpClient.get(
          `${Urls.TENANT_GET_ALL_PRODUCT_TARGETS.replace(
            "#{tenantId}",
            authState?.accountData?.tenantId
          )
            .replace("#{productId}", productState?.selectedProduct?.id)
            .replace(
              "#{environmentId}",
              productState?.selectedEnvironment?.id
            )}?PageNumber=${
            DefaultPagingValueForSearchableDropdown.PAGE_INDEX
          }&PageSize=${DefaultPagingValueForSearchableDropdown.PAGE_SIZE}`
        );

        if (
          isSuccessStatus(response?.status) &&
          !isNullOrEmpty(response?.data)
        ) {
          setProductTargets(response?.data?.result);
        }
      } catch (error) {
        // Handle error if needed
      } finally {
        setShowLoading(false);
      }
    };
    if (id) {
      getProductFeatureRolloutByFeatureRolloutId(id);
    } else if (state) {
      getProductFeatureRolloutForCopy(state);
    } else {
      setShowLoading(false);
    }
    fetchAllProductTarget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, productState, authState, state]);

  const fetchAllTargetOperators = async () => {
    setShowLoading(true);
    try {
      const response = await SearchAllTargetOperators();
      if (!isNullOrEmpty(response)) {
        dispatch(
          setAllTargetOperatorsDetails({
            allTargetOperators: response?.map((item) => ({
              name: item,
              label: item,
            })),
          })
        );
      }
    } catch (error) {
      // Handle error if needed
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    if (allTargetOperators) {
      setTargetOperators(allTargetOperators);
    } else {
      fetchAllTargetOperators();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTargetOperators]);
  const getProductFeatureFlagByFeatureFlagId = async (id) => {
    const response = await httpClient.get(
      `${Urls.TENANT_GET_PRODUCT_FEATURE_FLAG_BY_ID.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{featureFlagId}", id)}`
    );
    if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
      return response.data;
    } else {
      return null;
    }
  };
  const addProductFeatureRolloutAPI = async (data) => {
    if (!canSubmit) {
      NotificationService.error(
        "Please resolve all the validation errors in the generated form before submitting"
      );
      return; // Prevent submission as validation occurs in the generated form
    }

    setShowLoading(true);

    try {
      const url = data.id
        ? Urls.TENANT_EDIT_PRODUCT_FEATURE_ROLLOUT.replace(
            "#{tenantId}",
            authState?.accountData?.tenantId
          )
            .replace("#{productId}", productState?.selectedProduct?.id)
            .replace("#{environmentId}", productState?.selectedEnvironment?.id)
            .replace("#{featureRolloutId}", data.id)
        : Urls.TENANT_ADD_PRODUCT_FEATURE_ROLLOUT.replace(
            "#{tenantId}",
            authState?.accountData?.tenantId
          )
            .replace("#{productId}", productState?.selectedProduct?.id)
            .replace("#{environmentId}", productState?.selectedEnvironment?.id);

      const response = data.id
        ? await httpClient.put(url, data)
        : await httpClient.post(url, data);

      if (isSuccessStatus(response?.status)) {
        backToListPage();
        NotificationService.success(
          "Success",
          data.id
            ? "Feature Rollout edited successfully"
            : "Feature Rollout added successfully"
        );
      }
    } catch (error) {
      handleError(error, data);
    } finally {
      setShowLoading(false);
    }
  };

  const handleError = (error, data) => {
    setAddEditProductFeatureRollout(data);
    const code = error?.response?.data?.errors?.[0]?.code;
    if (
      error?.response?.data?.errors?.length > 0 &&
      code === DUPLICATE_FORCE_INSERT
    ) {
      setDuplicateErrorMessage(error.response.data.errors[0]?.message);
      setShowDuplicationPopUp(true);
    }
  };
  const backToListPage = () => {
    navigate("/product/featureRollout");
  };

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <AddProductFeatureRolloutComponent
          onFinish={(data) => addProductFeatureRolloutAPI(data)}
          editProductFeatureRolloutData={addEditProductFeatureRollout}
          productTargets={productTargets?.filter((target) => target.isEnabled)}
          targetOperators={targetOperators}
          id={id}
          backToListPage={() => {
            backToListPage();
          }}
          setCanSubmit={setCanSubmit}
          showDuplicationPopUp={showDuplicationPopUp}
          setShowDuplicationPopUp={setShowDuplicationPopUp}
          duplicateErrorMessage={duplicateErrorMessage}
        />
      )}
    </>
  );
};
export default AddFeatureRolloutContainer;
