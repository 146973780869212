import { useEffect, useState } from "react";
import Loader from "../../Components/Common/Loader";
import ListProductBuildComponent from "../../Components/Build/listProductBuildComponent";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../Hooks/useFetchData";
import Urls from "../../Constant/Urls";
import {
  convertDataToQueryParam,
  getSortingOrder,
} from "../../Util/commonUtility";
import { DefaultPagingValue, DefaultSortingValue } from "../../Constant/Common";

const ListBuildContainer = () => {
  const [listFilterData, setListFilterData] = useState({});
  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE || 10,
    pageIndex: DefaultPagingValue.PAGE_INDEX || 1,
    totalPages: 0,
    totalRecords: 0,
  });
  const [sortOrder, setSortOrder] = useState(DefaultSortingValue.Sort_Order);
  const [sortField, setSortField] = useState(DefaultSortingValue.Sort_By);
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);

  const handleTableChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    setSortField(field);
    setSortOrder(order);

    if (order === undefined || !order) {
      setSortField(DefaultSortingValue.Sort_By);
      setSortOrder(DefaultSortingValue.Sort_Order);
    }
    setPagination((prev) => ({
      ...prev,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  const handlePageChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize: pageSize,
    }));

    setSortField(DefaultSortingValue.Sort_By);
    setSortOrder(DefaultSortingValue.Sort_Order);
  };

  // Fetch data using useFetchData
  const {
    data: productBuilds,
    loading,
    pagination: fetchedPagination,
  } = useFetchData(
    `${Urls.TENANT_GET_ALL_PRODUCT_BUILDS.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    )
      .replace("#{productId}", productState?.selectedProduct?.id)
      .replace(
        "#{environmentId}",
        productState?.selectedEnvironment?.id
      )}?${convertDataToQueryParam(listFilterData)}&PageNumber=${
      pagination.pageIndex
    }&PageSize=${
      pagination.pageSize
    }&sortBy=${sortField}&OrderByDirection=${getSortingOrder(sortOrder)}&`,
    [
      pagination.pageIndex,
      pagination.pageSize,
      listFilterData,
      productState.selectedProduct?.id,
      productState.selectedEnvironment?.id,
      sortField,
      sortOrder,
    ]
  );

  // Update local pagination state when fetchedPagination changes
  useEffect(() => {
    if (fetchedPagination) {
      setPagination(fetchedPagination);
    }
  }, [fetchedPagination]);

  const editProductBuild = (data) => {
    navigate(`/product/build/edit/${data.id}`);
  };

  const listFilterSubmit = (data) => {
    setListFilterData(data);
    setPagination((prev) => ({ ...prev, pageIndex: 1 }));
  };

  const resetFilter = () => {
    setListFilterData({});
    setPagination((prev) => ({ ...prev, pageIndex: 1 }));
  };

  return loading ? (
    <Loader />
  ) : (
    <ListProductBuildComponent
      productBuilds={productBuilds}
      editProductBuild={editProductBuild}
      paging={pagination}
      handlePageChange={handlePageChange}
      listFilterSubmit={listFilterSubmit}
      listFilterData={listFilterData}
      resetFilter={resetFilter}
      handleTableChange={handleTableChange}
      sortOrder={sortOrder}
      sortField={sortField}
    />
  );
};

export default ListBuildContainer;
