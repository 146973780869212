import React, { useEffect, useState } from "react";
import Loader from "../../Components/Common/Loader";
import Urls from "../../Constant/Urls";
import { useSelector } from "react-redux";
import { DefaultPagingValue } from "../../Constant/Common";
import useFetchData from "../../Hooks/useFetchData";
import AuditModal from "../../Components/Products/ProductAudit/AuditModal";

const AuditModalContainer = ({ entityType, entityId, onClose, entityName }) => {
  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE || 10,
    pageIndex: DefaultPagingValue.PAGE_INDEX || 1,
    totalPages: 0,
    totalRecords: 0,
  });
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);

  const {
    data: productAuditData,
    loading,
    pagination: fetchedPagination,
  } = useFetchData(
    `${Urls.TENANT_GET_PRODUCT_AUDIT.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    )
      .replace("#{productId}", productState?.selectedProduct?.id)
      .replace("#{environmentId}", productState?.selectedEnvironment?.id)
      .replace("#{entityType}", entityType)
      .replace("#{entityId}", entityId)}?PageNumber=${
      pagination.pageIndex
    }&PageSize=${pagination.pageSize}&OrderByDirection=Desc&SortBy=createdAt`,

    [
      authState?.accountData?.tenantId,
      entityId,
      entityType,
      pagination.pageIndex,
      pagination.pageSize,
      productState?.selectedEnvironment?.id,
      productState?.selectedProduct?.id,
    ]
  );

  useEffect(() => {
    if (fetchedPagination) {
      setPagination(fetchedPagination);
    }
  }, [fetchedPagination]);

  const handlePageChange = (nextPage, pageSize) => {
    setPagination((prevPaging) => ({
      ...prevPaging,
      pageSize: pageSize,
      pageIndex: nextPage,
    }));
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <AuditModal
          productAuditData={productAuditData}
          paging={pagination}
          handlePageChange={handlePageChange}
          entityType={entityType}
          entityName={entityName}
          onClose={onClose}
        />
      )}
    </React.Fragment>
  );
};

export default AuditModalContainer;

