import { Button, Form, Input, Space } from "antd";
import Loader from "../Common/Loader"

import {
  PHONE_NUMBER_REGEX,
} from "../../Constant/Common";
import dayjs from "dayjs";
import { getSpaceValidationRule } from "../../Util/commonUtility";

const ProfileForm = (props) => {
  const {
    onFinishFailed,
    onFinish,
    formObject,
    loading,
    initialValuesObject,
    backToListPage,
  } = props;

  return (
    <>
      {initialValuesObject ? (
        <Form
          className="common-form"
          form={formObject}
          initialValues={{
            ...initialValuesObject,
            userName: initialValuesObject.name,
            phoneNumber: initialValuesObject.phone,
            birthDate: dayjs(initialValuesObject.birthDate),
          }}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        // autoComplete="off"
        >
          <Form.Item name="email" label="E-mail">
            <Input id="profileEmail" disabled={true} bordered={false} />
          </Form.Item>

          <Form.Item
            name="userName"
            label="Username"
            tooltip="What do you want others to call you?"
            rules={[
              {
                required: true,
                message: "Please enter your username!",
              },
               getSpaceValidationRule()
            ]}
          >
            <Input id="profileUserName" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            className="!mb-2"
            rules={[
              {
                required: false,
                message: "Please enter your Phone Number!",
              },
              {
                pattern: PHONE_NUMBER_REGEX,
                message: "Must be a valid phone number with 10 to 12 digits",
              },
            ]}
          >
            <Input prefixCls="mask-input" id="profilePhoneNumber" maxLength={12} />
          </Form.Item>
          <Form.Item
            className="!mb-0 flex justify-end "
          >
            <Space>
              <Button
                id="backToProfile"
                type="default"
                onClick={() => {
                  backToListPage();
                }}
              >
                Close
              </Button>
              <Button loading={loading} className="submit-btn" id="submitProfile" type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default ProfileForm;
