import React, { useState } from "react";
import { Button, Modal, Popover, Table, Tag, Tooltip } from "antd";
import TotalRecordsInListingComponent from "../../Common/totalRecordsInListing";
import {
  CloseOutlined,
  CompressOutlined,
  ExpandOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  AuditEntityType,
  defaultHeight,
  defaultWidth,
  fullScreenHeight,
  fullScreenWidth,
} from "../../../Constant/Common";
import { CAPITALIZED_NAME } from "../../../Constant/regex";
import NotificationService from "../../../Services/notification";
import dayjs from "dayjs";
import Diff from "./CompareAuditData";

const FormButton = ({ buttonTitle, onClick, extra }) => (
  <div className="flex gap-2">
    <Button onClick={onClick} type="primary">
      {buttonTitle}
    </Button>
    {extra && extra()}
  </div>
);

const AuditModal = ({
  productAuditData,
  paging,
  handlePageChange,
  entityType,
  entityName = "",
  onClose,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [selectedRowData, setSelectedRowData] = React.useState([]);
  const [auditData, setAuditData] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const modalWidth = isFullScreen ? fullScreenWidth : defaultWidth;
  const modalHeight = isFullScreen ? fullScreenHeight : defaultHeight;
  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);

  const columns = React.useMemo(
    () => [
      {
        title: "Operation Type",
        dataIndex: "operationType",
        key: "operationType",
        render: (operationType) => <Tag>{operationType}</Tag>,
      },
      {
        title: "User Email",
        dataIndex: "userEmail",
        key: "userEmail",
      },
      {
        title: "Created Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => dayjs(text).format(`MMM D, YYYY`),
      },
    ],
    []
  );

  const isTwoRowsSelected = selectedRowKeys.length === 2;

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, newSelectedRows) => {
      if (newSelectedRows.length > 2) {
        NotificationService.error("Only two selections are allowed");
        return;
      }
      setSelectedRowKeys(newSelectedRowKeys.slice(0, 2));
      setSelectedRowData(newSelectedRows);
    },
  };

  const formatAuditData = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    const auditData = data.map(({ auditData, userEmail }) => ({
      data: JSON.parse(auditData),
      created_by: userEmail,
    }));

    auditData.sort(
      (a, b) => new Date(a.data.UpdatedAt) - new Date(b.data.UpdatedAt)
    );
    switch (entityType) {
      case AuditEntityType.Target: {
        const targetData = auditData.map((item) => ({
          ...item,
          data: {
            ...item.data,
            TargetConditionJson: JSON.parse(item.data.TargetConditionJson),
          },
        }));
        return targetData;
      }
      case AuditEntityType.BuildRollout: {
        const targetData = auditData.map((item) => ({
          ...item,
          data: {
            ...item.data,
            TargetCriteriaJson: JSON.parse(item.data.TargetCriteriaJson),
          },
        }));
        return targetData;
      }

      default:
        return auditData;
    }
  };

  const handleCompare = () => {
    if (isTwoRowsSelected) {
      const formattedData = formatAuditData(selectedRowData);
      setAuditData(formattedData);
    } else {
      NotificationService.error(
        "You need to select exactly two rows at a time to compare."
      );
    }
  };

  const handleBackToListing = () => {
    setAuditData([]);
    setSelectedRowKeys([]);
    setSelectedRowData([]);
  };

  const compareInfoItem = () => (
    <Popover
      content={
        <div style={{ maxWidth: "300px" }}>
          <p className="text-muted small">
            ➤ You need to select exactly two rows at a time to compare.
          </p>
          <p className="text-muted small">
            ➤ If you select a row with the operation type set to "delete," the
            comparison data will be empty.
          </p>
        </div>
      }
      trigger="click"
    >
      <Button title="Compare logs info">
        <InfoCircleOutlined className="text-[18px] cursor-pointer" />
      </Button>
    </Popover>
  );

  const title = entityType.replace(CAPITALIZED_NAME, "$1 $2");
  const renderActions = () => (
    <div className="editor-preview-actions">
      <FormButton
        onClick={auditData.length > 0 ? handleBackToListing : handleCompare}
        buttonTitle={auditData.length > 0 ? "Back to Logs" : "Compare"}
        extra={auditData.length === 0 ? compareInfoItem : null}
      />
      <Tooltip title={isFullScreen ? "Exit full screen" : "Full screen"}>
        <Button
          id="editor-full-screen-toggle-button"
          icon={isFullScreen ? <CompressOutlined /> : <ExpandOutlined />}
          onClick={toggleFullScreen}
        />
      </Tooltip>
      <Tooltip title={"Close"}>
        <Button
          id="editor-full-screen-toggle-button"
          icon={<CloseOutlined />}
          onClick={onClose}
        />
      </Tooltip>
    </div>
  );

  return (
    <Modal
      title={
        <div className="editor-preview-modal-header">
          <span>
            {auditData.length > 0
              ? `Compare Logs : ${title} (${entityName?.trim()})`
              : `Audit Logs : ${title} (${entityName?.trim()})`}
          </span>
          {renderActions()}
        </div>
      }
      open={true}
      styles={{
        body: { height: modalHeight },
      }}
      width={modalWidth}
      className="editor-preview-modal-container"
      centered
      closeIcon={false}
      footer={[
        <Button
          key="close"
          className="editor-modal-close-button mt-3"
          id="editor-preview-close-button"
          onClick={onClose}
        >
          Close
        </Button>,
      ]}
    >
      {auditData.length === 0 ? (
        <div
          className={`border table-layout-container p-[8px] min-h-full  !border-[#eeeff2] bg-white rounded-lg h-full overflow-scroll`}
        >
          <Table
            className="featureFlag-listing"
            dataSource={productAuditData?.result}
            pagination={{
              showTotal: () => (
                <TotalRecordsInListingComponent
                  totalRecords={paging.totalRecords}
                />
              ),
              pageSize: paging.pageSize,
              total: paging.totalPages * paging.pageSize,
              current: paging.pageIndex,
              onChange: handlePageChange,
            }}
            columns={columns}
            rowKey="id"
            size="small"
            rowSelection={{
              type: "checkbox",
              hideSelectAll: true,
              ...rowSelection,
            }}
          />
        </div>
      ) : (
        <Diff data={auditData} />
      )}
    </Modal>
  );
};

export default AuditModal;

