import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Tag,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  ArrowLeftOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  THEME_KEY,
  getLocalStorage,
  getSpaceValidationRule,
  removeWhitespaceFromXAML,
  replaceContentWithPlaceholders,
  validateCodeSyntax,
} from "../../Util/commonUtility";
import { Option } from "antd/es/mentions";
import MonacoEditor from "../Common/monocoEditor";
import {
  ContentTypeOptions,
  ContentTypes,
  EditorDefaultOptions,
  LocalStorageEnums,
  maxNameCharacterLength,
  vsCodeTheme,
} from "../../Constant/Common";
import NotificationService from "../../Services/notification";
import EditorPreview from "../ContentRollouts/ModalComponent/ModalComponent";
import { useLocation, useParams } from "react-router-dom";
import FormLayout from "../Layout/formLayout";
import InfoDetails from "../Common/editHistoryInfo";
import InfoPopover from "../Common/infoPopover";
import AddContentPlaceholder from "./PlaceHolder";

const AddContentTemplatesComponent = (props) => {
  const {
    backToListPage,
    onFinish,
    editContentTemplateData,
    languageItems,
    onPreviewXaml,
  } = props;
  const location = useLocation();
  const { id } = useParams();
  const [formContentTemplate] = Form.useForm();
  const initialPlaceholderValue =
    getLocalStorage(LocalStorageEnums.allPlaceholderData) || [];
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [allPlaceHolderData, setAllPlaceHolderData] = useState(
    initialPlaceholderValue
  );
  const [placeHolderData, setPlaceHolderData] = useState(null);
  const [selectedTemplateType, setSelectedTemplateType] = useState(false);
  const [replacedContent, setReplacedContent] = useState(false);
  const [templateSchemaContent, setTemplateSchemaContent] = useState(false);
  const [isEditorCodeValid, setIsEditorCodeValid] = useState(false);
  const [isEditorCodeSyntaxError, setIsEditorCodeSyntaxError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isTemplateSchemaNonEmpty = templateSchemaContent?.length > 0;
  const isPreviewVisible =
    isEditorCodeSyntaxError && isTemplateSchemaNonEmpty && isEditorCodeValid;
  const templateHeading = location.pathname.includes("add") ? "Add" : "Edit";

  const isDarkMode = getLocalStorage(THEME_KEY) || false;

  const handleEditorChange = (value) => {
    setTemplateSchemaContent(value);
  };

  const showPlaceholderDrawer = () => {
    setOpen(true);
    setIsEdit(false);
  };

  const handleOpen = async () => {
    if (selectedTemplateType === ContentTypes.XAML) {
      onPreviewXaml(removeWhitespaceFromXAML(replacedContent));
    } else {
      setIsModalVisible(true);
    }
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleTemplateTypeChange = (value) => {
    setSelectedTemplateType(value);
  };

  const handleTemplateValidation = (
    templateValidation,
    isEditorCodeValid,
    formattedData
  ) => {
    if (!templateValidation.isValid) {
      NotificationService.error(templateValidation.message);
      return;
    }
    if (!isEditorCodeValid) {
      NotificationService.error(
        `The placeholder used in the template does not exist in the placeholders table`
      );
      return;
    }
    onFinish(formattedData);
  };

  const handleSubmit = (values) => {
    if (!replacedContent || !selectedTemplateType) {
      NotificationService.error(
        `Template Configuration Missing: No Placeholder Configuration or Content Template data Found.`
      );
      return;
    }
    const selectedLanguageObjects = values.languages?.map((code) => {
      const lang = languageItems.find((lang) => lang.code === code);
      return lang;
    });
    const templateValidation = validateCodeSyntax(
      replacedContent,
      selectedTemplateType
    );
    const placeholderPattern = /{{(.*?)}}/g;
    const extractedTemplatePlaceholder = [
      ...templateSchemaContent.matchAll(placeholderPattern),
    ].map((match) => match[1]);
    const placeHolderUsedInTemplateSchema = allPlaceHolderData.filter((item) =>
      extractedTemplatePlaceholder.includes(item.name)
    );

    const formattedData = {
      ...values,
      templateSchema: templateSchemaContent,
      templateType: selectedTemplateType,
      languages: selectedLanguageObjects,
      templatePlaceholder: placeHolderUsedInTemplateSchema || [],
    };

    handleTemplateValidation(
      templateValidation,
      isEditorCodeValid,
      formattedData
    );
  };

  useEffect(() => {
    if (editContentTemplateData) {
      const {
        templateType,
        templateSchema,
        templatePlaceholder,
        languages,
        ...restData
      } = editContentTemplateData;
      setSelectedTemplateType(templateType);
      setTemplateSchemaContent(templateSchema);
      setAllPlaceHolderData(templatePlaceholder);
      formContentTemplate.setFieldsValue({
        ...restData,
        templateSchema: templateSchema,
        templatePlaceholder: templatePlaceholder || [],
        languages: languages.map((item) => {
          return item?.code;
        }),
      });
    }
  }, [editContentTemplateData, formContentTemplate]);

  useEffect(() => {
    if (templateSchemaContent || allPlaceHolderData) {
      const { replacedContent, isValid } = replaceContentWithPlaceholders(
        templateSchemaContent,
        allPlaceHolderData
      );
      const { isValid: isCodeValid } = validateCodeSyntax(
        replacedContent,
        selectedTemplateType
      );
      setReplacedContent(replacedContent);
      setIsEditorCodeValid(isValid);
      setIsEditorCodeSyntaxError(isCodeValid);
    }
  }, [templateSchemaContent, allPlaceHolderData, selectedTemplateType]);

  const HelpContent = () => (
    <div className="d-flex flex-column">
      <div className="alert alert-info d-flex align-items-start">
        <InfoCircleOutlined className="me-2 mt-1" />
        <div>
          <p className="mb-0">
            To use a placeholder within the content template, enclose the
            placeholder name in double curly braces.
          </p>
          <p className="mb-0">
            {`For example, to use the 'New_Placeholder', format it as 
            {{New_Placeholder}}`}
          </p>
        </div>
      </div>

      <div className="alert alert-warning d-flex mb-0 align-items-start">
        <InfoCircleOutlined className="me-2 mt-1" />
        <div>
          <p className="mb-0">
            Any placeholders that are not utilized in the template will be lost
            upon form submission!
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <FormLayout formTitle={`${templateHeading} Content Template`}>
      <>
        <Form
          initialValues={editContentTemplateData}
          form={formContentTemplate}
          className="form-container"
          name="add-product"
          onFinish={handleSubmit}
          autoComplete="off"
          layout="vertical"
        >
          {/* Top row with updated by/updated at tags */}

          <Row gutter={28}>
            <Col sm={24} lg={24} xl={8} xxl={8}>
              <Form.Item
                className="w-full !mb-2"
                hidden={true}
                name="id"
                id="ContentTemplateId"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                className="w-full !mb-2"
                rules={[
                  { required: true, message: "Please input template name!" },
                  {
                    max: maxNameCharacterLength,
                    message: "Input cannot exceed 50 characters!",
                  },
                  getSpaceValidationRule()
                ]}
              >
                <Input className="!border-[#eeeff2]" id="ContentTemplateName" />
              </Form.Item>

              <Form.Item
                label="Template Type"
                name="templateType"
                className="w-full !mb-2"
                rules={[
                  { required: true, message: "Please select a template type!" },
                ]}
              >
                <Select
                  defaultValue={selectedTemplateType}
                  placeholder="Select Content Type"
                  onChange={handleTemplateTypeChange}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    const searchTerm = input.toLowerCase();
                    const label = option?.children?.toLowerCase() || "";

                    return label.indexOf(searchTerm) >= 0;
                  }}
                >
                  {ContentTypeOptions.map(({ label, value }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                className="w-full !mb-2"
                label="Languages"
                name="languages"
              >
                <Select
                  allowClear
                  mode="multiple"
                  placeholder="Select Languages"
                  tagRender={({ label }) => <Tag>{label}</Tag>}
                  maxTagCount={2}
                  maxTagPlaceholder={(omittedValues) =>
                    `+${omittedValues.length}`
                  }
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    const searchTerm = input.toLowerCase();
                    const label = option?.children?.toLowerCase() || "";

                    return label.indexOf(searchTerm) >= 0;
                  }}
                >
                  {languageItems?.map((lang) => (
                    <Option key={lang.code} value={lang.code}>
                      {lang.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                className="w-full !mb-2"
                rules={[
                  {
                    required: true,
                    message: "Please input template description!",
                  },
                  getSpaceValidationRule()
                ]}
              >
                <TextArea
                  className="!border-[#eeeff2]"
                  id="ContentTemplateDescription"
                />
              </Form.Item>

              <div className="flex justify-between items-start">
                <Form.Item
                  label="Enabled?"
                  className="w-full !mb-2"
                  name="isEnable"
                  initialValue={false}
                  valuePropName="checked"
                  prefixCls="enabled-form"
                >
                  <Switch id="ContentTemplateSwitch" />
                </Form.Item>
                <Button
                  type="primary"
                  className="button-add-placeHolder"
                  onClick={showPlaceholderDrawer}
                >
                  Configure Placeholders
                </Button>
              </div>
            </Col>
            <Col sm={24} lg={24} xl={16} xxl={16}>
              <Space direction="vertical" className="w-full">
                <>
                  <Card className="h-full" prefixCls="monaco-editor-card">
                    <div className="editor-container">
                      <div className="editor">
                        <div className="justify-button-and-center">
                          <h1>Template Editor</h1>
                          <InfoPopover
                            iconColor={"black"}
                            titleText={"Click for Template Editor Instructions"}
                            className={"text-black"}
                            helpItems={<HelpContent />}
                          />
                        </div>
                        <EditorPreview
                          visible={isModalVisible}
                          onClose={handleClose}
                          content={replacedContent}
                          placeholder={allPlaceHolderData}
                          language={selectedTemplateType}
                        />
                        <MonacoEditor
                          height="100%"
                          language={selectedTemplateType}
                          theme={
                            isDarkMode ? vsCodeTheme.dark : vsCodeTheme.light
                          }
                          value={templateSchemaContent}
                          allPlaceHolderData={allPlaceHolderData}
                          onChange={handleEditorChange}
                          options={EditorDefaultOptions}
                          setOpen={setOpen}
                          setPlaceHolderData={setPlaceHolderData}
                        />
                      </div>
                    </div>
                  </Card>
                </>
              </Space>
            </Col>
          </Row>
          <Form.Item className="!mb-0 mt-2 top-0 right-4 position-absolute">
            <Space>
              <Button
                id="ContentTemplateBackButton"
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={backToListPage}
                className="back-button"
              >
                Back
              </Button>
              <Button
                className="submit-button"
                id="ContentTemplateSubmitButton"
                type="primary"
                htmlType="submit"
              >
                {editContentTemplateData ? "Save" : "Submit"}
              </Button>
              <Button
                disabled={!isPreviewVisible}
                type="primary"
                onClick={handleOpen}
              >
                <EyeOutlined />
                Preview {selectedTemplateType}
              </Button>
              {id && <InfoDetails infoData={editContentTemplateData} />}{" "}
            </Space>
          </Form.Item>
          {open && (
            <AddContentPlaceholder
              setAllPlaceHolderData={setAllPlaceHolderData}
              placeHolderData={placeHolderData}
              setPlaceHolderData={setPlaceHolderData}
              allPlaceHolderData={allPlaceHolderData}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              open={open}
              setOpen={setOpen}
              templateSchemaContent={templateSchemaContent}
            />
          )}
        </Form>
      </>
    </FormLayout>
  );
};

export default AddContentTemplatesComponent;
