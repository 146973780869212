import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import ListTargetAttributes from "../../Components/TargetAttributes/ListTargetAttributes";
import NotificationService from "../../Services/notification";
import useFetchData from "../../Hooks/useFetchData";
import Urls from "../../Constant/Urls";
import {
  convertDataToQueryParam,
  getSortingOrder,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import { DefaultPagingValue, DefaultSortingValue } from "../../Constant/Common";
import { httpClient, isSuccessStatus } from "../../Api/client";

const TargetAttributesContainer = React.forwardRef((props, ref) => {
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [listFilterData, setListFilterData] = useState({});
  const [sortField, setSortField] = useState(DefaultSortingValue.Sort_By);
  const [sortOrder, setSortOrder] = useState(DefaultSortingValue.Sort_Order);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE || 10,
    pageIndex: DefaultPagingValue.PAGE_INDEX || 1,
    totalPages: 0,
    totalRecords: 0,
  });

  // Fetch data using useFetchData
  const {
    data: productTargetAttributes,
    loading: isLoading,
    pagination: fetchedPagination,
  } = useFetchData(
    `${Urls.TENANT_GET_ALL_PRODUCT_TARGET_ATTRIBUTES.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    ).replace(
      "#{productId}",
      productState?.selectedProduct?.id
    )}?${convertDataToQueryParam(listFilterData)}&PageNumber=${
      pagination.pageIndex
    }&PageSize=${
      pagination.pageSize
    }&sortBy=${sortField}&OrderByDirection=${getSortingOrder(sortOrder)}`,
    [
      pagination.pageIndex,
      pagination.pageSize,
      listFilterData,
      productState.selectedProduct?.id,
      productState.selectedEnvironment?.id,
      sortField,
      sortOrder,
    ]
  );

  // Update local pagination state when fetchedPagination changes
  useEffect(() => {
    if (fetchedPagination) {
      setPagination(fetchedPagination);
    }
  }, [fetchedPagination]);

  const deleteTargetAttribute = async (data) => {
    try {
      const response = await httpClient.delete(
        `${Urls.TENANT_DELETE_PRODUCT_TARGET_ATTRIBUTES.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{targetAttributeId}", data.id)}`
      );
      if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
        NotificationService.success(
          "Success",
          "Target Attribute deleted successfully"
        );
        setListFilterData({}); // Reset filter to reflect changes
      }
    } catch (error) {
      NotificationService.error(
        "Error",
        "An error occurred while deleting the target attribute"
      );
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    setSortField(field);
    setSortOrder(order);

    if (order === undefined || !order) {
      setSortField(DefaultSortingValue.Sort_By);
      setSortOrder(DefaultSortingValue.Sort_Order);
    }
    setPagination((prev) => ({
      ...prev,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  const handlePageChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize: pageSize,
    }));

    setSortField(DefaultSortingValue.Sort_By);
    setSortOrder(DefaultSortingValue.Sort_Order);
  };

  const editTargetAttributes = (data) => {
    navigate(`/product/targetattributes/edit/${data.id}`);
  };

  const listFilterSubmit = (data) => {
    setListFilterData(data);
    setPagination((prev) => ({ ...prev, pageIndex: 1 }));
  };

  const resetFilter = () => {
    setListFilterData({});
    setPagination((prev) => ({ ...prev, pageIndex: 1 }));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <ListTargetAttributes
          dataSource={productTargetAttributes || []}
          listFilterData={listFilterData}
          paging={pagination}
          editTargetAttribute={editTargetAttributes}
          deleteTargetAttributes={deleteTargetAttribute}
          handlePageChange={handlePageChange}
          listFilterSubmit={listFilterSubmit}
          resetFilter={resetFilter}
          handleTableChange={handleTableChange}
          sortField={sortField}
          sortOrder={sortOrder}
        />
      )}
    </>
  );
});

export default TargetAttributesContainer;
