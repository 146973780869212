import { ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import Loader from "../Common/Loader";
import FormLayout from "../Layout/formLayout";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Role } from "../../Constant/Common";
import InfoDetails from "../Common/editHistoryInfo";
const { Option } = Select;

const AddProductUserComponent = (props) => {
  const {
    allUser,
    onFinish,
    onFinishFailed,
    backToListPage,
    editProductUsersData,
    allProductEnvironmentsData,
  } = props;

  const location = useLocation();
  const [initialFormValues, setInitialFormValues] = useState(false);
  const [addProductUserForm] = Form.useForm();
  const [switchEnabled, setSwitchEnabled] = useState({});
  const [selectedUserRole, setSelectedUserRole] = useState();
  const productState = useSelector((state) => state.product);
  const existingEnvironments = productState?.selectedProduct?.environments;
  const isProductionEnvironment = existingEnvironments?.find(
    (env) => env.isProduction === true
  );
  const featureRolloutHeading = location.pathname.includes("add")
    ? "Add"
    : "Edit";

  useEffect(() => {
    if (allProductEnvironmentsData) {
      const combinedArray = editProductUsersData
        ? [...editProductUsersData]
        : [];

      for (const obj of allProductEnvironmentsData.result) {
        if (!combinedArray.some((item) => item.environmentId === obj.id)) {
          obj.environmentId = obj.id;
          obj.environmentName = obj.name;
          combinedArray.push(obj);
        }
      }

      const initialEnvironmentValues = combinedArray.map((env) => ({
        ...env,
        environment: env.isFullAccess ? env.isFullAccess : !!env.productId,
      }));

      setInitialFormValues({
        accountId: editProductUsersData?.[0]?.accountId,
        environment: initialEnvironmentValues,
      });

      addProductUserForm.setFieldsValue({
        accountId: editProductUsersData?.[0]?.accountId,
        environment: initialEnvironmentValues,
      });

      // Set initial state for enabling/disabling switches
      const switchState = {};
      initialEnvironmentValues.forEach((env, index) => {
        switchState[index] = env.environment;
      });
      setSwitchEnabled(switchState);
    }
  }, [allProductEnvironmentsData, editProductUsersData, addProductUserForm]);

  const onEnvironmentCheck = (event, index) => {
    const checked = event.target.checked;

    const environmentValues = addProductUserForm.getFieldValue("environment");

    environmentValues[index].environment = checked;
    addProductUserForm.setFieldsValue({ environment: environmentValues });

    setSwitchEnabled((prevState) => ({
      ...prevState,
      [index]: checked,
    }));
  };

  const handleFilterOption = (input, option) => {
    const children = option?.props?.children;
    if (Array.isArray(children)) {
      const childrenString = children.join("");
      return childrenString.toLowerCase().includes(input?.toLowerCase());
    }
    if (typeof children === "string") {
      return children.toLowerCase().includes(input?.toLowerCase());
    }
    return false;
  };

  const onSubmit = (values) => {
    const selectedCheckboxes =
      values.environment?.filter((env) => env.environment) || [];
    let environmentCheckError;
    if (selectedCheckboxes.length === 0) {
      environmentCheckError =
        "At least one environment for read access must be selected";
    } else {
      environmentCheckError = "";
    }
    onFinish(
      values,
      editProductUsersData ? "Edit" : "Add",
      environmentCheckError
    );
  };
  const handleUserSelectChange = (userValue) => {
    const selectedUserDetails = allUser?.find((item) => item.id === userValue);
    const userRole = selectedUserDetails?.roles[0] || null;
    setSelectedUserRole(userRole);
    if (userRole === Role.ADMIN) {
      const updatedObject = Object.keys(switchEnabled).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
      setSwitchEnabled(updatedObject);
      const environmentValue = addProductUserForm.getFieldValue("environment");
      const updateEnvironmentValue = environmentValue?.map((item) => ({
        ...item,
        isFullAccess: true,
        environment: true,
      }));
      addProductUserForm.setFieldValue("environment", updateEnvironmentValue);
    } else {
      const environmentValue = addProductUserForm.getFieldValue("environment");
      const updateEnvironmentValue = environmentValue?.map((item) => ({
        ...item,
        isFullAccess: false,
        environment: false,
      }));
      addProductUserForm.setFieldValue("environment", updateEnvironmentValue);
    }
  };

  return (
    <FormLayout formTitle={`${featureRolloutHeading} Product User`}>
      {initialFormValues ? (
        <Form
          name="add-product-user common-form"
          wrapperCol={{ span: 10 }}
          form={addProductUserForm}
          colon={false}
          onFinish={(data) => onSubmit(data)}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="horizontal"
          initialValues={initialFormValues}
        >
          <div className="w-full">
            <div className="users-selection">
              {!editProductUsersData ? (
                <Form.Item
                  label="User"
                  name="accountId"
                  className="!mb-0 md:w-full w-1/2"
                  rules={[{ required: true, message: "Please select user" }]}
                >
                  <Select
                    id="selectEditProductUser"
                    showSearch
                    className="!w-full"
                    placeholder="Please search/select user"
                    filterOption={handleFilterOption}
                    disabled={!!editProductUsersData}
                    onChange={handleUserSelectChange}
                  >
                    {allUser?.map((user) => (
                      <Option key={user.id} value={user.id}>
                        {user.name} ({user.email})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <div className="flex gap-2 !pt-0 p-2">
                  <p>User :</p>
                  <p style={{ fontSize: "14px" }}>
                    {editProductUsersData[0]?.name} (
                    {editProductUsersData[0]?.email})
                  </p>
                  <Form.Item
                    name="accountId"
                    className="!mb-0 !w-full"
                    hidden
                    rules={[{ required: true, message: "Please select user" }]}
                  >
                    <Select
                      id="selectAddProductUser"
                      showSearch
                      placeholder="Please search/select user"
                      filterOption={handleFilterOption}
                      className="!w-full"
                      disabled={!!editProductUsersData}
                      onChange={handleUserSelectChange}
                    >
                      {allUser?.map((user) => (
                        <Option key={user.id} value={user.id}>
                          {user.name} ({user.email})
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              )}
              <Form.List name="environment">
                {(fields) => (
                  <div className="flex flex-col gap-4">
                    {initialFormValues?.environment.map(
                      (environment, index) => (
                        <Card
                          prefixCls="product-user-card"
                          size="small"
                          key={environment.environmentId}
                          bordered={false}
                        >
                          <div
                            key={environment.environmentId}
                            className="flex flex-row gap-4 items-center main-card justify-between"
                          >
                            <div className="flex gap-3 items-center justify-between w-full xl:w-1/2">
                              <div className="flex gap-3 items-center">
                                <Form.Item
                                  className="!mb-0"
                                  name={[index, "environmentId"]}
                                  initialValue={environment.environmentId}
                                  noStyle
                                  hidden
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  className="!mb-0"
                                  name={[index, "environment"]}
                                  valuePropName="checked"
                                >
                                  <Checkbox
                                    onChange={(event) =>
                                      onEnvironmentCheck(event, index)
                                    }
                                    disabled={selectedUserRole === Role.ADMIN}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label={
                                    <div className="flex flex-row gap-4">
                                      {environment.environmentName}
                                      {isProductionEnvironment &&
                                        isProductionEnvironment?.name ===
                                          environment?.environmentName && (
                                          <Tag
                                            color="blue"
                                            className="breadcrumb-tag"
                                          >
                                            Production
                                          </Tag>
                                        )}
                                    </div>
                                  }
                                ></Form.Item>
                              </div>
                              <div className="flex gap-3 items-center !mb-0">
                                <Form.Item
                                  className="!mb-0"
                                  name={[index, "isFullAccess"]}
                                  valuePropName="checked"
                                >
                                  <Switch
                                    disabled={
                                      selectedUserRole === Role.ADMIN ||
                                      !switchEnabled[index]
                                    }
                                  />
                                </Form.Item>
                                <Form.Item
                                  className="full-access-label"
                                  label="Full Access"
                                ></Form.Item>
                              </div>
                            </div>

                            <div className="flex items-center !mb-0">
                              {location.pathname.includes("edit") &&
                                editProductUsersData?.length > 0 && (
                                  <InfoDetails
                                    infoData={editProductUsersData?.find(
                                      (obj) =>
                                        obj.environmentId ===
                                        environment.environmentId
                                    )}
                                  />
                                )}
                            </div>
                          </div>
                        </Card>
                      )
                    )}
                  </div>
                )}
              </Form.List>
            </div>
            {selectedUserRole === Role.ADMIN && (
              <div className="alert alert-info d-flex align-items-start">
                <InfoCircleOutlined className="me-2 mt-1" />
                The current selected user role is 'Admin'. All access
                permissions are granted upon submission and cannot be edited
                afterwards.
              </div>
            )}
          </div>
          <Form.Item className="!mb-0 mt-2 top-0 right-4 position-absolute">
            <Space>
              <Button
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={backToListPage}
              >
                Back
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              {/* {id && <InfoDetails infoData={editProductUsersData} />} */}
            </Space>
          </Form.Item>
        </Form>
      ) : (
        <Loader />
      )}
    </FormLayout>
  );
};

export default AddProductUserComponent;

