export const PRODUCT_TYPE = [
  {
    label: "Desktop",
    value: "Desktop",
  },
  {
    label: "Mobile",
    value: "Mobile",
  },
  {
    label: "Web",
    value: "Web",
  },
];
export const PRODUCT_TYPE_ENUM = {
  DESKTOP: "Desktop",
  MOBILE: "Mobile",
  WEB: "Web",
};

export const TARGET_ATTRIBUTE_TYPE = [
  {
    label: "String",
    value: "String",
  },
  {
    label: "Date",
    value: "Date",
  },
  {
    label: "Boolean",
    value: "Boolean",
  },
  {
    label: "Number",
    value: "Number",
  },
  {
    label: "Version",
    value: "Version",
  },
];

export const controlTypeOptions = [
  {
    label: "Text",
    value: "Text",
  },
  {
    label: "Color Picker",
    value: "ColorPicker",
  },
  {
    label: "DropDown",
    value: "DropDown",
  },
  {
    label: "Date",
    value: "Date",
  },

  {
    label: "Boolean",
    value: "Boolean",
  },
  {
    label: "Image Uploader",
    value: "ImageUploader",
  },
];
export const dataTypeOptions = [
  {
    label: "Integer",
    value: "Integer",
  },
  {
    label: "String",
    value: "String",
  },
  {
    label: "Decimal",
    value: "Decimal",
  },
  {
    label: "URL",
    value: "Url",
  },
];

export const contentTypeOptions = [
  {
    label: "Content",
    value: "Content",
  },
];

