import { useEffect, useState } from "react";
import Loader from "../../Components/Common/Loader";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { useSelector } from "react-redux";
import {
  convertDataToQueryParam,
  getSortingOrder,
} from "../../Util/commonUtility";
import { DefaultPagingValue, DefaultSortingValue } from "../../Constant/Common";
import { useNavigate } from "react-router-dom";
import NotificationService from "../../Services/notification";
import ListProductFeatureRolloutComponent from "../../Components/FeaturerRollout/listProductFeatureRolloutComponent";
import useFetchData from "../../Hooks/useFetchData";
const ListFeatureRolloutContainer = () => {
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [featureRolloutFilterData, setFeatureRolloutFilterData] = useState({});
  const [sortField, setSortField] = useState(DefaultSortingValue.Sort_By);
  const [sortOrder, setSortOrder] = useState(DefaultSortingValue.Sort_Order);

  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE || 10,
    pageIndex: DefaultPagingValue.PAGE_INDEX || 1,
    totalPages: 0,
    totalRecords: 0,
  });

  // Fetch data using useFetchData
  const {
    data: productFeatureRollouts,
    loading,
    pagination: fetchedPagination,
  } = useFetchData(
    `${Urls.TENANT_GET_ALL_PRODUCT_FEATURE_ROLLOUT.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    )
      .replace("#{productId}", productState?.selectedProduct?.id)
      .replace(
        "#{environmentId}",
        productState?.selectedEnvironment?.id
      )}?${convertDataToQueryParam(featureRolloutFilterData)}&PageNumber=${
      pagination.pageIndex
    }&PageSize=${
      pagination.pageSize
    }&sortBy=${sortField}&OrderByDirection=${getSortingOrder(sortOrder)}`,
    [
      pagination.pageIndex,
      pagination.pageSize,
      featureRolloutFilterData,
      productState.selectedProduct?.id,
      productState.selectedEnvironment?.id,
      sortField,
      sortOrder,
    ]
  );

  // Update local pagination state when fetchedPagination changes
  useEffect(() => {
    if (fetchedPagination) {
      setPagination(fetchedPagination);
    }
  }, [fetchedPagination]);

  const deleteProductFeatureRollout = async (data) => {
    const response = await httpClient.delete(
      Urls.TENANT_DELETE_PRODUCT_FEATURE_ROLLOUT.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{featureRolloutId}", data.id)
        .replace("#{environmentId}", productState?.selectedEnvironment?.id)
    );
    if (isSuccessStatus(response?.status)) {
      NotificationService.success("Success", " Feature deleted successfully.");
      setFeatureRolloutFilterData({}); // Reset filter to reflect changes
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    setSortField(field);
    setSortOrder(order);

    if (order === undefined || !order) {
      setSortField(DefaultSortingValue.Sort_By);
      setSortOrder(DefaultSortingValue.Sort_Order);
    }
    setPagination((prev) => ({
      ...prev,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  const handlePageChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize: pageSize,
    }));

    setSortField(DefaultSortingValue.Sort_By);
    setSortOrder(DefaultSortingValue.Sort_Order);
  };

  const editProductFeatureRollout = (data) => {
    navigate(`/product/featureRollout/edit/${data.id}`);
  };

  const featureRolloutFilterSubmit = (data) => {
    setFeatureRolloutFilterData(data);
    setPagination((prev) => ({ ...prev, pageIndex: 1 }));
  };

  const resetFilter = () => {
    setFeatureRolloutFilterData({});
    setPagination((prev) => ({ ...prev, pageIndex: 1 }));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ListProductFeatureRolloutComponent
          productFeatureRollouts={productFeatureRollouts}
          featureRolloutFilterData={featureRolloutFilterData}
          paging={pagination}
          editProductFeatureRollout={(data) => editProductFeatureRollout(data)}
          deleteProductFeatureRollout={(data) =>
            deleteProductFeatureRollout(data)
          }
          featureRolloutFilterSubmit={featureRolloutFilterSubmit}
          handlePageChange={handlePageChange}
          resetFilter={resetFilter}
          handleTableChange={handleTableChange}
          sortOrder={sortOrder}
          sortField={sortField}
        />
      )}
    </>
  );
};
export default ListFeatureRolloutContainer;
